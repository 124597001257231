import styled from '@emotion/styled';
import { PrimaryJoyuusLogo } from '../atoms/logos/PrimaryJoyuusLogo';

export const Footer = () => (
  <Container>
    <LogoContainer>
      <PrimaryJoyuusLogo color="#E4E1E9" />
    </LogoContainer>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 50px;
`;

const LogoContainer = styled.div`
  width: 75px;
`;
