import { Avatar, AvatarProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { AVATAR_SIZE } from '../enums/avatarSize';
import { IAuthUser, NullAuthUser } from '../../common/types/IAuthUser';
import { blossom } from '../../common/styles/colors/blossom';
import { orchid } from '../../common/styles/colors/orchid';

interface IProfileAvatarProps extends AvatarProps {
  user: IAuthUser | undefined;
  size: AVATAR_SIZE;
}

// Assumes only two profile sizes (medium and xlarge).
// Update if more are in use
function getBorderRadius(size: AVATAR_SIZE): string {
  return size === AVATAR_SIZE.medium ? '8px 8px 24px 24px' : '16px 16px 48px 48px';
}

export const ProfileAvatar = ({
  user: {
    picture,
    email,
    name,
    family_name: lastName,
    given_name: firstName,
    sub: userId,
  } = NullAuthUser,
  size,
}: IProfileAvatarProps): JSX.Element => {
  const [useProfileImage, setUseProfileImage] = useState<boolean>(false);
  const [initials, setInitials] = useState<string>('');
  const radius = getBorderRadius(size);
  const image = useProfileImage ? picture : undefined;
  useEffect(() => {
    if (userId) {
      const authProvider = userId.split('|')[0];
      if (authProvider && authProvider !== 'auth0') {
        // Is social login
        setUseProfileImage(true);
      } else if (firstName && lastName) {
        // Use first and last initials
        const firstAndLastInitial = `${firstName.substring(0, 1)} ${lastName.substring(0, 1)}`;
        setInitials(firstAndLastInitial);
      } else if (email) {
        // Use first two letters of email address
        setInitials(email.substring(0, 2));
      } else {
        setInitials('JOY');
      }
    }
  }, []);
  return (
    <Avatar
      src={image}
      alt={`${name} avatar`}
      style={{
        borderRadius: radius,
        width: size,
        height: size,
        backgroundColor: blossom[100],
        color: orchid[1000],
      }}
    >
      <Typography style={{ fontSize: size === AVATAR_SIZE.medium ? 17 : 32 }}>{ initials }</Typography>
    </Avatar>
  );
};
