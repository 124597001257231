export const MentalHealthIllustration = () => (
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2267.72 1500.27">
    <defs>
      <style>{`
          .mh-cls-1 {
            fill: #0054e0;
      }

          .mh-cls-2 {
            fill: #f4f7fa;
      }

          .mh-cls-3 {
            fill: #ffbbfb;
      }

          .mh-cls-4 {
            fill: #bb2f9f;
      }

          .mh-cls-5 {
            fill: #a5695f;
      }

          .mh-cls-6 {
            fill: #e1e800;
      }

          .mh-cls-7 {
            fill: #2f1747;
      }

          .mh-cls-8 {
            fill: #3d3935;
      }

          .mh-cls-9, .mh-cls-10 {
            stroke - width: 4.33px;
      }

          .mh-cls-9, .mh-cls-10, .mh-cls-11, .mh-cls-12, .mh-cls-13 {
            fill: none;
          strokeLinecap: round;
          stroke-miterlimit: 10;
      }

          .mh-cls-9, .mh-cls-12 {
            stroke: #2f1747;
      }

          .mh-cls-10 {
            stroke: #f4f7fa;
      }

          .mh-cls-11 {
            stroke: #3d3935;
      }

          .mh-cls-11, .mh-cls-12, .mh-cls-13 {
            stroke - width: 5.77px;
      }

          .mh-cls-13 {
            stroke: #2a1e43;
      }
    `}</style>
    </defs>
    <g id="real_life" data-name="real life">
      <g id="Layer_group" data-name="Layer group">
        <g id="details">
          <g id="heart_star" data-name="heart &amp;amp; star">
            <g>
              {/* <rect className="mh-cls-4" width="2267.72" height="1500.27" /> */}
              {/* <path className="mh-cls-4" d="M478.5,153.77c-40.62,16.04-123.8,50.23-182.34,138.93-13.14,19.91-56.6,87.6-56.44,178,.13,73.77,29.16,79.91,26.05,145.44-3.03,63.86-31.25,71.62-75.98,184.51-25.8,65.13-48.65,122.8-47.76,195.37,.85,69.17,23.18,128.44,41.24,160.63,81.51,145.31,312.42,170.64,468.88,141.1,93.09-17.58,72.03-38.62,262.66-97.68,216.55-67.1,289.43-54.1,327.78-43.41,75.75,21.11,140.55,67.71,167.15,86.83,74.27,53.4,78.42,80.41,132.41,110.71,97.69,54.81,205.56,34.58,227.93,30.39,186.13-34.9,271.41-205.46,282.2-227.93,8.28-17.26,72.36-155.9,26.05-308.24-42.38-139.39-141.28-172.17-134.59-293.05,2.6-46.99,17.78-46.23,32.56-145.44,19.63-131.79-1.36-172.01-15.2-191.02-6.54-8.98-20.81-26.05-69.46-49.93-52.28-25.66-153.99-75.57-282.2-49.93-99.49,19.9-166.51,75.62-197.54,102.02-66.45,56.55-58.96,81.74-104.2,104.2-76.29,37.87-166.82,.62-280.02-47.76-172.2-73.59-170.82-118.47-284.37-143.27-49.96-10.91-152.93-24.66-264.83,19.54Z" /> */}
              <path id="hair" className="mh-cls-7" d="M1182.86,119.63c-10.55,.24-21.2,8.75-27.57,16.21-8.17,9.56-8.56,16.65-9.85,31.33-.68,7.78-8.15,18-21.06,22.41-12.31,4.2-19.39-1.14-43.67-6.23-12.64-2.65-33.66-7.06-50.57,4.05-11.68,7.67-19.81,21.7-21.24,35.4-1.92,18.43,9.22,27.37,4.05,39.45-3.6,8.4-12.96,11.74-24.27,16.18-17.65,6.93-22.55,2.22-34.39,9.1-3.89,2.26-16.2,10.24-20.23,25.29-4.38,16.37,3.9,30.46,5.06,32.37,10.21,16.79,25.45,14.49,30.34,29.33,3.16,9.6-.42,19.03-2.02,23.26-5.18,13.65-13.64,14.68-20.23,26.3-6.95,12.25-7.14,28-3.03,39.45,9.13,25.49,45,32.08,51.58,33.38,21.11,4.15,30.04-2.07,40.46,6.07,11.75,9.18,10.53,25.02,13.15,35.4,10.3,40.84,94.43,54.37,124.41,58.66,101.68,14.57,201.72,11.93,276.12,6.07-6.52-13.17-12.15-29.53-5.06-39.45,3.11-4.34,6.23-8.94,17.19-9.1,14.9-.22,29.29-2,41.89-17.77,8.59-10.76,10.03-37.32,1.9-48.51-8.73-12.01-15.22-13.32-32.67-23.73-6.52-3.89-9.2-10.79-8.09-16.18,1.75-8.5,14.67-7.77,27.31-18.21,9.32-7.7,18.86-21.79,18.21-36.41-.73-16.15-3.04-25.38-18.73-38.71-16.65-14.15-40.93-12.81-46-24-1.99-4.39-4.46-14.84,4.05-28.32,19.58-31.03,16.12-42.87,12.87-51.61-4.86-13.04-14.53-26.16-29.32-30.44-18.04-5.23-37.01-.52-49.29-1.9-7.83-.88-12.18-1.48-15.17-5.06-5.88-7.03,.92-16.75,0-31.35-.74-11.7-6.42-26.25-17.19-34.39-18.61-14.07-51.94-8.66-65.74,6.07-2.62,2.8-5.69,7.16-12.14,10.11-.65,.3-12.46,5.52-23.26,1.01-12.36-5.16-11.59-18.12-21.24-29.33-6.79-7.89-19.67-16-46.53-16.18Z" />
              <g id="clothes">
                <path className="mh-cls-6" d="M726.13,637.65c102.66-54.91,193.31-82.23,256.8-96.79,45.81-10.5,97.81-22.09,167.76-21.94,18.01,.04,101.47,1,206.48,34.84,167.72,54.06,271.88,153.83,304.55,187.12,38.28,39,91.17,101.59,135.5,190.99-8.4,9.01-17.42,18.08-27.1,27.1-44.04,41.05-89.61,69.88-130.34,90.33,10.4,59.62,18.88,126.16,23.23,198.73,5.6,93.41,3.26,178.23-2.85,252.22H775.09c-6.5-94.65-9.06-197.85-5.18-308.37,2.24-63.82,6.44-124.85,12.08-182.86-2.76,.8-7.32,1.81-12.88,1.39-10.7-.81-18.23-6.41-22.69-9.81-12.39-9.46-32.84-32.49-55.19-65.3,11.63-99.22,23.26-198.44,34.89-297.66Z" />
                <path className="mh-cls-12" d="M806.14,828.68c-4.9,34.75-9.56,70.02-13.97,105.81-3,24.33-5.82,48.45-8.48,72.37" />
              </g>
              <g id="baby">
                <path className="mh-cls-2" d="M1530.58,830.62c-36.88-42.97-88.84-49.56-104.37-50.82-60.48-4.9-107.52,27.41-143.9,52.41-37.8,25.96-32.76,33.53-79.07,66.7-41.18,29.5-48.28,25.75-99.62,58.76-49.05,31.54-77.1,49.57-102.79,84.17-16.47,22.18-41.35,55.7-41.11,103.22,.18,36.34,15.01,73.15,37.95,96.87,60.81,62.86,178.34,33.41,248.27,15.88,62.72-15.72,214.56-53.77,286.22-185.8,12.38-22.81,43.56-82.15,33.21-155.63-2.88-20.45-8.17-54.73-34.79-85.76Z" />
                <circle className="mh-cls-5" cx="1423.26" cy="926.68" r="98.77" />
                <path className="mh-cls-12" d="M1110.41,955.53c11.88,27.75,37.38,77.37,88.24,122.68,50.77,45.23,102.79,64.76,131.64,73.32" />
                <path className="mh-cls-12" d="M1082.89,974.42c-1.66,26.73-2.13,75.4,13.56,125.56,15.67,50.07,40.03,77.94,54.25,91.41" />
                <path className="mh-cls-12" d="M1395.12,879.52c.06,.76,.47,5.32,4.59,8.49,4.64,3.57,10.08,2.37,10.72,2.22" />
                <path className="mh-cls-12" d="M1436.97,915.2c-.12,.71-.79,4.99,2.57,8.96,3.79,4.47,9.5,4.78,10.17,4.8" />
                <path className="mh-cls-12" d="M1411.59,921.28c0-.49-.02-3.46-2.42-5.71-2.71-2.54-6.15-2.03-6.56-1.97" />
                <circle className="mh-cls-7" cx="1404.96" cy="946.67" r="6.63" />
              </g>
              <path className="mh-cls-7" d="M760.74,923.67c21.58,27.68,31.34,70.47,24.42,89.88-2.9,8.15-57.5-13.72-79.08-41.4-21.58-27.68-33.19-70.71-19.28-81.56,13.91-10.85,52.35,5.4,73.93,33.07Z" />
              <path className="mh-cls-5" d="M1776.97,945.06c-1.16-2.81-4.07-4.47-7.06-3.94-31.25,5.57-64.33,12.51-98.93,21.16-37.73,9.44-72.93,19.83-105.45,30.6-1.73,.57-3.08,1.83-3.81,3.5-10.19,23.57-30.14,60.13-68.19,90.85-20.1,16.22-38.14,25.07-51.45,31.5-43.63,21.08-75.25,25.11-130.2,35.7-78.77,15.18-118.16,22.77-154.35,37.8-30.83,12.8-67.95,28.66-102.9,65.1-24.05,25.07-38.75,51.22-47.75,71.52-2.92,6.57-.85,14.26,4.96,18.49l.63,.46c6.99,5.09,16.75,3.96,22.29-2.68,7.52-9.01,16.2-18.45,26.17-27.95,8.29-7.89,16.45-14.86,24.25-20.98,2.39-1.88,5.76-1.77,8.08,.2l3.75,3.18c3.03,2.57,3.06,7.23,.01,9.78-8.75,7.32-18.31,16.48-27.7,27.78-13.61,16.38-23.03,32.65-29.59,46.6-3.73,7.93-.84,17.38,6.73,21.8h0c7.06,4.12,16.01,2.51,21.22-3.78,8.03-9.69,16.96-19.75,26.84-29.96,10.65-11,21.13-20.87,31.19-29.69,2.54-2.23,6.36-2.05,8.75,.34h0c2.73,2.73,2.47,7.19-.56,9.57-9.46,7.43-20.35,17.35-30.98,30.29-10.1,12.27-17.55,24.31-23.04,34.89-3.93,7.56-2.05,16.83,4.64,22.12l.18,.14c7.49,5.93,18.31,4.36,24-3.31,7.47-10.07,16.65-20.92,27.82-31.78,10.47-10.17,20.78-18.5,30.24-25.26,2.54-1.82,6.02-1.48,8.23,.72l1,1c2.84,2.84,2.45,7.57-.89,9.82-8.32,5.61-18.62,14.05-28.08,26.32-4.66,6.04-8.33,11.99-11.22,17.51-4.16,7.93-.78,17.72,7.36,21.45h0c6.36,2.92,13.82,1.15,18.29-4.24,6.3-7.58,14.63-15.81,25.47-23.18,8.49-5.77,22.21-15.11,42-17.85,13.06-1.81,22.61,.1,37.8,3.15,28.49,5.71,34.7,12.91,56.7,16.8,4.58,.81,17.85,2.9,45.15,0,83.94-8.91,147-35.7,147-35.7,125.17-53.19,196.13-150.48,210-170.1,32.38-45.79,69.07-97.68,71.4-173.25,1.39-45.05-9.94-82.11-20-106.48Z" />
              <path className="mh-cls-5" d="M931.41,571.23c-1.5-4.83-5.58-7.82-6.91-8.32-3.38-1.27-7.84-2.21-15.27,.57-6.51,2.43-15.63,6.8-21.45,11.25-11.9,9.12-22.89,18.21-32.98,27.1-2.29,2.01-5.66,2.02-7.97,.04l-1.21-1.04c-2.59-2.22-2.86-6.09-.64-8.67,8.44-9.82,19.52-20.99,33.66-31.83,13.49-10.34,26.64-17.99,38.24-23.65,7.65-3.73,10.06-13.45,5.2-20.43l-.16-.23c-3.81-5.46-10.92-7.45-17.03-4.81-13.33,5.75-28.78,13.73-44.89,24.86-19.61,13.55-34.58,27.88-45.53,40.05-2.27,2.52-6.15,2.66-8.64,.37h0c-2.36-2.17-2.67-5.82-.65-8.31,8.71-10.73,19.92-22.74,34.09-34.57,17.56-14.66,34.88-25.05,49.72-32.4,6.69-3.31,8.97-11.71,4.8-17.9l-.13-.19c-2.92-4.34-8.28-6.42-13.33-5.03-15.3,4.21-35.8,11.81-57.24,25.99-22.04,14.57-37.3,30.95-47.14,43.54-2.4,3.07-6.98,3.16-9.49,.18l-1.24-1.47c-1.91-2.27-1.96-5.59-.04-7.86,9.62-11.34,22.5-24.38,39.28-36.85,8.06-5.99,15.93-11.04,23.35-15.29,6.3-3.61,7.4-12.19,2.21-17.26-3.27-3.19-8.23-4.02-12.35-2.06-82.61,39.22-145.33,81.93-188.73,115.32-88.39,68.01-133.18,125.48-146.53,143.14-19.02,25.18-70.57,97.14-76.23,177.86-1.15,16.46-.42,33.57,6.78,51.67,0,0,16.1,40.48,67.76,66.06,60.74,30.08,169.58-7.43,270.32-98.73,2.37-2.15,2.71-5.78,.77-8.33-4.22-5.54-9.53-11.55-16.15-17.44-2.18-1.94-4.33-3.72-6.45-5.36-2.85-2.21-3.16-6.32-.68-8.94,28.53-30.19,58.19-64.48,87.77-103.13,56.39-73.7,45.39-57.29,58.94-76.44,2.71-3.82,8.32-14.7,11.84-28.6,1.54-6.07,4.61-18.23-.38-30.5-2.55-6.28-5.51-8.39-5.27-13.55,.1-2.15,.51-8.04,10.45-17.9,11.4-11.3,17.98-14.78,27.56-21.04,6.13-4,14.27-12.48,11.98-19.84Z" />
              <g id="nails_left_" data-name="nails (left)">
                <path className="mh-cls-9" d="M820.72,468.74c-1.68,3.5-1.07,7.66,1.47,10.34,2.19,2.31,5.51,3.2,8.64,2.33" />
                <path className="mh-cls-9" d="M882.12,479.71c-2.56,3.52-2.64,8.2-.26,11.62,2.06,2.95,5.66,4.56,9.37,4.22" />
                <path className="mh-cls-9" d="M911.97,511.47c-2.98,3.88-3.11,9.11-.38,13,2.35,3.35,6.5,5.22,10.79,4.91" />
                <path className="mh-cls-9" d="M918.81,563.59c-2.56,3.52-2.64,8.2-.26,11.62,2.06,2.95,5.66,4.56,9.37,4.22" />
              </g>
              <g id="nail_right_" data-name="nail (right)">
                <path className="mh-cls-9" d="M1152.35,1455.04c.06-3.88-2.24-7.41-5.67-8.77-2.96-1.18-6.34-.59-8.82,1.5" />
                <path className="mh-cls-9" d="M1096.61,1441.52c.85-4.27-1.04-8.55-4.64-10.66-3.1-1.82-7.05-1.77-10.27,.09" />
                <path className="mh-cls-9" d="M1057.07,1405.15c1.08-4.77-1-9.58-5.1-11.96-3.54-2.05-8.09-2.01-11.85,.06" />
                <path className="mh-cls-9" d="M1023.53,1346.13c.85-4.27-1.04-8.55-4.64-10.66-3.1-1.82-7.05-1.77-10.27,.09" />
              </g>
              <g>
                <path className="mh-cls-10" d="M703.82,547.18c.59,1.84,1.99,5.35,5.25,8.64,3.15,3.18,6.52,4.62,8.33,5.25" />
                <path className="mh-cls-10" d="M732.83,574.03c.59,1.84,1.99,5.35,5.25,8.64,3.15,3.18,6.52,4.62,8.33,5.25" />
                <path className="mh-cls-10" d="M764.31,601.81c.59,1.84,1.99,5.35,5.25,8.64,3.15,3.18,6.52,4.62,8.33,5.25" />
                <path className="mh-cls-10" d="M797.95,629.28c.59,1.84,1.99,5.35,5.25,8.64,3.15,3.18,6.52,4.62,8.33,5.25" />
              </g>
              <g>
                <path className="mh-cls-10" d="M1225.75,1336.49c-.44-1.88-1.54-5.5-4.52-9.05-2.88-3.43-6.12-5.14-7.87-5.92" />
                <path className="mh-cls-10" d="M1199.06,1307.33c-.44-1.88-1.54-5.5-4.52-9.05-2.88-3.43-6.12-5.14-7.87-5.92" />
                <path className="mh-cls-10" d="M1169.98,1277.05c-.44-1.88-1.54-5.5-4.52-9.05-2.88-3.43-6.12-5.14-7.87-5.92" />
                <path className="mh-cls-10" d="M1138.72,1246.9c-.44-1.88-1.54-5.5-4.52-9.05-2.88-3.43-6.12-5.14-7.87-5.92" />
              </g>
              <g id="face">
                <path className="mh-cls-5" d="M1239.01,251.09c17.46,123.64,34.91,247.28,52.37,370.92,3.58,25.35-1.55,51.52-16,72.65-5.28,7.73-12.05,15.4-20.71,22.34-5.96,4.78-37.82,30.3-81.03,22.1-41.66-7.91-61.94-41.1-65.38-46.96-12.01-20.49-13.97-40.83-13.51-54.5,.23-6.87,1.32-13.68,2.97-20.36,14.26-57.92,28.51-115.83,42.77-173.75-34.99-.92-69.98-1.84-104.97-2.76,3.21-22.62,5.92-46.39,26.99-78.93,55.14-85.19,158.73-108.4,176.51-110.75Z" />
                <circle className="mh-cls-5" cx="1282.64" cy="389.28" r="43.04" />
                <path className="mh-cls-12" d="M1158.92,326.35c-.5,4.39-.99,8.78-1.49,13.17" />
                <path className="mh-cls-12" d="M1301.55,377.64c-10.97,4.82-21.93,9.65-32.9,14.47" />
                <path className="mh-cls-13" d="M1112.44,311.27c-10,7.13-21.87,17.04-33.45,30.48-7.12,8.26-12.82,16.32-17.36,23.64,6.64,.02,13.28,.04,19.92,.07" />
                <circle className="mh-cls-7" cx="1105.67" cy="402.13" r="4.33" />
              </g>
              <g id=":l">
                <path className="mh-cls-2" d="M1968.7,67.56c-137.49-35.52-268.74,12.3-293.16,106.81-13.08,50.62,7.17,104.35,49.89,148.81-14.29,10.26-32.01,20.95-53.48,29.95-39.14,16.39-74.06,20.24-96.21,20.97,31.77,7.55,73.83,14.29,123.34,13.57,36.62-.54,69.3-5.04,97.16-10.98,25.24,13.9,53.5,25.24,84.03,33.13,137.49,35.52,268.74-12.3,293.16-106.81,24.42-94.51-67.25-199.92-204.74-235.44Z" />
                <circle className="mh-cls-3" cx="1921.76" cy="232.03" r="89.71" />
                <circle className="mh-cls-8" cx="1887.04" cy="215.9" r="6.12" />
                <circle className="mh-cls-8" cx="1955.59" cy="230.94" r="6.12" />
                <rect className="mh-cls-8" x="1896.95" y="252.32" width="38.4" height="4.05" rx="2.03" ry="2.03" transform="translate(114.64 -446.29) rotate(13.69)" />
              </g>
              <g id="_" data-name="!!">
                <path className="mh-cls-2" d="M2092.3,630.01c-62.43-24.09-127.68-15.83-170.25,16.44-25.74-5.38-55.4-13.7-87.27-26.91-47.93-19.86-84.92-44.1-111.01-64.14,22.6,36.13,53.47,78.72,94.97,122.11,19.24,20.11,38.33,37.79,56.7,53.41-4.55,63.05,42.09,129.11,116.93,157.99,88.12,34.01,181.92,3.62,209.51-67.87,27.59-71.49-21.47-157.02-109.59-191.03Z" />
                <g>
                  <rect className="mh-cls-1" x="2018.98" y="670.55" width="17.79" height="94.34" rx="8.89" ry="8.89" transform="translate(503.07 -799.64) rotate(25.37)" />
                  <circle className="mh-cls-1" cx="1998.67" cy="779.3" r="10.05" />
                  <rect className="mh-cls-1" x="2062.08" y="729.93" width="14.25" height="75.56" rx="7.12" ry="7.12" transform="translate(1023.1 -1175.65) rotate(41.35)" />
                  <circle className="mh-cls-1" cx="2033.14" cy="808.69" r="8.05" />
                </g>
              </g>
              <g id=":D">
                <path className="mh-cls-2" d="M431.16,585c-27.61,13.92-53.48,22.52-75.45,28.02-2.18-7.26-5.16-14.32-9.05-21.06-34.07-59.01-122.18-71.91-196.81-28.83-74.63,43.09-107.5,125.85-73.44,184.85,34.07,59.01,122.18,71.91,196.81,28.83,43.52-25.13,72.81-63.76,82.93-103.29,25.16-13.42,62.89-35.34,92.26-60.15,22.5-19,50.61-48.57,75.24-94.97-19.53,19.35-49.99,45.17-92.51,66.6Z" />
                <circle className="mh-cls-3" cx="211.33" cy="664.8" r="71.7" />
                <path className="mh-cls-11" d="M178.5,664.95c-.79-3.91,.74-7.87,3.83-9.96,3.75-2.54,9.15-1.91,12.54,1.73" />
                <path className="mh-cls-11" d="M211.59,646.11c-.79-3.91,.74-7.87,3.83-9.96,3.75-2.54,9.15-1.91,12.54,1.73" />
                <path className="mh-cls-8" d="M190.9,682.75l45.46-27.59c4.39-2.67,10.06-.24,11.23,4.76,1.05,4.49,1.52,10.27-.03,16.72-3.24,13.47-12.8,19.77-15.87,21.47-15.4,8.56-30.89,2.63-32.89,1.83-3.39-1.36-6.26-3.09-8.64-4.86-4.21-3.14-3.74-9.61,.75-12.33Z" />
              </g>
              <g id="_-2" data-name="??">
                <path className="mh-cls-2" d="M703.5,311.3c-30.22-8.04-56.01-18.57-77.28-29.06,6.34-14.08,9.8-28.89,9.8-44.2,0-91.37-120.61-165.44-269.39-165.44S97.24,146.67,97.24,238.04s120.61,165.44,269.39,165.44c78.77,0,149.62-20.77,198.88-53.86,35.47,.41,73.97-.64,115.11-4,53.61-4.38,102.27-11.87,145.24-20.59-30.38,1.1-73.23-.66-122.37-13.72Z" />
                <g>
                  <path className="mh-cls-1" d="M236.63,179.93c-1.35,1.61-1.51,3.86-.5,5.7,1.34,2.44,3.05,5.13,5.22,7.9,1.97,2.52,3.98,4.65,5.88,6.45,2.1,1.98,5.36,1.92,7.28-.24,7.17-8.06,18.4-18.11,34.09-24.06,16.96-6.43,26.72-3.37,28.88-2.63,6.3,2.16,9.35,5.57,9.98,6.3,7.4,8.56,4.37,20.4,4.2,21-2.48,9.1-9.93,13.76-17.33,18.38-6.09,3.81-12.02,5.99-16.9,7.27-2.79,.73-4.35,3.67-3.47,6.42l14.64,46.07c.85,2.67,3.69,4.14,6.36,3.3l18.78-5.91c2.64-.83,4.12-3.61,3.35-6.27l-7.38-25.25c-.67-2.28,.31-4.75,2.42-5.86,5.23-2.76,12.64-7.71,18.96-16.1,2.54-3.37,10.14-13.72,11.03-27.83,1.13-17.88-8.22-35.82-23.1-45.16-11.03-6.92-22-7.18-28.88-7.35-16.02-.39-27.87,5-36.23,8.93-17.53,8.22-29.63,19.83-37.28,28.93Z" />
                  <circle className="mh-cls-1" cx="333.28" cy="312.19" r="15.94" />
                  <path className="mh-cls-1" d="M401.43,184.42c-1.47,.41-2.48,1.7-2.62,3.22-.19,2.01-.26,4.32-.08,6.86,.16,2.31,.49,4.41,.9,6.26,.45,2.04,2.43,3.33,4.46,2.82,7.58-1.92,18.4-3.37,30.23-.55,12.79,3.05,17.39,8.86,18.38,10.18,2.9,3.86,3.34,7.15,3.42,7.84,.95,8.14-5.69,14.01-6.03,14.3-5.19,4.44-11.55,4.2-17.87,3.96-5.2-.2-9.64-1.3-13.09-2.52-1.97-.7-4.1,.43-4.69,2.43l-9.98,33.58c-.58,1.94,.53,3.99,2.47,4.57l13.67,4.1c1.92,.58,3.94-.49,4.56-2.39l5.85-18.14c.53-1.64,2.12-2.72,3.83-2.53,4.26,.47,10.71,.52,17.92-1.93,2.89-.99,11.66-4.1,17.94-12.2,7.96-10.26,9.65-24.82,4.53-36.48-3.79-8.63-10.27-13.26-14.32-16.16-9.45-6.76-18.74-8.34-25.35-9.4-13.86-2.21-25.84-.18-34.13,2.17Z" />
                  <circle className="mh-cls-1" cx="405.54" cy="303.05" r="11.55" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
