import axios, { AxiosInstance } from 'axios';

export class ApiService {
  static api: AxiosInstance;

  public initApi = (token: string) => {
    ApiService.api = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL?.concat('/api'),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

export const Api = new ApiService();
