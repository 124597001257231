import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { PageLoader } from './PageLoader';

export const AuthGuard = ({
  component,
}: {
  component: React.ComponentType<any>;
}): JSX.Element => {
  const AuthenticatedComponent = withAuthenticationRequired(component, {
    onRedirecting: () => <PageLoader />,
  });

  return <AuthenticatedComponent />;
};
