import {
  AppBar,
  Box,
  Toolbar,
  Container,
  useScrollTrigger,
  Avatar,
} from '@mui/material';
import { cloneElement } from 'react';
import * as React from 'react';
import { Business } from '@mui/icons-material';

import { BypassLink } from '../../common/atoms/BypassLink';
import { blossom } from '../../common/styles/colors/blossom';
import { orchid } from '../../common/styles/colors/orchid';
import { AVATAR_SIZE } from '../../app-user/enums/avatarSize';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

interface IAppHeaderProps {
  orgName: string | undefined;
}

const ElevationScroll = (props: Props) => {
  const { children, window } = props;
  useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    style: {
      background: 'rgba(255, 255, 255, 0.65)',
    },
    elevation: 0,
  });
};

const MainNavContent = () => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    style={{ width: '100%' }}
  >
    <h2>Admin App</h2>
    <Avatar
      alt="company avatar"
      style={{
        borderRadius: '8px 8px 24px 24px',
        width: AVATAR_SIZE.medium,
        height: AVATAR_SIZE.medium,
        backgroundColor: blossom[100],
        color: orchid[1000],
      }}
    >
      <Business fontSize="small" />
    </Avatar>
  </Box>
);

export const AppHeader = ({ orgName }: IAppHeaderProps): JSX.Element => (
  <ElevationScroll>
    <AppBar
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <BypassLink />
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <MainNavContent />
        </Toolbar>
      </Container>
    </AppBar>
  </ElevationScroll>
);
