import { Chip, IconButton } from '@mui/material';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { ICategory } from '../types/ICategory';
import { useURLQuery } from '../utils/unit-functions';
import { ROUTE } from '../enums/route';
import { QUERYNAME } from '../enums/queryname';

interface IProps {
  category: ICategory | undefined;
  addFilter: (name: string) => void;
  removeFilter: () => void;
}

const ButtonSkeletons = () => (
  <>
    { Array.from({ length: 8 }).map((value: unknown, index: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <ChipSkeleton color="info" key={index} size="medium" />))
    }
  </>
);

export const SubcategoryFilter = ({ category, addFilter, removeFilter }: IProps) => {
  const query = useURLQuery();
  const navigate = useNavigate();
  const subcategoryQuery = query.get('subcategory');
  const [selectedSubcat, setSelectedSubcat] = useState<string>(subcategoryQuery || '');

  useEffect(() => {
    if (subcategoryQuery) {
      setSelectedSubcat(subcategoryQuery);
      addFilter(subcategoryQuery);
    }
  }, [subcategoryQuery, addFilter]);

  const handleSelect = (label: string) => {
    const newSelectedSubcat = label;
    addFilter(newSelectedSubcat);
    setSelectedSubcat(newSelectedSubcat);
    navigate(`${ROUTE.CATEGORY}/${category?.slug}?${QUERYNAME.SUBCATEGORY}=${newSelectedSubcat}`);
  };

  const handleClear = () => {
    navigate(`${ROUTE.CATEGORY}/${category?.slug}`);
    setSelectedSubcat('');
    removeFilter();
  };

  return (
    <Container>
      { !category && <ButtonSkeletons /> }
      { selectedSubcat && (
        <>
          <IconButton onClick={handleClear}>
            <Close />
          </IconButton>
          <Chip
            label={selectedSubcat}
            color="success"
            size="medium"
          />
        </>
      ) }
      { category && !selectedSubcat && (
        <>
          <Chip label="All" color="success" size="medium" />
          { category?.subcategories.map(({ id, name }) => (
            <Chip
              key={id}
              label={name}
              size="medium"
              onClick={() => handleSelect(name)}
            />
          ))}
        </>
      ) }
    </Container>
  );
};

const Container = styled.div`
  padding: 16px 0;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: scroll;
  gap: 8px;
`;

const ChipSkeleton = styled(Chip)`
  min-width: 160px;
  min-height: 44px;
`;
