import { Button, Typography } from '@mui/material';
import { styled } from 'styled-components';
import { Link } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';

import { Grid } from '../../common/atoms/Grid';
import { ResourceCard, ResourceCardSkeleton } from './Card/ResourceCard';
import { IArticlePreview } from '../types/IArticle';
import { ROUTE } from '../enums/route';

interface IResourceBlockParams {
  resources: IArticlePreview[] | undefined;
  loading: boolean;
  showAllButton?: boolean;
  headline?: string;
}

const ResourcePreviewCarousel = ({ articles }: { articles: IArticlePreview[] }): JSX.Element => (
  <>
    {articles.map((article: IArticlePreview) => (
      <Grid.Cell key={article.slug} $bleed xs={12}>
        <ResourceCard article={article} slug={`${ROUTE.ARTICLE}/${article.slug}`} showCategory={false} />
      </Grid.Cell>
    ))}
  </>
);

export const ResourceBlock = ({
  resources = [], loading, showAllButton = true, headline,
}: IResourceBlockParams) => {
  if (loading) {
    return (
      <ResourceContainer>
        {headline && <Typography variant="h3">{headline}</Typography>}
        { Array.from({ length: 4 }).map((value: unknown, index: number) => ResourceCardSkeleton(index)) }
        { showAllButton && (
          <Link to={`${ROUTE.RESOURCE}`} style={{ textDecoration: 'none' }}>
            <Button variant="outlined" size="medium">
              View All Resources
              <ArrowForward />
            </Button>
          </Link>
        )}
      </ResourceContainer>
    );
  } if (!resources.length) {
    // Empty state only exists on bookmarks page
    return null;
  }

  return (
    <ResourceContainer>
      {headline && <Typography variant="h3">{headline}</Typography>}
      <ResourcePreviewCarousel articles={resources} />
      { showAllButton && (
        <Link to={`${ROUTE.RESOURCE}`} style={{ textDecoration: 'none' }}>
          <Button variant="outlined" size="medium">
            View All Resources
            <ArrowForward />
          </Button>
        </Link>
      )}
    </ResourceContainer>
  );
};

const ResourceContainer = styled.div`
  display: flex;
  /* width: 368px; */
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid #ECF0F5;
  background: linear-gradient(180deg, rgba(234, 240, 249, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #FFF;
  `;
