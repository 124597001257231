import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { CACHE_KEY } from '../enums/cacheKey';
import { IBookmark } from '../types/IBookmark';
import { ApiService } from '../../common/services/api-service';
import { IArticle, IArticlePreview } from '../types/IArticle';
import { UserTracking } from '../services/userTracking.service';

interface IToggleBookmarkParams {
  article: IArticle | IArticlePreview;
  bookmarkStatus: boolean;
}

export const useGetUserBookmarks = (): IBookmark[] => {
  const { data } = useQuery({
    queryKey: [CACHE_KEY.USER_BOOKMARKS],
    queryFn: async () => (await ApiService.api.get('/bookmark')).data,
  });
  return data;
};

export const useToggleBookmark = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      article,
      bookmarkStatus,
    }: IToggleBookmarkParams): Promise<AxiosResponse<IBookmark>> => {
      UserTracking.trackBookmarkToggle(article, bookmarkStatus);
      const paramBody = {
        article: { slug: article.slug, id: article.id },
        bookmarked: bookmarkStatus,
      };
      return ApiService.api.post<IBookmark>('/bookmark', paramBody);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEY.USER_BOOKMARKS],
      });
    },
  });
};
