import { RouterProvider } from 'react-router-dom';

import { IUser } from './common/types/IUser';
import { USER_ROLE } from './common/enums/userRole';
import { AppWrapper } from './common/wrappers/AppWrapper';
import { router } from './app-user/router/router';
import { routerAdmin } from './app-admin/router/router-admin';
import { routerInternal } from './app-internal/router/router-internal';

export const AuthenticatedApp = ({ user }: { user: IUser }): JSX.Element => {
  const handleAppRender = (): JSX.Element => {
    switch (user?.role) {
      case USER_ROLE.INTERNAL: {
        return (
          <RouterProvider router={routerInternal} />
        );
      }
      case USER_ROLE.ADMIN: {
        return (
          <RouterProvider router={routerAdmin} />
        );
      }
      case USER_ROLE.USER: {
        return (
          <RouterProvider router={router} />
        );
      }
    }
  };

  return (
    <AppWrapper>
      {handleAppRender()}
    </AppWrapper>
  );
};
