import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { ArticleCard } from '../components/ArticleCard';
import { IArticlePreview } from '../types/IArticle';
import { Grid } from '../../common/atoms/Grid';
import { theme } from '../../common/theme/theme';
import { useGetCategoryResources } from '../hooks/useArticles';
import { ResourceCardSkeleton } from '../components/Card/ResourceCard';
import { ROUTE } from '../enums/route';

export const ResourceCategory = () => {
  const params = useParams<{ category: string }>();
  const { category } = params;
  const { data: categoryResources, isLoading } = useGetCategoryResources(category);

  return (
    <div className="content">
      <Typography variant="h1">{`${categoryResources![0].category} Resources`}</Typography>
      <Grid rowgap={theme.space(4)} colgap={theme.space(4)} sx={{ py: 4 }} justifyItems="space-between">
        { !isLoading && categoryResources?.length ? categoryResources!.map((preview: IArticlePreview) => (
          <Grid.Cell key={preview?.slug} $bleed xs={12} sm={6} md={4}>
            <ArticleCard key={preview?.id} article={preview} slug={`${ROUTE.ARTICLE}/${preview?.slug}`} showCategory />
          </Grid.Cell>
        ))
          : Array.from({ length: 4 }).map((value: unknown, index: number) => ResourceCardSkeleton(index))}
      </Grid>
    </div>
  );
};
