import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IOrganization } from '../../common/types/IOrganization';
import { ApiService } from '../../common/services/api-service';
import { INTERNAL_CACHE_KEY } from '../enums/cacheKey';
import { ICreateOrganizationInput } from '../types/ICreateOrganizationInput';

export const useGetAllOrganizations = () => useQuery<IOrganization[]>({
  queryKey: [INTERNAL_CACHE_KEY.ORGANIZATIONS],
  queryFn: async () => (await ApiService.api.get('internal/organization')).data,
});

export const useGetOrganization = (orgId?: string) => useQuery<IOrganization>({
  queryKey: [INTERNAL_CACHE_KEY.ORGANIZATIONS],
  queryFn: async () => (await ApiService.api.get(`/internal/organization/${orgId}`)).data,
  enabled: !!orgId,
});

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (organization: ICreateOrganizationInput) => (await ApiService.api.post('/internal/organization', { organization })).data,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [INTERNAL_CACHE_KEY.ORGANIZATIONS],
      });
    },
  });
};

export const useArchiveOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (orgId: number) => (await ApiService.api.put(`/internal/organization/${orgId}`)).data,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [INTERNAL_CACHE_KEY.ORGANIZATIONS],
      });
    },
  });
};
