import { styled } from 'styled-components';
import { ToggleBookmark } from '../../atoms/ToggleBookmark';
import { ReadtimeBadge } from '../../atoms/ReadtimeBadge';
import { IArticlePreview } from '../../types/IArticle';

export const CardFooter = ({ article }: { article: IArticlePreview }) => (
  <Container>
    <ToggleBookmark article={article} />
    <ReadtimeBadge readminutes={article?.readminutes} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
