import { Amplitude } from '../../common/services/analytics/amplitude.service';
import { IEventProperties, ITrackValue } from '../../common/types/ITrackEvent';
import { ANALYTICS_EVENT_NAMES_ADMIN } from '../enums/eventNames';

export class AdminTrackingService {
  public trackUserInvite = async (value: ITrackValue) => {
    const eventProperties: IEventProperties = {
      page: 'admin-dashboard',
      value,
    };
    Amplitude.track({
      eventProperties,
      eventInput: ANALYTICS_EVENT_NAMES_ADMIN.INVITE_USER,
    });
  };

  public trackUserEdit = (value: ITrackValue) => {
    const eventProperties: IEventProperties = {
      page: 'admin-dashboard',
      value,
    };
    Amplitude.track({
      eventProperties,
      eventInput: ANALYTICS_EVENT_NAMES_ADMIN.EDIT_USER,
    });
  };
}

export const AdminTracking = new AdminTrackingService();
