import styled, { css } from 'styled-components';
import { Box, BoxProps } from '@mui/material';
import { theme } from '../theme/theme';

export interface IGridProps extends BoxProps {
  children: React.ReactNode;
  rowgap?: number;
  colgap?: number;
  mcolgap?: number;
  className?: string;
}

export interface ICellProps extends BoxProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  rows?: number;
  hideup?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  hidedown?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  $bleed?: boolean;
}

export const gutter = theme.space(4);

/**
 * Custom 12 column grid which uses native CSS Grid styles (not flexbox)
 * @param colgap - (default theme.space(4) - Specifies the gutters between grid columns. Will adjust to 2/3 size on mobile.
 * @param rowgap - (optional) Specifies the gutters between grid rows.
 * @param className - (optional) Additional class names to apply to the grid.
 */
export const Grid = ({
  children,
  rowgap,
  colgap = gutter,
  mcolgap = colgap * 0.5,
  className,
  ...props
}: IGridProps): JSX.Element => (
  <Container
    className={`${className} bc-grid`}
    rowgap={rowgap}
    colgap={colgap}
    mcolgap={mcolgap}
    {...props}
  >
    {children}
  </Container>
);

interface IContainerProps extends BoxProps {
  rowgap?: number;
  colgap?: number;
  mcolgap?: number;
}
const Container = styled(Box) <{ rowgap: IContainerProps['rowgap'], colgap: IContainerProps['colgap'], mcolgap: IContainerProps['mcolgap'] }>`
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
  column-gap: ${(props: IContainerProps) => `${props.mcolgap}px`};
  ${(props: IContainerProps) => !!props.rowgap
    && css`
      row-gap: ${props.rowgap * 0.5}px;
    `}
  @media (min-width: ${theme.breakpoints.values.md}px) {
    column-gap: ${(props: IContainerProps) => `${props.colgap}px`};
    ${(props: IContainerProps) => !!props.rowgap
    && css`
        row-gap: ${props.rowgap}px;
      `}
  }
`;

/**
 * @description custom grid column cell which uses native CSS Grid styles (not flexbox)
 * @param xs - number of columns for xs breakpoint
 * @param sm - number of columns for sm breakpoint
 * @param md - number of columns for md breakpoint
 * @param lg - number of columns for lg breakpoint
 * @param xl - number of columns for xl breakpoint
 * @param rows - number of rows to span
 * @param hideup - hide cell on this breakpoint and above
 * @param hidedown - hide cell on this breakpoint and below
 * @param $bleed - no padding on this cell
 */
Grid.Cell = styled(Box).attrs({
  className: 'bc-grid__cell',
}) <ICellProps>`
  max-width: 100%; // Firefox fix
  padding: 0 ${(props) => theme.spacing(props.$bleed ? 0 : 2)};

  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0;
  }
  ${(props) => {
    const {
      xs, sm, md, lg, xl,
    } = props;
    let styles = `
      grid-column: span 12;
    `;
    if (xs) {
      styles += `
        @media (min-width: ${theme.breakpoints.values.xs}px) {
          grid-column: span ${xs};
        }`;
    }
    if (sm) {
      styles += `
        @media (min-width: ${theme.breakpoints.values.sm}px) {
          grid-column: span ${sm};
        }`;
    }
    if (md) {
      styles += `
        @media (min-width: ${theme.breakpoints.values.md}px) {
          grid-column: span ${md};
        }`;
    }
    if (lg) {
      styles += `
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          grid-column: span ${lg};
        }`;
    }
    if (xl) {
      styles += `
        @media (min-width: ${theme.breakpoints.values.xl}px) {
          grid-column: span ${xl};
        }`;
    }
    return css`
      ${styles}
    `;
  }}
  ${(props) => {
    const { hideup, hidedown } = props;
    if (hidedown) {
      return css`
        display: none;
        @media (min-width: ${theme.breakpoints.values[hidedown]}px) {
          display: block;
        }
      `;
    }
    if (hideup) {
      return css`
        display: block;
        @media (max-width: ${theme.breakpoints.values[hideup]}px) {
          display: none;
        }
      `;
    }
    return '';
  }}
  ${(props) => {
    const { rows } = props;
    if (rows) {
      return css`
        grid-row: span ${rows};
      `;
    }
    return '';
  }}
`;
