export const charcoal = {
  50: '#ECEBEB',
  100: '#D8D7D7',
  200: '#C5C4C2',
  300: '#B1B0AE',
  400: '#9E9C9A',
  500: '#8B8886',
  600: '#777472',
  700: '#64615D',
  800: '#504D49',
  900: '#3D3935',
};
