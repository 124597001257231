import {
  Grid, TextField, FormHelperText, Button, Box, Snackbar,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { theme } from '../theme/theme';
import { useCreateOrganization } from '../../app-internal/hooks/useOrganizations';

interface IOrganizationFormProps {
  onComplete: () => void;
}

interface IOrganizationFormValues {
  name: string;
  domain: string;
}

export const OrganizationForm = ({ onComplete }: IOrganizationFormProps): JSX.Element => {
  const { mutate: createOrg, isSuccess, isError } = useCreateOrganization();
  const [message, setMessage] = useState<string>('');

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<IOrganizationFormValues>();

  const submit = ({ name, domain }: { name: string, domain: string }) => {
    if (isValid) {
      createOrg({ name, domain });
      reset();
      onComplete();
    }
  };

  useEffect(() => {
    if (isError) {
      setMessage('Error creating organization');
    } else {
      setMessage('Organization created');
    }
  }, [isError, isSuccess]);

  const handleClose = () => {
    setMessage('');
  };

  return (
    <div>
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5}>
            <Controller
              control={control}
              name="name"
              rules={{ required: true, minLength: 2 }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  margin="dense"
                  placeholder="Name"
                  onChange={onChange}
                  value={value}
                  error={!!errors.name}
                  inputProps={{ style: { fontSize: '1rem', fontWeight: 400 } }}
                />
              )}
            />
            { errors.name ? <FormHelperText sx={{ fontSize: '.7rem', fontWeight: 300, color: theme.palette.error.main }}>Name is required.</FormHelperText> : '' }
          </Grid>
          <Grid item xs={12} sm={5}>
            <Controller
              control={control}
              name="domain"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  margin="dense"
                  onChange={onChange}
                  value={value}
                  error={!!errors.domain}
                  inputProps={{ style: { fontSize: '1rem', fontWeight: 400 } }}
                />
              )}
            />
            { errors.domain ? <FormHelperText sx={{ fontSize: '.7rem', fontWeight: 300, color: theme.palette.error.main }}>Please enter a valid url.</FormHelperText> : '' }
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mx: 1, my: 1.25 }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      <Box sx={{ width: 500 }}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!!message?.length}
          autoHideDuration={3000}
          onClose={handleClose}
          message={message}
        />
      </Box>
    </div>
  );
};
