export const emerald = {
  50: '#E6F9F0',
  100: '#CCF2E1',
  200: '#B3ECD3',
  300: '#99E6C4',
  400: '#80DFB5',
  500: '#67D9A6',
  // This was used in the UI but not defined in the palette
  550: '#60B589',
  //
  600: '#4DD397',
  700: '#34CD89',
  800: '#1AC67A',
  900: '#01C06B',
};
