import {
  Skeleton,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { NavLink, Link as RouterLink } from 'react-router-dom';

import { IArticlePreview } from '../../types/IArticle';
import { ANALYTICS_EVENT_NAMES } from '../../../common/enums/eventNames';
import { IEventProperties, ITrackEventInput } from '../../../common/types/ITrackEvent';
import { StyledChip } from '../../atoms/CategoryTags';

import { CardFooter } from './CardFooter';
import { stringToSlug } from '../../utils/unit-functions';
import { palette } from '../../../common/styles/palette';
import { Amplitude } from '../../../common/services/analytics/amplitude.service';

interface ArticlePreviewProps {
  article: IArticlePreview,
  slug: string,
}

// Need to clean this up to only have one click event per element
export const RedFlagCard = ({ article, slug }: ArticlePreviewProps): JSX.Element => {
  const eventProperties: IEventProperties = {
    article: article.slug,
    category: stringToSlug(article.category),
    subcategory: stringToSlug(article.subcategory),
    type: stringToSlug(article.type),
    target: 'article-card',
  };
  const eventInput: ITrackEventInput = {
    eventProperties,
    eventInput: ANALYTICS_EVENT_NAMES.CLICK,
  };

  return (
    <RedFlagCardContainer>
      {/* Div needs to be here to constrain chip width */}
      <div>
        <StyledChip
          size="small"
          label="Red Flag"
          color="primary"
          style={{ backgroundColor: 'background: rgba(169, 58, 153, 0.08)', color: '#A93A99', width: 'auto' }}
        />
      </div>
      <RouterLink to={slug} onClick={() => Amplitude.track(eventInput)} style={{ textDecoration: 'none' }}>
        <Typography variant="h2" style={{ padding: '8px 0px', fontSize: 15 }}>
          {article.title}
        </Typography>
      </RouterLink>
      <CardFooter article={article} />
      <NavLink to={slug} onClick={() => Amplitude.track(eventInput)} style={{ textDecoration: 'none' }} />
    </RedFlagCardContainer>

  );
};

export const RedFlagCardSkeleton = (): JSX.Element => (
  <RedFlagCardContainer>
    <div style={{ width: '100%' }}>
      <Skeleton width="15%" variant="text" height={20} />
      <Skeleton width="60%" variant="text" height={35} />
      <Skeleton width="15%" variant="text" height={20} />
    </div>
  </RedFlagCardContainer>
);

export const RedFlagCardContainer = styled.div`
  border-radius: 16px;
  border: 1px solid ${palette.secondary.dark};
  background-color: linear-gradient(258deg, rgba(255, 223, 253, 0.20) 0%, rgba(255, 255, 255, 0.20) 59.01%), #FFF;
  display: flex;
  padding: 16px;
  flex-direction: column;
`;
