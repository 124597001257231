import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  Navigate,
} from 'react-router-dom';

import { AuthGuard } from '../../common/components/AuthGuard';
import { InternalApp } from '../InternalApp';
import { OrganizationList } from '../pages/OrganizationList';
import { OrganizationPage } from '../pages/Organization';
import { ROUTE_INTERNAL } from '../enums/route';

export const routerInternal = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTE_INTERNAL.ROOT} element={<InternalApp />}>
      <Route
        index
        path={`${ROUTE_INTERNAL.INTERNAL}/`}
        element={(<AuthGuard component={OrganizationList} />)}
      />
      <Route
        path={ROUTE_INTERNAL.ROOT}
        element={<Navigate to={ROUTE_INTERNAL.INTERNAL} replace />}
      />
      <Route path={`${ROUTE_INTERNAL.ORGANIZATION}/:orgId`} element={<AuthGuard component={OrganizationPage} />} />
    </Route>,
  ),
);
