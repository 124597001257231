import { useQuery } from '@tanstack/react-query';

import { IOrganization } from '../../common/types/IOrganization';
import { ApiService } from '../../common/services/api-service';
import { ADMIN_CACHE_KEY } from '../enums/cacheKey';

export const useGetOrganization = () => useQuery<IOrganization>({
  queryKey: [ADMIN_CACHE_KEY.ORGANIZATIONS],
  queryFn: async () => (await ApiService.api.get('admin/organization')).data,
});
