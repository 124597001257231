export const PhysicalHealthIllustration = () => (
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2267.72 1500.27">
    <defs>
      <style>{`
          .ph-cls-1, .ph-cls-2, .ph-cls-3, .ph-cls-4, .ph-cls-5, .ph-cls-6 {
            fill: none;
      }

          .ph-cls-7 {
            clip - path: url(#clippath);
      }

          .ph-cls-8 {
            fill: #01c06b;
      }

          .ph-cls-9 {
            fill: #ebf0f6;
      }

          .ph-cls-10 {
            fill: #f6c7b8;
      }

          .ph-cls-11 {
            fill: #b72e9c;
      }

          .ph-cls-12 {
            fill: #bb2f9f;
      }

          .ph-cls-13 {
            fill: #cf6dbc;
      }

          .ph-cls-14 {
            fill: #e1e800;
      }

          .ph-cls-15 {
            fill: #2f1747;
      }

          .ph-cls-16 {
            fill: #3d3935;
      }

          .ph-cls-2 {
            stroke - linejoin: round;
      }

          .ph-cls-2, .ph-cls-3, .ph-cls-5, .ph-cls-6 {
            stroke - linecap: round;
      }

          .ph-cls-2, .ph-cls-4, .ph-cls-5, .ph-cls-6 {
            stroke - width: 5.77px;
      }

          .ph-cls-2, .ph-cls-4, .ph-cls-6 {
            stroke: #2f1747;
      }

          .ph-cls-3 {
            stroke - width: 4.33px;
      }

          .ph-cls-3, .ph-cls-4, .ph-cls-5, .ph-cls-6 {
            stroke - miterlimit: 10;
      }

          .ph-cls-3, .ph-cls-5 {
            stroke: #f3f6f9;
      }

          .ph-cls-17 {
            opacity: .21;
      }
    `}</style>
      <clipPath id="clippath">
        <rect className="ph-cls-8" width="2267.72" height="1500.27" />
      </clipPath>
    </defs>
    <g id="real_life" data-name="real life">
      <g id="Layer_group" data-name="Layer group">
        <g id="details">
          <g id="heart_star" data-name="heart &amp;amp; star">
            <g>
              {/* <rect className="ph-cls-8" width="2267.72" height="1500.27" /> */}
              <g className="ph-cls-7">
                <g>
                  <g>
                    <path className="ph-cls-15" d="M1322.31,552.38c.4-12.78,.8-25.56,1.2-38.35,3.1-7.23,9.5-12.38,16.78-13.18,9.76-1.07,19.74,5.83,22.77,16.78,21.89-16.87,43.45-29.21,63.51-38.35,156.95-71.46,325.11,6.55,371.48,29.96,51.93,181.35,103.86,362.7,155.78,544.04,32.75,1.2,65.51,2.4,98.26,3.59,2.44-.12,15.66-.51,26.36,9.59,13.03,12.28,11.02,29.42,10.79,31.16v399.93c-88.11,35.37-158.83,37.21-212.07,28.82-278.35-43.87-506.31-434.68-554.87-973.99Z" />
                    <path className="ph-cls-15" d="M1547.67,431.73c-7.69-4.07-31.08-17.67-36.92-42.47-8.66-36.77,27.45-71.97,57.44-85.65,39.62-18.07,88.2-7.51,117.33,16.98,36,30.27,18.08,61.45,56.11,96.85,34.62,32.23,58.48,14.76,88.76,46.27,32.74,34.07,16.64,66.75,47.29,103.19,24.73,29.4,46.17,14.07,83.66,42.94,30.75,23.68,66.62,70.08,55.27,103.89-14.34,42.75-99.87,52.54-140.21,51.11-187.08-6.62-309.05-286.53-328.73-333.12Z" />
                    <path className="ph-cls-12" d="M1146.97,775.23c24.5-34.2,129.77-172.92,323.05-211.44,52.63-10.49,243.88-39.87,404.47,91.18,111.64,91.1,147.7,210.16,158.9,255.05-19.7,14.98-39.4,29.95-59.09,44.93-24.51,43.61-49.03,87.22-73.54,130.83,17.19,8.42,42,23.29,64.35,48.9,29.69,34.01,41.09,71.02,49.9,112.33,20.15,94.4,21.45,186.29,18.38,253.32h-629.03c-85.8-241.7-171.59-483.4-257.39-725.1Z" />
                    <path className="ph-cls-15" d="M1829.34,923.29c17.28-13.24,28.84-18.79,64.86-29.28,58.9-17.15,118.38,4.7,141.72,16.66-3.58,6.12-33.96,56.21-92.12,62.53-48.89,5.31-89.45-2.02-114.46-49.91Z" />
                    <path className="ph-cls-15" d="M1373.87,1091.39h232.15c78.99,0,143.12,64.13,143.12,143.12v265.59h-375.27v-408.71h0Z" />
                    <path className="ph-cls-10" d="M1440.11,923.29c33.48,.13,111.59,5,191.42,55.51,97.99,62,135.29,152.72,146.12,182.49,.58,3.74-.89,7.46-3.83,9.57-3.52,2.53-8.54,2.32-12.12-.64-9.59-12.66-19.19-25.33-28.78-37.99-1.7-2.25-4.93-2.62-7.1-.81l-.08,.06c-2.02,1.68-2.36,4.62-.79,6.73,4.55,6.1,9.18,12.73,13.78,19.89,6.86,10.68,12.64,20.95,17.5,30.52-.44,.52-2.01,2.38-3.56,4.21-1.53,1.81-3.04,3.6-3.45,4.08l-7.41-2.34c-2.83-.89-5.28-2.7-6.97-5.14-9.3-13.46-18.61-26.92-27.91-40.38-3.11-4.5-9.93-.12-7.14,4.58l25.87,43.51c2.72,4.57,2.24,10.77-1.9,14.11-.5,.41-1.05,.77-1.63,1.09-4.32,2.37-9.8,1.6-13.4-1.91-10.63-16.16-21.27-32.33-31.9-48.49-3.26-4.95-10.82-.66-8.24,4.67,5.72,11.84,11.45,23.68,17.17,35.52,1.04,4.02,.14,8.23-2.55,10.85-3.85,3.74-10.93,3.84-15.95-.64-10.21-11.06-20.42-22.12-30.63-33.18-18.4,2.23-39.27,2.79-61.89,0-99.59-12.28-163.92-81.02-186.95-108.47,17.44-49.13,34.88-98.26,52.32-147.39Z" />
                    <path className="ph-cls-10" d="M1571.89,412.67c-18.04,50.24-36.08,100.48-54.13,150.72-1.49,5.03-7.92,28.66,5,52.46,12.49,23,38.19,37.2,62.45,34.97,33.83-3.11,63.59-38.07,60.79-81.6-3.89-33.31-7.77-66.62-11.66-99.92,32.48-5,64.95-9.99,97.43-14.99-4.14-15.41-13.83-42.99-37.47-69.11-15.36-16.97-31.47-27.65-43.3-34.14-2.97,8.08-11.7,28.46-33.31,44.13-18.54,13.44-37.13,16.58-45.8,17.49Z" />
                    <circle className="ph-cls-10" cx="1546.08" cy="435.19" r="36.64" />
                    <line className="ph-cls-6" x1="1530.69" y1="434.97" x2="1566.98" y2="435.41" />
                    <path className="ph-cls-6" d="M1680.09,373.75c4.19,2.67,10.63,7.56,17.04,15.97,4.84,6.36,8.06,12.64,10.1,17.31-3.6,.34-7.21,.68-10.81,1.02" />
                    <path className="ph-cls-10" d="M1828.82,928.02h.04c22.09-9.53,54.94-20.75,96.09-24.73,37.24-3.6,46.08,1.53,68.49,5.82,0,0,24.33,11.58,24.34,11.61,5.65,44.97,9.65,76.81,15.3,121.78v.02c-1.91,14.02-9.11,52.16-40.99,79.14-48.54,41.09-125.81,14.72-166.79,1.41-22.27-7.24-40.83-16.02-55.18-23.92-11.2-6.17-24.71-6.36-35.96-.26-.95,.52-1.91,1.04-2.86,1.57-11.82,6.52-22.64,13.19-32.49,19.78h-.03c-6.26,1.37-12.7-1.17-16.24-6.35-3.26-4.78-3.53-11.07-.72-16.24l.02-.03c9.83-8.4,19.67-16.8,29.5-25.2,3.62-3.1,4.16-8.5,1.22-12.25h0c-.71-.91-1.73-2.2-3.88-4.94-12.72,9.19-25.44,18.37-38.16,27.56-5.88,3.42-13.24,2.45-17.67-2.12-5.04-5.2-5.24-13.96,0-19.79,10.24-7.88,20.49-15.76,30.73-23.64,3.45-2.65,4.41-7.44,2.25-11.22h0c-2.53-4.44-8.33-5.74-12.52-2.83-14.36,9.97-28.72,19.94-43.08,29.92-6.14,3.16-13.72,1.32-17.67-4.24-4.04-5.69-3.15-13.66,2.12-18.38,12.02-8.91,24.04-17.82,36.06-26.73,2.76-2.05,3.43-5.9,1.53-8.76h0c-2.18-3.27-6.83-3.9-9.81-1.1-12.93,12.12-26.52,23.53-42.98,30.1l-.36,.14c-5.67,.87-11.12-1.82-13.4-6.36-2.86-5.71-.34-13.57,6.35-16.96h.02c14.03-14.74,33.04-31.23,57.94-45.24,74.23-41.76,149.6-32.72,178.78-27.57Z" />
                    <path className="ph-cls-4" d="M1825.31,1123.08c-22.26-7.23-40.8-16-55.15-23.9-11.22-6.18-24.75-6.37-36.02-.26-.94,.51-1.89,1.03-2.83,1.55-11.82,6.52-22.64,13.19-32.49,19.78-.01,0-.02,.01-.04,.01-6.26,1.35-12.7-1.18-16.24-6.36-3.26-4.78-3.53-11.07-.72-16.24,0-.01,.01-.02,.02-.03,9.83-8.4,19.67-16.8,29.5-25.2,3.62-3.1,4.16-8.5,1.22-12.25h0c-.71-.91-1.73-2.2-3.88-4.94-12.72,9.19-25.44,18.37-38.16,27.56-5.88,3.42-13.24,2.45-17.67-2.12-5.04-5.2-5.24-13.96,0-19.79,10.24-7.88,20.49-15.76,30.73-23.64,3.45-2.65,4.41-7.44,2.25-11.22h0c-2.53-4.44-8.33-5.74-12.52-2.83-14.36,9.97-28.72,19.94-43.08,29.92-6.14,3.16-13.72,1.32-17.67-4.24-4.04-5.69-3.15-13.66,2.12-18.38,12.02-8.91,24.04-17.82,36.06-26.73,2.76-2.05,3.43-5.9,1.53-8.76h0c-2.01-3.01-6.1-3.78-9.07-1.71-14.68,10.28-29.36,20.55-44.04,30.83-.01,0-.03,.01-.04,.02-5.67,.87-11.12-1.82-13.4-6.36-2.86-5.71-.34-13.57,6.35-16.96,0,0,.01,0,.02-.01,14.03-14.73,33.04-31.21,57.94-45.22" />
                    <circle className="ph-cls-15" cx="1688.49" cy="437.07" r="5.17" />
                    <path className="ph-cls-6" d="M1642.43,403.83c1.06,.14,5.78,.67,10.38-2.32,4.97-3.23,6.08-8.14,6.27-9.04" />
                    <line className="ph-cls-6" x1="1659.09" y1="416.47" x2="1667.7" y2="401.51" />
                    <line className="ph-cls-6" x1="1811.48" y1="790.84" x2="1828.87" y2="928.01" />
                    <path className="ph-cls-4" d="M1828.87,928.01c9.09,.31,20.93,1.58,34.27,5.36,12.87,3.64,23.3,8.52,31.06,12.85" />
                    <g>
                      <line className="ph-cls-3" x1="1667.05" y1="966.54" x2="1673.64" y2="973.86" />
                      <line className="ph-cls-3" x1="1690.83" y1="990.16" x2="1697.42" y2="997.47" />
                      <line className="ph-cls-3" x1="1711.56" y1="1012.31" x2="1718.15" y2="1019.63" />
                      <line className="ph-cls-3" x1="1736.23" y1="1035.76" x2="1742.83" y2="1043.08" />
                    </g>
                    <path className="ph-cls-15" d="M630.14,394.96c5.45-5.78,21.72-24.55,20.5-49.91-1.81-37.62-40.82-62.97-69.37-68.98-37.72-7.94-75.88,13.93-94.28,44.6-22.74,37.9,0,63.74-23.18,107.08-21.1,39.46-45.44,28.31-63.08,66.04-19.07,40.79,2.52,68.51-14.2,111.09-13.49,34.35-35.27,24.69-59.62,61.6-19.96,30.27-38.61,83.73-20.73,113.69,22.6,37.87,96.81,26.78,130.32,15.71,155.39-51.34,188.63-351.16,193.64-400.92Z" />
                    <path className="ph-cls-10" d="M541.65,356.38c-10.99,13.74-28.97,30.95-35.43,78.18-5.39,39.41,2.99,71.04,8.6,87.39,19.58-15.28,39.16-30.56,58.73-45.84,5.25,28.17,10.51,56.35,15.76,84.52-33.33,9.99-84.7,29.79-137.53,70.2-50.19,38.39-81.87,80.23-100.28,108.87-11-20.22-12.65-22.52-18.81-22.46-5.74,2.29-7.44,.81-12.15,22.79-3.13,14.61,.68,21.07-2.69,22.4-2.04,.81-6.43,.9-15.49-8.39-10.97-26.25-21.95-52.5-32.92-78.76-2.87-2.73-6.87-3.77-10.33-2.58-4.57,1.56-7.53,6.78-6.46,12.27,4.3,13.34,8.61,26.68,12.91,40.02-2.8,1.51-5.59,3.01-8.39,4.52-7.53-16.14-15.06-32.28-22.59-48.42-3.7-4.43-9.92-5.73-14.85-3.23-4.42,2.24-7.03,7.15-6.46,12.27,6.24,15.71,12.48,31.42,18.72,47.12-3.44,2.15-6.89,4.3-10.33,6.46-7.75-17.21-15.49-34.43-23.24-51.64-3.78-2.75-8.52-3.64-12.27-1.94-5.54,2.52-8.55,10.53-5.16,18.08,6.03,15.92,12.05,31.85,18.08,47.77,1.29,5.02,1.12,10-1.29,11.62-3.04,2.04-10.3-.81-16.14-9.04-3.44-10.54-6.89-21.09-10.33-31.63-5.48-7.44-8.53-7.38-9.68-7.1-6.54,1.59-5.9,23.36-5.81,25.82,1.29,22.49,4.74,53.68,14.2,89.73,7.73,29.43,17.55,66.85,43.9,107.16,14.66,22.43,44.03,66.16,101.35,94.25,51.68,25.33,100.13,25.23,125.24,23.24,162.35-1.85,324.7-3.7,487.04-5.55-1.41-141.36-2.81-282.73-4.22-424.09-21.58-18.71-53.78-42.6-97.06-62.24-41.89-19.02-79.85-27.57-107.61-31.65-34.81-54.51-69.63-109.01-104.44-163.52-1.45,.74-30.39,15.55-52.21,2.4-16.68-10.06-19.67-15.83-20.36-18.99Z" />
                    <rect className="ph-cls-10" x="438.33" y="977.83" width="571.91" height="281.33" />
                    <circle className="ph-cls-10" cx="645.39" cy="381.25" r="31.7" />
                    <line className="ph-cls-6" x1="656.95" y1="369.3" x2="633.82" y2="393.2" />
                    <path className="ph-cls-6" d="M515.3,408.56c-3.4,5.59-8.13,15.02-10.5,28.19-1.8,9.96-1.65,18.76-1.11,25.07,4.65-2,9.3-4,13.94-5.99" />
                    <line className="ph-cls-6" x1="548.87" y1="410.22" x2="551.14" y2="419.5" />
                    <path className="ph-cls-6" d="M539.15,425.06c.51,.98,2.94,5.32,8.39,7.24,5.43,1.91,10.04,.06,11.05-.38" />
                    <path className="ph-cls-9" d="M518.28,469.74c1.57,6.78,3.15,13.55,4.72,20.33,12.22-7.99,24.45-15.97,36.67-23.96-2.18-1.47-10.77-6.9-22.51-5.45-10.3,1.27-16.82,7.08-18.88,9.08Z" />
                    <path className="ph-cls-12" d="M448.86,632.6c6.97-6.5,15.56-13.57,25.9-20.4,14.64-9.69,28.55-16.1,40.02-20.4-1.35,14.45-1.3,36.28,7.85,59.64,31.37,80.09,136.28,92.78,142.04,93.39,74.64,7.91,159.72-29.73,180.5-85.54,6.53-17.54,6.12-35.29,18.05-40.02,21.98-8.72,60.01,36.02,72.2,53.36,63.66,90.57-6.92,246.13-30.61,294.29-72.47,2.64-150.1,3-232.29,0-80.65-2.94-156.68-8.79-227.59-16.48-3.35-52.55-4.97-109.22-3.92-169.51,.91-52.41,3.74-101.95,7.85-148.32Z" />
                    <path className="ph-cls-12" d="M438.01,1212.48c64.25,11.29,137.89,20.37,219.46,23.87,100.52,4.31,190.45-.85,266.75-9.68,26.91,90.86,53.82,181.72,80.73,272.58-99.87,.72-199.74,1.45-299.62,2.17-18.96-42.94-37.92-85.88-56.87-128.82-12.85,50.35-25.7,100.7-38.55,151.05-82.8,1.46-165.6,2.91-248.4,4.37,25.5-105.18,51-210.36,76.5-315.54Z" />
                    <path className="ph-cls-6" d="M246.92,855.76c-8.94,23.21,.99,46.7,17.66,53.55,11.44,4.7,27.64,2.22,35.89-6.84,7.12-7.81,4.78-16.63,11.96-21.08,6.88-4.26,16.63-.9,23.36,2.28" />
                    <path className="ph-cls-6" d="M206.78,829.27c-4.27-2.47-21.96-13.34-27.92-35.32-5.98-22.07,2.77-44.65,16.52-56.97,3-2.68,11.95-10.48,24.5-10.82,3.63-.1,11.16-.3,14.81,4.56,5.2,6.91-1.95,19.91-4.56,22.79-5.44,6.01-7,6.45-15.38,14.81-5.29,5.27-10.12,11.89-8.55,16.52,1.81,5.33,12.42,7.73,25.64,6.27" />
                    <path className="ph-cls-6" d="M328.47,834.96c-9.74,6.48-22.26,5.64-29.95-1.36-8.43-7.68-1.77-29.09-.03-35.2,1.98-6.96,3.93-9.96,3.1-15.55-1.17-7.86-7.19-16.55-13.62-16.87-7.24-.36-16.36,9.84-17.39,26.03" />
                    <line className="ph-cls-6" x1="585.75" y1="558.96" x2="591.12" y2="603.24" />
                    <ellipse className="ph-cls-15" cx="652.58" cy="1174.33" rx="6.39" ry="11.56" />
                    <g className="ph-cls-17">
                      <path className="ph-cls-5" d="M548.13,1091.39c-1.15,1.29-4.3,5.14-6.35,12.25-2.9,10.06-1.3,18.68-1.02,20.11" />
                      <path className="ph-cls-5" d="M567.89,1155.16c-.76,2.69-2.51,10.3,.79,18.88,4.67,12.13,15.75,16.6,17.62,17.32" />
                      <path className="ph-cls-5" d="M515.19,1113.32c-2.38,2.55-8.65,9.94-9.44,20.41-1.11,14.82,9.64,24.1,11.46,25.62" />
                      <path className="ph-cls-5" d="M762.02,1099.86c1,1.5,3.68,5.94,4.71,13.56,1.45,10.78-1.55,19.4-2.07,20.82" />
                      <path className="ph-cls-5" d="M810.36,1108.69c1.57,2.12,5.69,8.31,6.01,17.66,.44,13.23-7.09,22.33-8.37,23.83" />
                      <path className="ph-cls-5" d="M781.02,1155.74c.17,2.64,.28,10.11-4.54,18.47-6.81,11.83-18.09,16.09-19.98,16.77" />
                    </g>
                    <path className="ph-cls-2" d="M350.86,728.04c2.76,11.85,8.62,30.44,21.93,49.87,12.1,17.66,25.68,29.2,35.15,36.05" />
                    <path className="ph-cls-9" d="M620.83,253.66c3.73-2.09,10.02-6.32,15.02-13.91,8.58-13.02,7.21-26.66,6.67-30.59,.33,1.54,4.89,21.19,23.36,28.37,15.2,5.91,33.33,.86,44.5-12.79-1.1,1.5-13.6,19.16-6.12,40.6,7.36,21.09,27.58,27.27,29.48,27.81-2.28-.02-17.35,.08-27.81,12.24-7,8.13-8.1,17.22-8.34,20.58" />
                    <line className="ph-cls-6" x1="446.89" y1="951.56" x2="440.49" y2="1060.02" />
                    <path className="ph-cls-15" d="M1260.26,345.92c7.12-4.84,28.65-20.81,31.81-46.05,4.69-37.44-34.35-68.62-65.17-79.06-40.72-13.79-87.25,1.79-113.27,29.17-32.17,33.84-11.37,62.94-45,102.08-30.61,35.64-55.81,20.79-82.24,55.26-28.58,37.28-9.41,68.05-35.69,107.46-21.2,31.79-43.81,18.82-77.58,51.43-27.7,26.74-58.07,76.6-43.45,108.99,18.49,40.96,103.35,41.72,142.75,36.07,182.73-26.19,273.36-317.01,287.84-365.35Z" />
                    <path className="ph-cls-11" d="M808.59,840.32c1.36-25.79,10.5-146.44,112.63-241.57,10.21-9.51,115.94-105,264.29-96.33,155.54,9.09,296.64,129.1,348.39,300.85-23.03,16.3-46.05,32.6-69.08,48.91-3.5,215.88-7.01,431.77-10.51,647.65-189.21,.49-378.42,.99-567.63,1.48V861.06c-26.03-6.92-52.06-13.83-78.09-20.75Z" />
                    <path className="ph-cls-13" d="M808.59,840.32c1.36-25.79,10.5-146.44,112.63-241.57,10.21-9.51,115.94-105,264.29-96.33,155.54,9.09,296.64,129.1,348.39,300.85-23.03,16.3-46.05,32.6-69.08,48.91-3.5,215.88-7.01,431.77-10.51,647.65-189.21,.49-378.42,.99-567.63,1.48V861.06c-26.03-6.92-52.06-13.83-78.09-20.75Z" />
                    <path className="ph-cls-15" d="M1421.48,780.63c6.45-2.37,33.72-11.71,65.7-4.25,28.83,6.72,43.31,22.86,47.35,27.76-7.3,11.33-16.4,16.46-21.68,18.81-25.57,11.35-64.85,3.28-94.67-22.16,1.1-6.72,2.2-13.43,3.29-20.15Z" />
                    <path className="ph-cls-15" d="M808,843.44c16.39-11.14,44.33-26.98,82.22-34.21,57.02-10.88,103.11,4.13,123.03,12-21.84,11.55-60.35,28.26-111.03,31.81-39.83,2.79-72.63-3.58-94.22-9.6Z" />
                    <path className="ph-cls-10" d="M1172.92,340c9.27-.05,29.71-1.4,51.29-13.99,22.65-13.21,34.04-31.5,38.47-39.63,15.21,16.51,35.44,42.34,51.29,78.1,19.54,44.09,23.78,83.81,24.48,108.4-31.47-8.55-62.94-17.1-94.42-25.64,1.17,41.96,2.33,83.93,3.5,125.89-5.46,39.98-37.47,68.93-71.1,69.94-41.03,1.23-80.8-39.23-75.77-89.75,24.09-71.1,48.18-142.21,72.27-213.31Z" />
                    <polygon className="ph-cls-15" points="1244.42 446.89 1206.16 435.19 1248.35 591.79 1244.42 446.89" />
                    <rect className="ph-cls-16" x="1281.37" y="558.87" width="16.3" height="148.28" transform="translate(825.3 -726.43) rotate(45)" />
                    <path className="ph-cls-10" d="M1544.08,843.8l-9.69-22.15c-6.61-15.12-16.79-27.68-29.18-37.17-14.47-15.06-29.24-31.57-42.13-44.85-11.04-13.39-22.02-26.71-33.06-40.1-16.8-24.9-46.95-59.38-95.63-79.27-21.77-8.9-42.22-12.81-58.98-14.43,0,0-.01,0-.02,0-5.6-2.58-12.06-.28-14.38,4.37-1.85,3.71-.87,8.51,2.55,11.69l2.86,1.64c11.76,6.72,25.21,9.15,38.77,10.92,3.43,.45,5.41,3.76,4.34,6.81-.93,2.63-3.81,4.12-6.55,3.38-11.93-3.23-23.85-6.45-35.78-9.68-5.76-1.02-11.47,2.37-13.18,7.77-1.68,5.27,.92,11.01,6.1,13.35,13.8,4.19,27.61,8.38,41.41,12.57,4.03,1.22,6.08,5.56,4.44,9.37-1.4,3.25-5.05,5.01-8.53,4.13-10.35-2.61-20.71-5.22-31.06-7.83-6.48-.44-12.16,4.03-13.05,9.82-.78,5.08,2.27,10.25,7.49,12.29,12.46,3.54,24.92,7.07,37.38,10.61l-1.3,4.92c-.99,3.73-4.82,6.07-8.69,5.3-10.49-2.09-20.98-4.18-31.47-6.27-.01,0-.02,0-.03,0-4.82,.77-8.84,4.07-10.35,8.51-1.64,4.82-.07,10.18,3.98,13.49,0,0,.02,0,.02,.01,9.3,3,19.15,6.58,29.4,10.86,.85,.35,1.69,.71,2.52,1.07,9.65,4.12,16.31,12.78,17.87,22.93,1.89,12.31,5.25,27.45,11.02,44.04l-2.21,.93,48.08,109.91c21.2,48.47,78.97,70.64,129.02,49.52l5.77-2.44c50.05-21.12,73.44-77.54,52.24-126.01Z" />
                    <g>
                      <path className="ph-cls-1" d="M1488.21,1012.31v-337.68h-93.6c0,84.18-66.48,152.42-148.5,152.42s-148.5-68.24-148.5-152.42h-89.12v337.68h479.72Z" />
                      <path className="ph-cls-9" d="M1246.11,827.06c76.19,0,138.98-58.9,147.51-134.75,1.06-9.38-5.99-17.67-15.19-17.67h-264.64c-9.2,0-16.25,8.29-15.19,17.67,8.53,75.86,71.32,134.75,147.51,134.75Z" />
                    </g>
                    <path className="ph-cls-10" d="M832.7,845.1c.48,15.29,4.27,82.85,58.28,132.15,9.38,8.56,55.96,51.08,115.74,44.32,32.74-3.7,56.07-20.67,72.23-33.65,85-68.3,159.16-177.24,191.83-228.06,3.3-5.13,1.33-12.37-4.42-14.39-.04-.01-.09-.03-.13-.04-4.65-1.57-9.92,.35-12.6,4.67-10.22,10.08-20.43,20.16-30.65,30.24-2.64,2.61-6.91,2.54-9.48-.15-2.56-2.69-2.42-6.96,.32-9.48,12.5-11.47,24.99-22.93,37.49-34.4,3.15-2.89,4.55-7.59,2.61-11.41-.53-1.06-1.26-2.01-2.17-2.81-3.68-3.24-9.61-3.16-13.53,.47l-36.91,32.04c-2.9,2.52-7.37,1.82-9.37-1.46-1.57-2.57-1.06-5.9,1.2-7.89l42.28-37.15c2.25-4.37,2.05-9.33-.47-12.13-3.53-3.93-11.98-3.99-17.26,2.33-12.03,10.79-24.06,21.57-36.09,32.36-2.51,2.25-6.35,2.09-8.67-.35h0c-2.52-2.66-2.22-6.9,.66-9.17,12.21-9.63,24.42-19.27,36.63-28.9,.58-1.38,2.62-6.36,.47-9.8-2.9-4.63-12.98-5.74-23.79,.47-14.62,7.93-29.24,15.86-43.86,23.79-.49-1.44-1.8-5.94,0-11.2,1.5-4.37,4.35-6.93,5.6-7.93,.77-3.92,.8-7.81-1.4-9.8-5.24-4.73-20.71,3.6-28.93,10.26-17.22,13.96-21.27,35.78-22.4,47.12-42.77,28.93-85.54,57.85-128.31,86.78-17.81-1.76-42.39-2.32-70.92,2.8-26.75,4.8-48.4,13.04-64.01,20.36Z" />
                    <circle className="ph-cls-10" cx="1145.98" cy="363.35" r="38.47" />
                    <path className="ph-cls-6" d="M1528.67,943.06c-3.49,4.53-7.59,9.85-16.7,16.15,0,0-15.43,10.66-43.57,19.74" />
                    <line className="ph-cls-6" x1="1128.63" y1="354.37" x2="1161.35" y2="370.07" />
                    <line className="ph-cls-6" x1="1242.65" y1="355.5" x2="1263.04" y2="355.5" />
                    <path className="ph-cls-6" d="M1295.03,344.56c5.31,4.49,13.2,12.33,19.65,24.39,4.88,9.12,7.33,17.65,8.63,23.87-5.59-.69-11.19-1.38-16.78-2.08" />
                    <path className="ph-cls-6" d="M1266.97,390.02c7.39,3.8,12.13,11.35,12.35,19.64,.21,8.26-4.09,16.01-11.22,20.2" />
                    <line className="ph-cls-6" x1="1279.59" y1="412.95" x2="1299.45" y2="417.6" />
                    <path className="ph-cls-6" d="M1373.87,383.53c4.66,1.01,9.07,5.04,11.46,10.49,2.38,5.43,2.41,11.44,.08,15.67" />
                    <path className="ph-cls-6" d="M1393.23,362.22c5.1,1.04,11.33,5.87,16.22,12.58,4.86,6.68,7.66,14.2,7.28,19.61" />
                    <g>
                      <line className="ph-cls-3" x1="1139.61" y1="728.05" x2="1145.52" y2="735.92" />
                      <line className="ph-cls-3" x1="1161.18" y1="753.7" x2="1167.1" y2="761.57" />
                      <line className="ph-cls-3" x1="1179.86" y1="777.61" x2="1185.77" y2="785.48" />
                      <line className="ph-cls-3" x1="1202.34" y1="803.17" x2="1208.26" y2="811.04" />
                    </g>
                    <g>
                      <line className="ph-cls-3" x1="1360.97" y1="729.05" x2="1353.16" y2="735.04" />
                      <line className="ph-cls-3" x1="1335.54" y1="750.87" x2="1327.72" y2="756.86" />
                      <line className="ph-cls-3" x1="1311.8" y1="769.77" x2="1303.98" y2="775.76" />
                      <line className="ph-cls-3" x1="1286.46" y1="792.5" x2="1278.64" y2="798.49" />
                    </g>
                    <path className="ph-cls-6" d="M1032.44,324.89c-.77-4.71,1.36-10.28,5.56-14.51,4.17-4.21,9.76-6.44,14.54-5.82" />
                    <path className="ph-cls-6" d="M1005.51,314.69c-.9-5.12,1.31-12.69,5.76-19.7,4.43-6.97,10.4-12.33,15.57-13.97" />
                    <g>
                      <line className="ph-cls-3" x1="1336.88" y1="633.01" x2="1334.52" y2="640.18" />
                      <line className="ph-cls-3" x1="1330.18" y1="657.7" x2="1327.82" y2="664.87" />
                      <line className="ph-cls-3" x1="1323.32" y1="679.89" x2="1320.96" y2="687.07" />
                      <line className="ph-cls-3" x1="1317.07" y1="705.06" x2="1314.71" y2="712.24" />
                    </g>
                  </g>
                  <path className="ph-cls-14" d="M1927.79,97.09l-2.2,12.95c-.92,5.41,2.75,10.52,8.17,11.39l47.66,7.64-63.58,72.32c-9.88,11.24-3.19,28.96,11.65,30.86l82.57,10.59c5.29,.68,10.16-2.96,11-8.23l2.29-14.26c.89-5.54-3.01-10.7-8.58-11.36l-53.57-6.38,67.57-73.11c10.37-11.22,3.61-29.47-11.57-31.23l-80.55-9.33c-5.21-.6-9.99,2.98-10.87,8.15Z" />
                  <path className="ph-cls-14" d="M1799.62,194.92l-1.66,9.79c-.69,4.09,2.08,7.96,6.18,8.62l36.04,5.78-48.08,54.69c-7.47,8.5-2.41,21.9,8.81,23.34l62.44,8c4,.51,7.68-2.24,8.32-6.22l1.73-10.79c.67-4.19-2.28-8.09-6.49-8.59l-40.51-4.82,51.09-55.28c7.84-8.49,2.73-22.29-8.75-23.62l-60.91-7.06c-3.94-.46-7.55,2.25-8.22,6.17Z" />
                  <g>
                    <path className="ph-cls-14" d="M147.86,107.38l18.14-5.83c7.11-2.29,14.76,1.45,17.33,8.46l41.27,112.47c2.57,7.02-.85,14.81-7.76,17.66l-17.39,7.18c-7.3,3.01-15.64-.64-18.38-8.05l-42.03-113.82c-2.74-7.43,1.26-15.65,8.8-18.08Z" />
                    <path className="ph-cls-14" d="M206.44,268.84l18.14-5.83c7.11-2.29,14.76,1.45,17.33,8.46l10.61,28.16c2.57,7.02-.85,14.81-7.76,17.66l-17.39,7.18c-7.3,3.01-15.64-.64-18.38-8.05l-11.36-29.51c-2.74-7.43,1.26-15.65,8.8-18.08Z" />
                  </g>
                  <g>
                    <path className="ph-cls-14" d="M330.34,309.21l19.05-.3c7.47-.12,13.7,5.68,14.12,13.14l1.21,17.48c.42,7.46-5.13,13.92-12.56,14.64l-18.73,1.81c-7.86,.76-14.78-5.17-15.24-13.05l-1.54-18.99c-.46-7.91,5.76-14.61,13.68-14.73Z" />
                    <path className="ph-cls-14" d="M280.48,226.31l14.89,6.4c5.84,2.51,12.58-.47,14.66-6.48,2.08-6.01,4.52-12.89,5.97-16.33,2.89-6.87,14.46-10.12,26.03-2.89,11.57,7.23,4.34,24.59-2.53,28.93-6.87,4.34-20.8,9.72-21.88,20.25-.57,5.57-.39,14.11-.09,20.93,.26,6.02,5.35,10.69,11.37,10.45l14.42-.59c5.78-.24,10.37-4.93,10.49-10.71l.16-7.79s27.12-9.4,29.29-47.37c2.17-37.97-31.92-61.16-62.91-56.77-32,4.53-42.76,34.24-46.2,49.53-1.16,5.14,1.52,10.36,6.35,12.44Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
