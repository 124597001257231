import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { AVATAR_SIZE } from '../enums/avatarSize';
import { ROUTE } from '../enums/route';
import { SPACE } from '../../common/styles/constants';
import { ProfileAvatar } from './ProfileAvatar';
import { IAuthUser } from '../../common/types/IAuthUser';

interface IAvatarButtonProps {
  user: IAuthUser | undefined;
}

export const AvatarButton = ({ user }: IAvatarButtonProps): JSX.Element => (
  <Button
    component={RouterLink}
    to={ROUTE.PROFILE}
    variant="text"
    aria-label="Profile Avatar Button"
    aria-describedby="profile-avatar"
    sx={{
      p: 0,
      ':hover, :focus': { backgroundColor: 'transparent' },
      height: { xs: SPACE * 2.5, md: SPACE * 3 },
    }}
  >
    <ProfileAvatar size={AVATAR_SIZE.medium} user={user} />
  </Button>
);
