import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import { theme } from '../../common/theme/theme';

interface IOrgBannerProps {
  orgName: string;
  logout: () => void;
}

export const OrgBanner = ({ orgName, logout }: IOrgBannerProps) => (
  <Box style={{
    backgroundColor: theme.palette.success.light,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.success.dark}`,
    padding: '0px 20px',
    borderRadius: 10,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}
  >
    <Typography variant="body1" sx={{ width: '400px' }}> Logged in as member of the {orgName} organization.</Typography>
    <Button onClick={logout} sx={{ my: '16px', '&:hover': { bgcolor: theme.palette.success.light } }}>
      Logout
    </Button>
  </Box>
);
