import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { useGetUser } from './common/hooks/useGetUser';
import { AuthenticatedApp } from './AuthenticatedApp';
import { Api } from './common/services/api-service';

export const App = (): JSX.Element => {
  const {
    user, isLoading, loginWithRedirect, getAccessTokenSilently,
  } = useAuth0();

  const { data: joyuusUser, isLoading: joyuusUserLoading } = useGetUser({ isAuthenticated: !!user });

  const initAPI = async () => {
    const token = await getAccessTokenSilently();
    Api.initApi(token);
  };

  useEffect(() => {
    if (user) {
      initAPI();
    }
  }, [user]);

  if (isLoading || joyuusUserLoading) {
    return <div>Loading...</div>;
  }

  if (user && joyuusUser) {
    return (
      <AuthenticatedApp user={joyuusUser} />
    );
  }

  if (!user && !joyuusUser) {
    loginWithRedirect();

    return <div>Redirecting...</div>;
  }
  return <div>ERROR</div>;
};
