import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';

interface IConfirmDialogProps {
  open: boolean;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
  title?: string;
}

export const ConfirmationDialog = ({
  open, title, message, onConfirm, onClose,
}: IConfirmDialogProps) => {
  const confirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      open={open}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={confirm} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
