import { Typography, useMediaQuery } from '@mui/material';
import { styled } from 'styled-components';
import { Link } from 'react-router-dom';

import { useGetDiscover } from '../hooks/useDiscover';
import { IArticlePreview } from '../types/IArticle';
import { Grid } from '../../common/atoms/Grid';
import { theme } from '../../common/theme/theme';
import { useGetDiscoverResources } from '../hooks/useArticles';
import { FeatureCard, FeatureCardSkeleton } from '../components/Card/FeatureCard';
import { categories, redFlag } from '../utils/categories';
import { ResourceBlock } from '../components/ResourceBlock';
import { ArticleSearch } from '../components/ArticleSearch';
import { ANALYTICS_EVENT_NAMES } from '../../common/enums/eventNames';
import { IEventProperties, ITrackEventInput } from '../../common/types/ITrackEvent';
import { CategoryColorBlock } from '../atoms/CategoryColorBlock';
import { ROUTE } from '../enums/route';
import { Amplitude } from '../../common/services/analytics/amplitude.service';

interface IRedFlagButton {
  trackFunction: (data: ITrackEventInput) => void;
}

const ArticlePreviewCarousel = ({ articles, showDescription }: { articles: IArticlePreview[], showDescription: boolean }): JSX.Element => (
  <Grid rowgap={theme.space(3)} colgap={theme.space(4)} justifyItems="space-between">
    <Typography variant="h3">Featured</Typography>
    {articles.map((article: IArticlePreview) => (
      <Grid.Cell key={article.slug} $bleed xs={12}>
        <FeatureCard article={article} slug={`${ROUTE.ARTICLE}/${article.slug}`} showCategory={false} showDescription />
      </Grid.Cell>
    ))}
  </Grid>
);

export const FeatureSkeletonList = ({ count, headline }: { count: number, headline?: string }): JSX.Element => (
  <Grid rowgap={theme.space(3)} colgap={theme.space(4)} justifyItems="space-between">
    { headline && <Typography variant="h3">{headline}</Typography>}
    { Array.from({ length: count }).map((value: unknown, index: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid.Cell key={index} $bleed xs={12}>
        <FeatureCardSkeleton showDescription />
      </Grid.Cell>
    ))
      }
  </Grid>
);

const RedFlagButton = ({ trackFunction }: IRedFlagButton) => {
  const eventProperties: IEventProperties = {
    page: 'home',
    category: 'red-flags',
    target: 'category-button',
  };
  const eventInput: ITrackEventInput = {
    eventProperties,
    eventInput: ANALYTICS_EVENT_NAMES.CLICK,
  };
  return (
    <Link to={`${ROUTE.RED_FLAG}`} style={{ textDecoration: 'none' }} onClick={() => trackFunction(eventInput)}>
      <CategoryColorBlock
        label={redFlag.label}
        description={redFlag.description}
        color={redFlag.color}
        backgroundColor={redFlag.backgroundColor}
        textSize="h2"
        svg={redFlag.svg}
        height={48}
      />
    </Link>
  );
};

export const Home = (): JSX.Element => {
  const { data: discoveryPreviews, isLoading: discoveryLoading } = useGetDiscover();
  const { data: resources, isLoading: resourcesLoading } = useGetDiscoverResources();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const trackCategoryClick = (categorySlug: string) => {
    const eventProperties: IEventProperties = {
      page: 'home',
      category: categorySlug,
      target: 'category-button',
    };
    const eventInput: ITrackEventInput = {
      eventProperties,
      eventInput: ANALYTICS_EVENT_NAMES.CLICK,
    };
    Amplitude.track(eventInput);
  };

  return (
    <div className="content">
      <Grid rowgap={theme.space(4)} colgap={theme.space(4)}>
        <Grid.Cell
          xs={12}
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0,
          }}
        >
          <SearchContainer>
            <ArticleSearch />
          </SearchContainer>
        </Grid.Cell>

        {categories.map((category) => (
          <Grid.Cell $bleed xs={6} sm={6} md={3} lg={3} key={category.slug}>
            <Link
              to={`${ROUTE.CATEGORY}/${category.slug}`}
              style={{ textDecoration: 'none' }}
              onClick={() => trackCategoryClick(category.slug)}
            >
              <CategoryColorBlock
                height={48}
                label={category.label}
                color={category.color}
                backgroundColor={category.backgroundColor}
                svg={category.svg}
                textSize="h2"
              />
            </Link>
          </Grid.Cell>
        ))}

        { isTablet && (
          <Grid.Cell $bleed xs={12} md={4}>
            <RedFlagContainer>
              <RedFlagButton trackFunction={Amplitude.track} />
            </RedFlagContainer>
          </Grid.Cell>
        )}

        <Grid.Cell $bleed xs={12} md={8}>
          { discoveryLoading || !discoveryPreviews?.length ? <FeatureSkeletonList count={5} headline="Featured" />
            : <ArticlePreviewCarousel articles={discoveryPreviews} showDescription /> }
        </Grid.Cell>

        <Grid.Cell $bleed xs={12} md={4}>
          { !isTablet && (
            <RedFlagContainer>
              <RedFlagButton trackFunction={Amplitude.track} />
            </RedFlagContainer>
          )}
          <ResourceBlock loading={resourcesLoading} resources={resources} headline="Resources" />
        </Grid.Cell>
      </Grid>
    </div>
  );
};

const SearchContainer = styled.div`
  margin-bottom: 8px;
  padding: 0px;
  width: 100%;
  @media (min-width: ${theme.breakpoints.values.md}px)  {
    width: 380px;
  }
`;

const RedFlagContainer = styled.div`
  margin-bottom: 16px;
  margin-top: 8px;
  @media (min-width: ${theme.breakpoints.values.md}px)  {
    margin-bottom: 24px;
    margin-top: 24px;
  }
`;
