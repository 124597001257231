// Source find for all Joyuus colors used in both styled components and material UI.
// Find Figma source here: https://www.figma.com/file/tRoGWjkoHwZUapnbhIFMbZ/Joyuus---Version-1

// import { nightfall } from './colors/nightfall';
import { orchid } from './colors/orchid';
// import { blossom } from './colors/blossom';
import { charcoal } from './colors/charcoal';
import { emerald } from './colors/emerald';
// import { grey } from './colors/grey';
import { limeade } from './colors/limeade';
// import { violet } from './colors/violet';
import { red } from './colors/red';

/* We shouldn’t be adding any new colors to the palette without it explicitly being added to the designer’s palette in figma as well, making it official. If you come across a design that contains what appears to be a new color, talk to the designer about it and find out if it was a) intentional b) an official consideration. The reason for this is to avoid the wild-wild-west approach we’ve seen in the past. */

/* Don't hard code colors anywhere else. The one exception to this rule is drop shadows. Those will never be consistent (they vary by component) and are usually always a mix/blend of black in ways that we will never use those colors again. */

/* The intent is not to be tyrannical, but to be consistent & intentful. Having these rules in place helps us move faster and be more scalable in the long run. Consistent colors becomes immensely important to conveying professionalism to our users. */

export const palette = {
  common: {
    black: charcoal[900],
    white: '#FFFFFF',
  },
  a11yFocusRing: 'rgba(184, 46, 157, 0.5)', // orchid[900] with 50% opacity
  primary: {
    ...orchid,
    lighter: orchid[50],
    light: orchid[100],
    main: orchid[800],
    dark: orchid[800],
    darker: orchid[900],
  },
  secondary: {
    main: '#F8F8F8',
    light: '#EFEFEF',
    dark: '#F2F4F9',
  },
  inverse: {
    main: '#FFFFFF',
    contrastText: '#1F1F17',
  },
  background: {
    paper: '#FFFFFF',
    default: '#F2F4F9',
  },
  success: {
    ...emerald,
    light: emerald[100],
    main: emerald[700],
    dark: emerald[800],
    darker: emerald[900],
  },
  error: {
    ...red,
    light: red[100],
    main: red[600],
    dark: red[700],
    darker: red[800],
  },
  warning: {
    ...limeade,
    light: limeade[100],
    main: limeade[700],
    dark: limeade[800],
    darker: limeade[900],
  },
  text: {
    primary: '#1F1F17',
    secondary: '#6D6D68',
    inverse: '#FFFFFF',
    disabled: '#6D6D68',
  },
  divider: 'rgba(0, 0, 0, 0.1)',
  border: 'rgba(0, 0, 0, 0.15)',
  overlay: 'rgba(31, 31, 23, 0.6)',
};
