import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const useURLQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const stringToSlug = (str: string) => {
  if (!str) {
    return '';
  }
  return str.toLowerCase().replace(/\s+/g, '-');
};
