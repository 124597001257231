import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiService } from '../../common/services/api-service';
import { IUser } from '../../common/types/IUser';
import { INTERNAL_CACHE_KEY } from '../enums/cacheKey';

export const useGetOrganizationUsers = (orgId?: string) => useQuery<IUser[]>({
  queryKey: [INTERNAL_CACHE_KEY.USERS],
  queryFn: async () => (await ApiService.api.get(`internal/user/${orgId}`)).data,
  enabled: !!orgId,
});

export const useMutateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (updatedUser: IUser) => (await ApiService.api.put(`internal/user/${updatedUser.id}`, { updatedUser })).data,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [INTERNAL_CACHE_KEY.USERS],
      });
    },
  });
};
