import { styled } from 'styled-components';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';

interface ICategoryButton {
  label: string;
  description?: string;
  color: string;
  backgroundColor: string;
  svg: JSX.Element;
  textSize?: TypographyTypeMap['props']['variant'];
  height?: number;
}

export const CategoryColorBlock = ({
  label,
  description,
  color,
  backgroundColor,
  textSize = 'h2',
  height = 64,
  svg,
}: ICategoryButton) => (
  <CategoryBtn style={{ backgroundColor, height: `${height}px` }}>
    <TitleContainer>
      <Typography variant={textSize} style={{ color }}>{label}</Typography>
      {description && <Typography variant="body1" style={{ color }}>{description}</Typography> }
    </TitleContainer>
    <CategoryIcon style={{
      width: textSize === 'h1' ? 130 : 105,
    }}
    >
      { svg }
    </CategoryIcon>
  </CategoryBtn>
);

const CategoryBtn = styled.div`
  display: flex;
  border-radius: 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
`;

const TitleContainer = styled.div`
  white-space: nowrap;
  z-index: 10;
`;

const CategoryIcon = styled.div`
  position: absolute;
  right: 0;
  top: 15%;
`;
