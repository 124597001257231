export const nightfall = {
  50: '#EAE8ED',
  100: '#D5D1DA',
  200: '#C1B9C8',
  300: '#ACA2B5',
  400: '#978BA3',
  500: '#827491',
  600: '#6D5D7E',
  700: '#59456C',
  800: '#442E59',
  900: '#2F1747',
};
