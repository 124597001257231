import { Bookmark, BookmarkBorder } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from 'styled-components';

import { useToggleBookmark, useGetUserBookmarks } from '../hooks/useBookmarks';

import { IArticle, IArticlePreview } from '../types/IArticle';

// On = bookmarked = true
// Off = not bookmarked = false
export const ToggleBookmark = ({ article }: { article: IArticle | IArticlePreview }) => {
  const userBookmarks = useGetUserBookmarks();
  const { mutate: toggleBookmark } = useToggleBookmark();
  const [isLoading, setIsLoading] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);

  useEffect(() => {
    if (userBookmarks && article?.id) {
      const someResult = userBookmarks.some(
        (i: any) => i?.id && i?.id === article?.id,
      );
      setBookmarked(someResult);
    }
  }, [article, bookmarked, userBookmarks]);

  const handleBookmarkIconClick = () => {
    setIsLoading(true);
    const updatedBookmark = !bookmarked;
    setBookmarked(updatedBookmark);
    toggleBookmark(
      {
        article,
        bookmarkStatus: updatedBookmark,
      },
      {
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const renderToggleButton = () => (bookmarked ? (
    <Bookmark
      cursor="pointer"
      onClick={() => handleBookmarkIconClick()}
    />
  ) : (
    <BookmarkBorder
      cursor="pointer"
      onClick={() => handleBookmarkIconClick()}
    />
  ));

  return (
    <Container>
      {isLoading ? <CircularProgress size={14} /> : renderToggleButton()}
    </Container>
  );
};

const Container = styled.div`
  width: 16px;
  height: 16px;
  color: #69518c;
`;
