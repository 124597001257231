import { Remove } from '@mui/icons-material';
import {
  TableRow, TableCell, Typography, Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { USER_ROLE } from '../enums/userRole';
import { CircularIconButton } from './CircularIconButton';
import { UserRoleSelect } from './UserRoleSelect';
import { IUser } from '../types/IUser';
import { ITrackValue } from '../types/ITrackEvent';
import { useMutateUser } from '../../app-admin/hooks/useUsers';

interface IUsersTableRowProps {
  user: IUser;
  setMessage: (message: string) => void;
}

export default function UsersTableRow({ user, setMessage }: IUsersTableRowProps) {
  const [userRole, setUserRole] = useState<USER_ROLE>(user.role);
  const { mutate: updateUser, isSuccess: updateSuccess, isError: updateError } = useMutateUser();

  useEffect(() => {
    if (updateError) {
      setMessage('Error updating user');
    }
    if (updateSuccess) {
      setMessage('User updated');
    }
  }, [updateSuccess, updateError]);

  const removeUser = () => {
    const updatedUser = {
      ...user,
      role: USER_ROLE.USER,
      orgId: null,
      organization: null,
    };
    const value: ITrackValue = {
      previous: user,
      new: updatedUser, 
    };
    updateUser(value);
  };

  const updateUserRole = (role: USER_ROLE) => {
    setUserRole(role);
    const updatedUser = {
      ...user,
      role,
    };
    const value: ITrackValue = {
      previous: user,
      new: updatedUser, 
    };
    updateUser(value);
  };

  return (
    <TableRow
      key={user.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Typography variant="body1">{user.email}</Typography>
      </TableCell>
      <TableCell align="center">
        <UserRoleSelect
          value={userRole}
          onChange={updateUserRole}
        />
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Remove from organization">
          <CircularIconButton color="primary" edge="start" onClick={removeUser}>
            <Remove fontSize="small" />
          </CircularIconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
