import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import {
  PollyClient,
  Engine,
  LanguageCode,
  OutputFormat,
  VoiceId,
  StartSpeechSynthesisTaskCommand,
  SynthesizeSpeechCommand,
  GetSpeechSynthesisTaskCommand,
} from '@aws-sdk/client-polly';
import { S3Client, ListObjectsV2Command, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

import { IArticle } from '../types/IArticle';
import { AudioStream, INarrationCredentials } from '../types/IAws';
import { AWS_REGION } from '../enums/awsRegion';

export class NarrationProvider {
  static pollyClient: PollyClient | undefined;

  static s3client: S3Client | undefined;

  static s3Bucket: string | undefined;

  static articlesWithNarrationFiles: { [key: string]: string };

  constructor() {
    const credentials: INarrationCredentials = {
      accessKey: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      s3Bucket: process.env.REACT_APP_S3_NARRATION_BUCKET,
    };

    if (credentials.accessKey && credentials.secretKey && credentials.s3Bucket) {
      const awsClientConfig = {
        region: AWS_REGION.EAST1,
        credentials:
        {
          accessKeyId: credentials.accessKey,
          secretAccessKey: credentials.secretKey,
        },
      };
      try {
        NarrationProvider.pollyClient = new PollyClient(awsClientConfig);
        NarrationProvider.s3client = new S3Client(awsClientConfig);
        NarrationProvider.s3Bucket = credentials.s3Bucket;
        NarrationProvider.listExistingNarrationFiles();
      } catch (er) {
        throw new Error('Error initializing Narration Service');
      }
    }
  }

  public getNarrationFileKey = (slug: string) => {
    const existingNarrationFileKey = NarrationProvider.articlesWithNarrationFiles[slug];
    return existingNarrationFileKey;
  };

  static getS3PresignedUrl = async (fileKey: string, client: S3Client): Promise<string> => {
    const getStreamParams = {
      Key: fileKey,
      Bucket: NarrationProvider.s3Bucket,
    };
    const getObjectCommand = new GetObjectCommand(getStreamParams);
    const response = await client.send(getObjectCommand);
    return await getSignedUrl(client, getObjectCommand, { expiresIn: 3600 });
  };

  static getSpeechSynthesisTaskStatus = async (taskId: string) => {
    if (NarrationProvider.pollyClient) {
      const input = {
        TaskId: taskId,
      };
      const command = new GetSpeechSynthesisTaskCommand(input);
      return await NarrationProvider.pollyClient.send(command);
    }
  };

  // Create polly audio narration file and save to s3
  // Returns s3 file uri
  public createNarrationFile = async (article: IArticle) => {
    if (NarrationProvider.pollyClient) {
      const narrationText = documentToPlainTextString(article.body);
      const pollyResponse = await NarrationProvider.pollyClient.send(
        new StartSpeechSynthesisTaskCommand({
          Engine: Engine.LONG_FORM,
          LanguageCode: LanguageCode.en_US,
          OutputFormat: OutputFormat.MP3,
          Text: narrationText,
          VoiceId: VoiceId.Danielle,
          OutputS3BucketName: NarrationProvider.s3Bucket,
          OutputS3KeyPrefix: article.slug,
        }),
      );
      if (pollyResponse.SynthesisTask) {
        const { OutputUri } = pollyResponse.SynthesisTask;
        return OutputUri;
      }
    }
    return Promise.resolve('');
  };

  public getUrl = async (article: IArticle) => {
    try {
      await NarrationProvider.listExistingNarrationFiles();
      const existingNarrationFileKey = NarrationProvider.articlesWithNarrationFiles[article.slug];
      if (existingNarrationFileKey && NarrationProvider.s3client) {
        // Stream file from s3
        return await NarrationProvider.getS3PresignedUrl(existingNarrationFileKey, NarrationProvider.s3client);
      }
    } catch (e) {
      console.error(e);
      throw new Error('Error fetching narration stream');
    }
  };

  static listExistingNarrationFiles = async () => {
    const command = new ListObjectsV2Command({
      Bucket: NarrationProvider.s3Bucket,
    });
    let existingArticleNarrationFiles = {};
    try {
      let isTruncated = true;
      while (NarrationProvider.s3client && NarrationProvider.s3Bucket && isTruncated) {
        const { Contents, IsTruncated, NextContinuationToken } = await NarrationProvider.s3client.send(command);
        if (Contents?.length) {
          for (const file of Contents) {
            const filenameParts = file.Key?.split('.');
            if (filenameParts?.length) {
              const slug: string = filenameParts[0];
              existingArticleNarrationFiles = { ...existingArticleNarrationFiles, [slug]: file.Key };
            }
          }
        }
        isTruncated = IsTruncated || false;
        command.input.ContinuationToken = NextContinuationToken;
      }
    } catch (err) {
      console.error(err);
    }
    NarrationProvider.articlesWithNarrationFiles = existingArticleNarrationFiles;
  };

  // Play streaming audio from polly
  // Text must be shorter than 3000 characters
  static playShortAudiostream = async (text: string): Promise<AudioStream | void> => {
    if (NarrationProvider.pollyClient) {
      const pollyResponse = await NarrationProvider.pollyClient.send(
        new SynthesizeSpeechCommand({
          Engine: Engine.LONG_FORM,
          LanguageCode: LanguageCode.en_US,
          OutputFormat: OutputFormat.MP3,
          Text: text,
          VoiceId: VoiceId.Danielle,
        }),
      );
      const audioStream = pollyResponse.AudioStream as unknown as AudioStream;
      const audioContext = new AudioContext();
      const pollyBufferSourceNode = audioContext.createBufferSource();
      const byteArray = (await audioStream.transformToByteArray());
      const { buffer } = byteArray;
      pollyBufferSourceNode.buffer = await audioContext.decodeAudioData(
        buffer,
      );
      pollyBufferSourceNode.connect(audioContext.destination);
      pollyBufferSourceNode.start();
    }
    return Promise.resolve();
  };
}

export const Narrator = new NarrationProvider();
