import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiService } from '../../common/services/api-service';
import { IUser } from '../../common/types/IUser';
import { ADMIN_CACHE_KEY } from '../enums/cacheKey';
import { AdminTracking } from '../services/adminTracking.service';
import { ITrackValue } from '../../common/types/ITrackEvent';

export const useGetOrganizationUsers = () => useQuery<IUser[]>({
  queryKey: [ADMIN_CACHE_KEY.USERS],
  queryFn: async () => (await ApiService.api.get('admin/user')).data,
});

export const useMutateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (updateValue: ITrackValue) => {
      AdminTracking.trackUserEdit(updateValue);
      const { new: updatedUser } = updateValue;
      const userId = (updatedUser as IUser).id;
      return (await ApiService.api.put(`admin/user/${userId}`, { updatedUser })).data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_CACHE_KEY.USERS],
      });
    },
  });
};
