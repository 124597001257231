import { FormControl, MenuItem, TextField } from '@mui/material';

import { USER_ROLE } from '../enums/userRole';

interface IUserRoleSelectProps {
  value: USER_ROLE;
  onChange: (newValue: USER_ROLE) => void;
}

export const UserRoleSelect = ({ value, onChange }: IUserRoleSelectProps): JSX.Element => (
  <FormControl size="small" fullWidth sx={{ minWidth: '140px' }}>
    <TextField
      fullWidth
      select
      value={value}
      margin="dense"
      onChange={(e) => onChange(e.target.value as USER_ROLE)}
      inputProps={{ style: { fontSize: '1rem', fontWeight: 400, width: '100%' } }}
    >
      <MenuItem key="USER" value={USER_ROLE.USER} sx={{ fontSize: 'small', fontWeight: 'normal' }}>User</MenuItem>
      <MenuItem key="ADMIN" value={USER_ROLE.ADMIN} sx={{ fontSize: 'small', fontWeight: 'normal' }}>Administrator</MenuItem>
    </TextField>
  </FormControl>
);
