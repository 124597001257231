import { ANALYTICS_EVENT_NAMES_ADMIN } from '../../../app-admin/enums/eventNames';
import { AMPLITUDE_AUTO_TRACKED_TYPE, ANALYTICS_EVENT_NAMES } from '../../enums/eventNames';

export const AnalyticsUtils = {
  getJoyuusEventName: (eventType: AMPLITUDE_AUTO_TRACKED_TYPE | ANALYTICS_EVENT_NAMES | ANALYTICS_EVENT_NAMES_ADMIN) => {
    const autoTrackTypes = Object.entries(AMPLITUDE_AUTO_TRACKED_TYPE);
    for (const autoTrackType of autoTrackTypes) {
      if (autoTrackType[1] === eventType) {
        return autoTrackType[0];
      }
    }
    return eventType;
  },

  isEmployee: (email: string): boolean => !!email?.includes('@joyuus.com'),

  // Remove personal identifyable information from eventData
  cleanEventPiiData: (eventData: any) => {
    const cleanData = { ...eventData };
    if (cleanData.user?.userId) {
      cleanData.userId = eventData.user?.userId;
    }
    delete cleanData.user;
    return cleanData;
  },

};
