import { articleCategories, resource, redFlag } from '../utils/categories';
import { CategoryColorBlock } from '../atoms/CategoryColorBlock';

interface ICategoryHeaderProps {
  title: string;
}

interface ICategoryButton {
  label: string;
  color: string;
  backgroundColor: string;
  slug: string;
  svg: JSX.Element;
}

export const CategoryHeader = ({ title }: ICategoryHeaderProps): JSX.Element => {
  const categoryInfo = articleCategories.filter((category: ICategoryButton) => category.slug === title)[0];

  return (
    <CategoryColorBlock
      label={categoryInfo?.label}
      color={categoryInfo.color}
      backgroundColor={categoryInfo.backgroundColor}
      textSize="h1"
      svg={categoryInfo.svg}
      height={62}
    />
  );
};

export const ResourceHeader = (): JSX.Element => (
  <CategoryColorBlock
    label={resource?.label}
    description={resource?.description}
    color={resource.color}
    backgroundColor={resource.backgroundColor}
    textSize="h1"
    svg={resource.svg}
    height={62}
  />
);

export const RedFlagHeader = (): JSX.Element => (
  <CategoryColorBlock
    label={redFlag?.label}
    description={redFlag?.description}
    color={redFlag.color}
    backgroundColor={redFlag.backgroundColor}
    textSize="h1"
    svg={redFlag.svg}
    height={62}
  />
);
