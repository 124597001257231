import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { CACHE_KEY } from '../enums/cacheKey';
import { IArticlePreview } from '../types/IArticle';
import { ApiService } from '../../common/services/api-service';

export const useGetDiscover = (): UseQueryResult<IArticlePreview[], unknown> => useQuery({
  queryKey: [CACHE_KEY.DISCOVER_ARTICLES],
  queryFn: async () => (await ApiService.api.get('/discover', { params: { thumbnails: true } })).data,
  refetchOnWindowFocus: false,
  staleTime: 1000,
});
