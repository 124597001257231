import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { ArticleCard } from '../components/ArticleCard';
import { useGetArticleList, useGetCategoryResources } from '../hooks/useArticles';
import { useGetCategory } from '../hooks/useCategories';
import { IArticlePreview } from '../types/IArticle';
import { CategoryHeader } from '../components/CategoryHeader';
import { Grid } from '../../common/atoms/Grid';
import { theme } from '../../common/theme/theme';
import { SubcategoryFilter } from '../components/SubcategoryFilter';
import { ANALYTICS_EVENT_NAMES } from '../../common/enums/eventNames';
import { ResourceBlock } from '../components/ResourceBlock';
import { stringToSlug } from '../utils/unit-functions';
import { FeatureCardSkeleton } from '../components/Card/FeatureCard';
import { ROUTE } from '../enums/route';
import { Amplitude } from '../../common/services/analytics/amplitude.service';
import { IEventProperties, ITrackEventInput } from '../../common/types/ITrackEvent';

const ArticleSkeletonList = ({ count }: { count: number }): JSX.Element => (
  <>
    {Array.from({ length: count }).map((value: unknown, index: number) => (
      // eslint-disable-next-line react/no-array-index-key
      <Grid.Cell key={index} $bleed xs={12}>
        <FeatureCardSkeleton showDescription />
      </Grid.Cell>
    ))}
  </>
);

export const Category = () => {
  const params = useParams<{ category: string }>();
  const { category } = params;
  const { data: articleList, isLoading: featuresLoading } = useGetArticleList(category);
  const { data: categoryInfo } = useGetCategory(category);
  const { data: categoryResources, isLoading: resourcesLoading } = useGetCategoryResources(category);
  const [displayArticles, setDisplayArticles] = useState<IArticlePreview[]>([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string[]>([]);

  useEffect(() => {
    // Filter articles on selectedSubcatIds update
    if (articleList?.length) {
      if (selectedSubcategory.length > 0) {
        const filteredArticles = articleList.filter((preview) => selectedSubcategory.includes(preview?.subcategory));
        setDisplayArticles(filteredArticles);
      } else {
        setDisplayArticles(articleList);
      }
    }
  }, [articleList, selectedSubcategory]);

  // Called by child components when filter is added
  const addFilter = useCallback((name: string) => {
    const eventProperties: IEventProperties = {
      category,
      target: 'subcategory-filter',
      value: {
        previous: '',
        new: stringToSlug(name),
      },
    };
    const eventInput: ITrackEventInput = {
      eventProperties,
      eventInput: ANALYTICS_EVENT_NAMES.CLICK,
    };
    Amplitude.track(eventInput);

    setSelectedSubcategory([name]);
  }, [category]);

  // Called by child components when filter is removed
  const removeFilter = () => {
    setSelectedSubcategory([]);
  };

  return (
    <div className="content">
      {category && <CategoryHeader title={category} /> }

      <SubcategoryFilter category={categoryInfo} addFilter={addFilter} removeFilter={removeFilter} />

      <Grid rowgap={theme.space(4)} colgap={theme.space(4)} sx={{ py: 4 }} justifyItems="space-between">

        <Grid.Cell $bleed xs={12} md={8}>

          <Grid rowgap={theme.space(4)} colgap={theme.space(4)} justifyItems="space-between">
            { !featuresLoading && displayArticles.length ? displayArticles.map((preview: IArticlePreview) => (
              <Grid.Cell key={preview.slug} $bleed xs={12}>
                <ArticleCard
                  article={preview}
                  showCategory
                  showDescription
                  slug={`${ROUTE.ARTICLE}/${preview.slug}`}
                />
              </Grid.Cell>
            )) : <ArticleSkeletonList count={4} />}
          </Grid>
        </Grid.Cell>
        {selectedSubcategory.length === 0 && (
          <Grid.Cell $bleed xs={12} md={4}>
            <ResourceBlock loading={resourcesLoading} resources={categoryResources} headline="Resources" />
          </Grid.Cell>
        )}
      </Grid>
    </div>
  );
};
