export const limeade = {
  50: '#FCFDE5',
  100: '#F9FACC',
  200: '#F6F8B2',
  300: '#F3F699',
  400: '#F0F380',
  500: '#EDF166',
  600: '#EAEF4D',
  700: '#E7ED33',
  800: '#E4EA1A',
  900: '#E4EA1A',
};
