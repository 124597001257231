import {
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { theme } from '../../../common/theme/theme';

import { IArticlePreview } from '../../types/IArticle';
import { ANALYTICS_EVENT_NAMES } from '../../../common/enums/eventNames';
import { IEventProperties, ITrackEventInput } from '../../../common/types/ITrackEvent';
import { CategoryTags } from '../../atoms/CategoryTags';
import { CardFooter } from './CardFooter';
import { stringToSlug } from '../../utils/unit-functions';
import { Amplitude } from '../../../common/services/analytics/amplitude.service';

interface IArticlePreviewProps {
  article: IArticlePreview,
  slug: string,
  showDescription?: boolean;
  showCategory?: boolean;
}

// Need to clean this up to only have one click event per element
export const FeatureCard = ({
  article, slug, showCategory, showDescription = false, ...props
}: IArticlePreviewProps): JSX.Element => {
  const eventProperties: IEventProperties = {
    article: article.slug,
    category: stringToSlug(article.category),
    subcategory: stringToSlug(article.subcategory),
    type: stringToSlug(article.type),
    target: 'article-card',
  };
  const eventInput: ITrackEventInput = {
    eventProperties,
    eventInput: ANALYTICS_EVENT_NAMES.CLICK,
  };
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CardContainer
      {...props}
      data-test="FeatureCard"
    >
      <RouterLink to={slug} onClick={() => Amplitude.track(eventInput)} style={{ textDecoration: 'none' }}>
        <ArticleImg
          background={article?.thumbnail?.file?.url || article?.illustration?.file?.url}
        />
      </RouterLink>
      <div>
        <CategoryTags article={article} showSubCategory={showCategory} />

        <RouterLink to={slug} onClick={() => Amplitude.track(eventInput)} style={{ textDecoration: 'none' }}>
          <Typography variant="h2" style={{ padding: '8px 0px', fontSize: 15 }}>
            {article.title}
          </Typography>
          { !isTablet && showDescription && (
          <Typography style={{ fontSize: 14, paddingBottom: 8 }}>
            {article.descriptor}
          </Typography>
          )}
        </RouterLink>
        <CardFooter article={article} />
      </div>

    </CardContainer>
  );
};

export const FeatureCardSkeleton = ({ showDescription = false }: { showDescription?: boolean }): JSX.Element => {
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CardContainer>
      <Skeleton variant="rectangular" width={120} height={96} style={{ borderRadius: 16, marginRight: 16, minWidth: '120px' }} />
      <div style={{ width: '100%' }}>
        <Skeleton width="15%" variant="text" height={20} />
        <Skeleton width="70%" variant="text" height={28} />
        {
          isPhone && <Skeleton width="60%" variant="text" height={28} />
        }
        { !isTablet && showDescription
          && <Skeleton width="100%" height={20} variant="text" />
        }
        <Skeleton width="15%" variant="text" height={20} />
      </div>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  ${({ onClick }) => onClick
    && css`
      cursor: pointer;
    `}
`;

const ArticleImg = styled.div<{ background: string }>`
  width: 120px;
  height: 96px;
  border-radius: 16px;
  background: ${(props) => `url(${props.background}) lightgray 50% / cover no-repeat`};
  margin-right: 16px;
`;
