import { Box, Container } from '@mui/system';
import { Snackbar } from '@mui/material';
import { useState } from 'react';

import { useGetOrganization } from '../hooks/useOrganizations';
import { useGetOrganizationUsers } from '../hooks/useUsers';
import { useGetInvitations } from '../hooks/useInvitations';
import { Organization } from '../../common/components/Organization';

export const OrganizationPage = () => {
  const { data: organization } = useGetOrganization();
  const { data: users } = useGetOrganizationUsers();
  const { data: invitations } = useGetInvitations();
  const [message, setMessage] = useState<string>('');

  const handleClose = () => {
    setMessage('');
  };

  return (
    <Container>
      <Organization organization={organization} users={users || []} invitations={invitations || []} setMessage={setMessage} />
      <Box sx={{ width: 500 }}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!!message?.length}
          autoHideDuration={3000}
          onClose={handleClose}
          message={message}
        />
      </Box>
    </Container>
  );
};
