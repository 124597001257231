import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';

import { AppHeader } from './components/AppHeader';
import { Footer } from './components/Footer';
import { TrackWrapper } from '../common/wrappers/TrackWrapper';
import { useGetUser } from '../common/hooks/useGetUser';
import { useGetOrganization } from './hooks/useOrganizations';

export const AdminApp = () => {
  const { data: org } = useGetOrganization();
  const [orgName, setOrgName] = useState<string>();
  const { data: joyuusUser } = useGetUser({ isAuthenticated: true });

  useEffect(() => {
    if (org) {
      setOrgName(org.name);
    }
  }, [org]);

  return (
    <TrackWrapper user={joyuusUser!} amplitudeKey={process.env.REACT_APP_AMPLITUDE_API_KEY}>
      <Container
        maxWidth="lg"
        style={{ marginTop: 88 }}
      >
        <AppHeader orgName={orgName} />
        <Container
          maxWidth="lg"
          style={{ minHeight: '80vh' }}
        >
          <Outlet />
        </Container>
        <Footer />
        <ScrollRestoration />
      </Container>
    </TrackWrapper>
  );
};
