import { Chip, IconButton } from '@mui/material';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { useURLQuery } from '../utils/unit-functions';
import { CategoryButtonParams, categories } from '../utils/categories';

interface IProps {
  addFilter: (name: string) => void;
  removeFilter: (name: string) => void;
  baseRoute: string;
}

export const CategoryFilter = ({ addFilter, removeFilter, baseRoute }: IProps) => {
  const query = useURLQuery();
  const navigate = useNavigate();
  const categoryQuery = query.get('category');
  const [selectedCategory, setSelectedCategory] = useState<any>(categoryQuery || '');

  useEffect(() => {
    if (categoryQuery) {
      setSelectedCategory(categoryQuery);
      addFilter(categoryQuery);
    }
  }, [categoryQuery, addFilter]);

  const handleSelect = (label: string) => {
    const newSelectedCategory = label;
    addFilter(newSelectedCategory);
    setSelectedCategory(newSelectedCategory);
    navigate(`${baseRoute}?category=${newSelectedCategory}`);
  };

  const handleClear = () => {
    removeFilter(selectedCategory);
    setSelectedCategory('');
    navigate(baseRoute);
  };

  return (
    <Container>
      { selectedCategory ? (
        <>
          <IconButton onClick={handleClear}>
            <Close />
          </IconButton>
          <Chip
            label={selectedCategory}
            color="success"
            size="medium"
          />
        </>
      )
        : (
          <>
            <Chip label="All" color="success" size="medium" />
            {categories.map((option: CategoryButtonParams) => (
              <Chip
                key={option.slug}
                label={option.label}
                onClick={() => handleSelect(option.label)}
                size="medium"
              />
            ))}
          </>
        )}
    </Container>
  );
};

const Container = styled.div`
  padding: 16px 0;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: scroll;
  gap: 8px;
`;
