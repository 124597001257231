export const blossom = {
  50: '#FFF8FF',
  100: '#FFF1FE',
  200: '#FFEBFE',
  300: '#FFE4FD',
  400: '#FFDDFD',
  500: '#FFD6FD',
  600: '#FFCFFC',
  700: '#FFC9FC',
  800: '#FFC2FB',
  900: '#FFBBFB',
};
