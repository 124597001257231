import { Chip } from '@mui/material';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { orchid } from '../../common/styles/colors/orchid';
import { violet } from '../../common/styles/colors/violet';
import { emerald } from '../../common/styles/colors/emerald';
import { IArticlePreview } from '../types/IArticle';
import { stringToSlug } from '../utils/unit-functions';
import { QUERYNAME } from '../enums/queryname';
import { ROUTE } from '../enums/route';

interface IProps {
  article: IArticlePreview;
  showSubCategory?: boolean;
}

export const CategoryTags = ({ article, showSubCategory = true }: IProps) => {
  const getStyle = () => {
    switch (article.category) {
      case 'Mental Health': return { backgroundColor: orchid[100], color: orchid[900] };
      case 'Social Life': return { backgroundColor: violet[50], color: violet[900] };
      case 'Physical Health': return { backgroundColor: emerald[50], color: emerald[900] };
      case 'Real Life': return { backgroundColor: '#F6F8FE', color: '#2053D8' };
    }
  };

  const getLinkUrl = () => {
    if (article.category === 'Red Flags') {
      return `${ROUTE.RED_FLAG}`;
    }
    return `${ROUTE.CATEGORY}/${stringToSlug(article.category)}`;
  };

  return (
    <div>
      <RouterLink to={getLinkUrl()}>
        <StyledChip
          size="small"
          label={article.category}
          color="primary"
          style={getStyle()}
        />
      </RouterLink>
      {showSubCategory && article.subcategory && (
        <RouterLink to={`${ROUTE.CATEGORY}/${stringToSlug(article.category)}?${QUERYNAME.SUBCATEGORY}=${article.subcategory}`}>
          <StyledChip
            size="small"
            label={article.subcategory}
            color="primary"
            style={getStyle()}
          />
        </RouterLink>
      )}
    </div>
  );
};

// Used in red flag card: styling the red flag chip
export const StyledChip = styled(Chip)`
  margin-right: 4px;
`;
