import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { CACHE_KEY } from '../enums/cacheKeys';
import { IUser } from '../types/IUser';
import { ApiService } from '../services/api-service';

export const useGetUser = ({ isAuthenticated }: { isAuthenticated?: boolean | undefined }): UseQueryResult<IUser, unknown> => useQuery({
  queryKey: [CACHE_KEY.USER],
  queryFn: async () => (await ApiService.api.get('/user')).data,
  enabled: !!isAuthenticated,
  refetchOnWindowFocus: false,
  staleTime: 10000,
});
