import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

import { IInvitation } from '../types/IInvitation';

interface IInvitationsTableProps {
  invitations: IInvitation[];
}

export default function InvitationsTable({ invitations }: IInvitationsTableProps) {
  return (
    <TableContainer component={Paper} sx={{ my: 2 }}>
      <Table sx={{ width: 1, minWidth: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="center">Role</TableCell>
            <TableCell align="center">Invited</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Accepted</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invitations.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Typography variant="body1">{row.email}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1">{row.role.name.toLowerCase()}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1">{new Intl.DateTimeFormat('en-US').format(new Date(row.createdAt))}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1">{row.accepted ? 'Accepted' : 'Open'}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body1">{row.accepted ? new Intl.DateTimeFormat('en-US').format(new Date(row.accepted)) : null}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
