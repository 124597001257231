import { useLayoutEffect } from 'react';
import { Container, ContainerProps } from '@mui/material';
import { MAINCONTENT_ID } from '../../utils/constants';

export interface IBreadCrumbConfig {
  label: string;
  route?: string;
}

interface IMainProps extends ContainerProps {
  title?: string;
  hiddenTitle?: boolean;
  appHeaderIsHidden?: boolean;
  maxWidth?: ContainerProps['maxWidth'];
  breadcrumbs?: IBreadCrumbConfig[];
}

/**
 * Most common layout component to use.
 * @param title - Automatically adds a <Helmet> title tag.
 * @param hiddenTitle - adds vissually hidden h1 tag (for accessibility).
 * @param appHeaderIsHidden - hides the app header.
 * @param maxWidth - Sets the max width of the main content using the MUI Container values (xs, sm, md, lg, xl)
 * @returns Wraps the children in a <main> component and includes the app header.
 */
export const MainLayout = ({
  title,
  hiddenTitle,
  appHeaderIsHidden,
  breadcrumbs,
  children,
  ...props
}: IMainProps): JSX.Element => {
  useLayoutEffect(() => {
    // Scroll to top on page change (gets ignored for tabs, sub-routes, modals, etc. because they are all triggered from this main layout)
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container
      component="main"
      id={MAINCONTENT_ID}
      maxWidth={props.maxWidth || 'lg'}
      style={{ minHeight: '100vh', marginTop: 88 }}
    >
      {children}
    </Container>
  );
};
