export enum ANALYTICS_EVENT_NAMES {
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  ACCOUNT_DELETED = 'ACCOUNT_DELETED',
  SESSION_STARTED = 'SESSION_STARTED',
  SESSION_ENDED = 'SESSION_ENDED',
  PAGE_LOAD = 'PAGE_LOAD',
  CLICK = 'CLICK',
  SEARCH = 'SEARCH',
  BOOKMARK = 'BOOKMARK',
  BODY_INTERNAL_LINK_CLICK = 'BODY_INTERNAL_LINK_CLICK',
  BODY_EXTERNAL_LINK_CLICK = 'BODY_EXTERNAL_LINK_CLICK',
  NARRATION_OPEN = 'NARRATION_OPEN',
  NARRATION_EXISTING_FILE = 'NARRATION_EXISTING_FILE',
  NARRATION_CREATE_FILE = 'NARRATION_CREATE_FILE',
  NARRATION_PLAY = 'NARRATION_PLAY',
  NARRATION_END = 'NARRATION_END',
}

export enum AMPLITUDE_AUTO_TRACKED_TYPE {
  SESSION_STARTED = 'session_start',
  SESSION_ENDED = 'session_end',
  PAGE_LOAD = '[Amplitude] Page Viewed',
}
