import {
  Typography, InputAdornment, TextField, Box,
  CircularProgress,
} from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Grid } from '../../common/atoms/Grid';
import { ArticleCard } from '../components/ArticleCard';
import { theme } from '../../common/theme/theme';
import { IArticlePreview } from '../types/IArticle';
import { useMutateSearch } from '../hooks/useArticles';
import { SEARCH_TXT_PLACEHOLDER } from '../utils/app-language';
import { stringToSlug, useURLQuery } from '../utils/unit-functions';
import { ROUTE } from '../enums/route';

const EmptySearchResults = ({ searchTerm }: { searchTerm?: string }) => (
  <Grid.Cell xs={12}>
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      sx={{ py: 13, mt: 2 }}
      style={{
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(1.5),
        width: '100%',
      }}
    >
      <Typography variant="h2" sx={{ mb: 1 }}>
        {searchTerm ? `No Search results found for "${searchTerm}"` : 'No Search results found'}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        Please make sure that your search term is spelled correctly, or use fewer or different keywords.
      </Typography>
    </Box>
  </Grid.Cell>
);

export const Search = () => {
  const query = useURLQuery();
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState<IArticlePreview[]>([]);
  const searchQuery = query.get('search');
  const [searchText, setSearchText] = useState<string>(searchQuery || '');
  const [previousSearch, setPreviousSearch] = useState<string>('');
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [nullResult, setNullResult] = useState<boolean>(false);
  const { mutate: search } = useMutateSearch();

  const getSearchDisplay = () => {
    if (searchLoading) {
      return <CircularProgress size={14} />;
    } if (searchResult.length) {
      return (
        <>
          <Grid.Cell xs={12}>
            <Typography variant="h3">{`${searchResult?.length} Search Results`}</Typography>
          </Grid.Cell>

          {searchResult.map((preview: IArticlePreview) => (
            <Grid.Cell key={preview?.slug} $bleed sm={12} md={6}>
              <ArticleCard key={preview?.id} article={preview} slug={`${ROUTE.ARTICLE}/${preview?.slug}`} showCategory={false} />
            </Grid.Cell>
          ))}
        </>
      );
    } if (nullResult) {
      return (
        <EmptySearchResults searchTerm={searchQuery || undefined} />
      );
    }
    // Search not yet executed, or search field recently cleared
    return null;
  };

  const executeSearch = useCallback(async () => {
    const value = {
      previous: previousSearch,
      new: '',
    };
    if (searchText) {
      setNullResult(false);
      setSearchLoading(true);
      const newValue = stringToSlug(searchText);
      value.new = newValue;
      search(value, {
        onSuccess: (data) => {
          const { data: searchResponse } = data;
          if (searchResponse.length) {
            setSearchResult(searchResponse as unknown as IArticlePreview[]);
          } else {
            setNullResult(true);
          }
          setSearchLoading(false);
          setPreviousSearch(newValue);
        },
      });
    }
    navigate(`${ROUTE.SEARCH}?search=${searchText}`);
    setSearchResult([]);
  }, [search, navigate, searchText]);

  useEffect(() => {
    if (searchQuery) {
      executeSearch();
    }
  }, [searchQuery, searchText, executeSearch]);

  const clearSearch = async () => {
    const value = {
      previous: stringToSlug(searchText),
      new: '',
    };
    setSearchText('');
    setSearchResult([]);
    setSearchLoading(false);
    setNullResult(false);
    navigate(`${ROUTE.SEARCH}`);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      executeSearch();
    }
  };

  return (
    <div className="content">
      <Grid rowgap={theme.space(4)} colgap={theme.space(4)} sx={{ py: 4 }}>
        <Grid.Cell xs={0} sm={3} md={4} />

        <Grid.Cell xs={12} sm={6} md={4} padding={0}>
          <StyledTextField
            value={searchText}
            variant="filled"
            placeholder={SEARCH_TXT_PLACEHOLDER}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => handleKeyPress(e)}
            InputProps={{
              endAdornment: searchText && (
                <InputAdornment position="end">
                  <CloseIcon onClick={clearSearch} style={{ cursor: 'pointer' }} />
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            autoFocus
          />
        </Grid.Cell>
        <Grid.Cell xs={0} sm={3} md={4} />
        { getSearchDisplay() }
      </Grid>
    </div>
  );
};

const StyledTextField = styled(TextField)`
  width: 100%;
`;
