import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Container,
  useScrollTrigger,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import {
  Link as RouterLink,
  useLocation,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { cloneElement, useState } from 'react';
import { ArrowBackRounded } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

import { Search } from '../atoms/icons/Search';
import { Menu } from '../atoms/icons/Menu';
import { SPACE } from '../../common/styles/constants';
import { theme as appTheme } from '../../common/theme/theme';
import { BypassLink } from '../../common/atoms/BypassLink';
import { PrimaryJoyuusLogo } from '../atoms/logos/PrimaryJoyuusLogo';
import { ToggleBookmark } from '../atoms/ToggleBookmark';
import { useGetArticle } from '../hooks/useArticles';
import { ROUTE } from '../enums/route';
import { IAuthUser } from '../../common/types/IAuthUser';
import { AvatarButton } from '../atoms/AvatarButton';
import { palette } from '../../common/styles/palette';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const drawerWidth = 320;

const ElevationScroll = (props: Props) => {
  const { children, window } = props;
  useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    style: {
      background: 'rgba(255, 255, 255, 0.65)',
    },
    elevation: 0,
  });
};

const MainNavContent = ({ handleDrawerToggle }: { handleDrawerToggle: () => void }) => {
  const { user } = useAuth0<IAuthUser>();
  const location = useLocation();

  const handleSearch = () => {
    if (location.pathname === ROUTE.ROOT || location.pathname.includes(ROUTE.SEARCH)) {
      return null;
    }
    return (
      <IconButton component={RouterLink} to={ROUTE.ROOT} sx={{ borderRadius: 24, backgroundColor: palette.secondary.dark, '&:hover': { backgroundColor: '#E0E0E0' } }}>
        <Search />
      </IconButton>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ width: '100%' }}
    >
      <IconButton
        color="primary"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
      >
        <Menu />
      </IconButton>
      <Button
        component={RouterLink}
        to={ROUTE.ROOT}
        variant="text"
        aria-label="Home"
        aria-describedby="joyuus-logo"
        sx={{
          p: 0,
          ':hover, :focus': { backgroundColor: 'transparent' },
          height: { xs: SPACE * 2.5, md: SPACE * 3 },
        }}
      >
        <PrimaryJoyuusLogo
          id="joyuus-logo"
          aria-label="Joyuus Logo"
          height="100%"
        />
      </Button>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={2}
        color={appTheme.palette.text.secondary}
        style={{ width: 80 }}
      >
        {handleSearch()}
        <AvatarButton user={user} />
      </Box>
    </Box>
  );
};

const ArticleNav = () => {
  const params = useParams<{ article: string }>();
  const navigate = useNavigate();
  const { article: slug } = params;

  const { data: articleData } = useGetArticle(slug);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems={{ xs: 'center' }}
      style={{ width: '100%' }}
    >
      <IconButton color="primary" edge="start" onClick={() => navigate(-1)}>
        <ArrowBackRounded fontSize="large" />
      </IconButton>
      <Button
        component={RouterLink}
        to={ROUTE.ROOT}
        variant="text"
        aria-label="Home"
        aria-describedby="joyuus-logo"
        sx={{
          p: 0,
          ':hover, :focus': { backgroundColor: 'transparent' },
          height: { xs: SPACE * 2.5, md: SPACE * 3 },
        }}
      >
        <PrimaryJoyuusLogo
          id="joyuus-logo"
          aria-label="Joyuus Logo"
          height="100%"
        />
      </Button>
      <IconButton edge="end" color="secondary">
        <ToggleBookmark article={articleData!} />
      </IconButton>
    </Box>
  );
};

const SideNavContent = ({ logout }: { logout: () => void }) => (
  <Container sx={{ height: '100%' }}>
    <Toolbar />
    <List>
      <ListItem disablePadding>
        <ListItemButton component={RouterLink} to={ROUTE.ROOT}>
          <ListItemText primary="Home" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component={RouterLink} to={ROUTE.RED_FLAG}>
          <ListItemText primary="Red Flags" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component={RouterLink} to={ROUTE.RESOURCE}>
          <ListItemText primary="Resources" />
        </ListItemButton>
      </ListItem>
    </List>
    <List sx={{ position: 'absolute', bottom: 0 }}>
      <ListItem disablePadding>
        <Button onClick={logout}>Logout</Button>
      </ListItem>
    </List>
  </Container>
);

export const AppHeader = ({ ...props }): JSX.Element => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();

  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      {/* Nav */}
      <ElevationScroll {...props}>
        <AppBar
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, ...props.sx }}
          {...props}
        >
          <BypassLink />
          <Container maxWidth={props.maxWidth || 'lg'}>
            <Toolbar disableGutters>
              {
                location.pathname.includes(ROUTE.ARTICLE) ? <ArticleNav />
                  : <MainNavContent handleDrawerToggle={handleDrawerToggle} />
              }
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      {/* Sidebar */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClick={handleDrawerToggle}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        elevation={3}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
      >
        <SideNavContent logout={handleLogout} />
      </Drawer>
    </>
  );
};
