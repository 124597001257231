import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CACHE_KEY } from '../enums/cacheKey';

import { ApiService } from '../../common/services/api-service';
import { UserTracking } from '../services/userTracking.service';

export const useDeleteAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (): Promise<void> => {
      UserTracking.trackAccountDeletion();
      return ApiService.api.delete('/account');
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEY.USER_BOOKMARKS],
      });
    },
  });
};
