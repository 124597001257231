import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
} from 'react-router-dom';

import { Home } from '../pages/Home';
import { Category } from '../pages/Category';
import { Article } from '../pages/Article';
import { Profile } from '../pages/Profile';
import { RedFlags } from '../pages/RedFlags';
import { Resources } from '../pages/Resources';
import { ResourceCategory } from '../pages/ResourceCategory';
import { Search } from '../pages/Search';
import { ROUTE } from '../enums/route';
import { UserApp } from '../UserApp';
import { AuthGuard } from '../../common/components/AuthGuard';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTE.ROOT} element={<UserApp />}>
      <Route
        path={ROUTE.ROOT}
        element={<AuthGuard component={Home} />}
      />

      <Route
        path={ROUTE.CATEGORY}
        element={<AuthGuard component={Home} />}
      />

      <Route
        path={ROUTE.PROFILE}
        element={<AuthGuard component={Profile} />}
      />

      <Route
        path={ROUTE.RESOURCE}
        element={<AuthGuard component={Resources} />}
      />

      <Route
        path={ROUTE.RED_FLAG}
        element={<AuthGuard component={RedFlags} />}
      />

      <Route
        path={ROUTE.SEARCH}
        element={<AuthGuard component={Search} />}
      />

      <Route
        path={`${ROUTE.CATEGORY}/:category`}
        element={<AuthGuard component={Category} />}
      />

      <Route
        path={`${ROUTE.RESOURCE}/:category`}
        element={<AuthGuard component={ResourceCategory} />}
      />

      <Route
        path={`${ROUTE.ARTICLE}/:article`}
        element={<AuthGuard component={Article} />}
      />

    </Route>,
  ),
);
