import { useEffect, useState } from 'react';
import { Add, Inventory, Remove } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

import { useArchiveOrganization, useGetAllOrganizations } from '../hooks/useOrganizations';
import { WidthConstrainedContent } from '../../common/atoms/WidthConstrainedContent';
import { ROUTE_INTERNAL } from '../enums/route';
import { CircularIconButton } from '../../common/atoms/CircularIconButton';
import { OrganizationForm } from '../../common/components/OrganizationForm';

export const OrganizationList = () => {
  const navigate = useNavigate();
  const { data: organizationList } = useGetAllOrganizations();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [updatingId, setUpdatingId] = useState<number | null>(null);
  const { mutate: archiveOrganization, isSuccess } = useArchiveOrganization();

  useEffect(() => {
    if (isSuccess && updatingId) {
      console.log('completed');
      setUpdatingId(null);
    }
  }, [isSuccess]);

  const hideForm = () => {
    setShowForm(false);
  };

  const toOrgDetailPage = (id: number) => {
    navigate(`${ROUTE_INTERNAL.ORGANIZATION}/${id}`);
  };

  const onArchive = (id: number) => {
    setUpdatingId(id);
    archiveOrganization(id);
  };

  return (
    <WidthConstrainedContent>
      <h4>
        Organizations
        <Tooltip title="Add organization" sx={{ ml: 2 }}>
          <CircularIconButton color="primary" edge="start" onClick={() => setShowForm(!showForm)}>
            {showForm ? <Remove fontSize="small" /> : <Add fontSize="small" />}
          </CircularIconButton>
        </Tooltip>
      </h4>

      {/* Add Organization Form */}

      { showForm && (
        <OrganizationForm onComplete={hideForm} />
      )}

      { organizationList && organizationList.length > 0 && (
        <TableContainer component={Paper} sx={{ my: 2 }}>
          <Table sx={{ width: 1, minWidth: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Domain</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organizationList.map((organization) => (
                <TableRow
                  key={organization.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => toOrgDetailPage(organization.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography variant="body1">{organization.name}</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() => toOrgDetailPage(organization.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography variant="body1">{organization.domain}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title="Archive organization">
                      <LoadingButton color="primary" size="medium" sx={{ height: '32px', width: '32px', borderRadius: 20 }} loading={updatingId === organization.id} onClick={() => onArchive(organization.id)}>
                        <Inventory fontSize="small" />
                      </LoadingButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </WidthConstrainedContent>
  );
};
