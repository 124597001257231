import { SPACE } from './constants';

/**
 * mimics MUI's theme.spacing function for use in native mobile
 */
export const spacing = (s: number) => `${SPACE * s}px`;

/**
 * Similar to MUI's theme.spacing function, but returns a unitless number
 */
export const space = (s: number) => SPACE * s;

/**
 * Takes a font size and a numeric value, and returns the unitless line height, which helps the readability of the css on native.
 * @param {number} fontSize - The font size of the text.
 * @param {number} value - The line height.
 * @example
 * `line-height: ${calcLineHeight(24, 1.2)}px; // results in 28.8px`
 * (which is similar to `font-size: 24px; line-height: 1.2;` on web.)
 */
export const calcLineHeight = (fontSize: number, value: number) => fontSize * value;

export const calcPxToRem = (px: number) => `${px / 16}rem`;
