import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Footer } from './components/Footer';
import { AppHeader } from './components/AppHeader';
import { MainLayout } from './atoms/layout/MainLayout';
import { TrackWrapper } from '../common/wrappers/TrackWrapper';
import { useGetUser } from '../common/hooks/useGetUser';

export const UserApp = () => {
  const { data: joyuusUser } = useGetUser({ isAuthenticated: true });

  return (
    <TrackWrapper user={joyuusUser!} amplitudeKey={process.env.REACT_APP_AMPLITUDE_API_KEY}>
      <AppHeader />
      <MainLayout>
        <Outlet />
      </MainLayout>
      <Footer />
      <ScrollRestoration />
    </TrackWrapper>
  );
};
