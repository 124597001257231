/* eslint-disable no-restricted-imports */
import {
  Clear as ClearIcon,
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import { createTheme, ThemeOptions } from '@mui/material';

import { FONT_WEIGHT, typography } from '../styles/typography';
import { ICON_SIZES } from '../styles/constants';
import { theme } from './theme';
import { emerald } from '../styles/colors/emerald';

const inputs: ThemeOptions = {
  components: {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <ExpandMoreIcon />,
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall, & .MuiFilledInput-root.MuiInputBase-sizeSmall': {
            padding: theme.spacing(1, 1.5),
            '& .MuiAutocomplete-input': {
              padding: 0,
            },
          },
        },
        hasPopupIcon: {
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall, & .MuiFilledInput-root.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd':
          {
            paddingRight: `calc(${theme.spacing(2)} + ${ICON_SIZES.small}px)`, // icon size + one unit on each side
          },
        },
        endAdornment: {
          right: theme.spacing(1.5),
          top: 'auto',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            outlineColor: theme.palette.a11yFocusRing,
            outlineWidth: 2,
            outlineStyle: 'auto',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          ...typography.body2.web,
          fontWeight: theme.typography.fontWeightBold,
          textTransform: 'none',
          borderRadius: theme.shape.borderRadius * 1.5,
          minWidth: 'auto',
        },
        sizeSmall: {
          lineHeight: theme.spacing(2.5),
          padding: theme.spacing(0.5, 1.25),
        },
        sizeMedium: {
          lineHeight: theme.spacing(2.5),
          padding: theme.spacing(1, 2),
        },
        sizeLarge: {
          // lineHeight: theme.spacing(3),
          ...typography.body1.web,
          fontWeight: theme.typography.fontWeightBold,
          padding: theme.spacing(1.5, 3),
        },
        startIcon: {
          '& > .MuiSvgIcon-fontSizeSmall:nth-of-type(1)': {
            fontSize: theme.typography.pxToRem(ICON_SIZES.small),
          },
          '& > .MuiSvgIcon-fontSizeMedium:nth-of-type(1)': {
            fontSize: theme.typography.pxToRem(ICON_SIZES.medium),
          },
          '& > .MuiSvgIcon-fontSizeLarge:nth-of-type(1)': {
            fontSize: theme.typography.pxToRem(ICON_SIZES.large),
          },
        },
        endIcon: {
          '& > .MuiSvgIcon-fontSizeSmall:nth-of-type(1)': {
            fontSize: theme.typography.pxToRem(ICON_SIZES.small),
          },
          '& > .MuiSvgIcon-fontSizeMedium:nth-of-type(1)': {
            fontSize: theme.typography.pxToRem(ICON_SIZES.medium),
          },
          '& > .MuiSvgIcon-fontSizeLarge:nth-of-type(1)': {
            fontSize: theme.typography.pxToRem(ICON_SIZES.large),
          },
        },
        outlined: {
          whiteSpace: 'nowrap',
          '&, &:hover, &:active': {
            border: 'none',
            boxShadow: `0px 1px 3px rgba(0, 0, 0, 0.07), inset 0px 0px 0px 1px ${theme.palette.border}`,
          },
          '&.Mui-disabled': {
            opacity: 0.4,
          },
          '&.danger-on-hover:hover': {
            color: theme.palette.error.main,
          },
        },
        contained: {
          whiteSpace: 'nowrap',
          '&.danger-on-hover:hover': {
            backgroundColor: theme.palette.error.main,
          },
          '&.Mui-focusVisible': {
            outlineOffset: 2,
          },
        },
        containedPrimary: {
          color: theme.palette.common.white,
          '&, &:hover, &:active': {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.07)',
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.inverse,
            opacity: 0.4,
          },
          '&.Mui-disabled:has(.MuiCircularProgress-root)': {
            opacity: 1,
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.text.primary,
            opacity: 0.4,
          },
          '&.Mui-disabled:has(.MuiCircularProgress-root)': {
            opacity: 1,
          },
        },
        containedError: {
          '&, &:hover, &:active': {
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.07)',
          },
          '&:hover': {
            backgroundColor: theme.palette.error.dark,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.text.inverse,
            opacity: 0.4,
          },
          '&.Mui-disabled:has(.MuiCircularProgress-root)': {
            opacity: 1,
          },
        },
        outlinedPrimary: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.text.primary,
          borderColor: theme.palette.border,
          '&:hover': {
            backgroundColor: theme.palette.background.default,
            borderColor: theme.palette.border,
          },
        },
        text: {
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            opacity: 0.4,
          },
        },
        textPrimary: {
          color: theme.palette.text.secondary,
          ':hover': {
            color: theme.palette.text.primary,
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            opacity: 0.4,
          },
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        contained: {
          boxShadow: 'none',
        },
        grouped: {
          minWidth: 'auto',
        },
        groupedContainedPrimary: {
          '&:not(:last-child)': {
            borderColor: theme.palette.border,
          },
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius * 1.5,
          color: 'inherit',
        },
        sizeSmall: {
          padding: theme.spacing(0.5),
          '&.MuiIconButton-edgeStart': {
            marginLeft: theme.spacing(-0.5),
          },
          '&.MuiIconButton-edgeEnd': {
            marginRight: theme.spacing(-0.5),
          },
        },
        colorPrimary: {
          background: '#8A12E7',
          borderRadius: 50,
          color: theme.palette.common.white,
          '&:hover': {
            background: '#AD59EE',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
      styleOverrides: {
        standard: {
          // MUI hard codes this to 24px so we need to override it with !important
          paddingRight: `${ICON_SIZES.small + 2}px !important`,
          '&.MuiSelect-select:focus': {
            backgroundColor: 'transparent',
          },
        },
        icon: {
          fontSize: ICON_SIZES.small,
          transform: 'none',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: theme.spacing(5),
          height: theme.spacing(3),
          padding: 0,
          marginLeft: 'auto',
        },
        input: {
          left: 0,
          width: '100%',
        },
        switchBase: {
          padding: 0,
          margin: theme.spacing(0.25),
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: theme.palette.text.inverse,
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
              border: 0,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.palette.primary.main,
            border: '6px solid #fff',
          },
        },
        thumb: {
          boxSizing: 'border-box',
          width: theme.spacing(2.5),
          height: theme.spacing(2.5),
        },
        track: {
          borderRadius: theme.spacing(1.75),
          opacity: 1,
          backgroundColor: theme.palette.secondary.light,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        input: {
          [`@media screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: ${theme.breakpoints.values.md}px)`]: {
            fontSize: '1rem', // iPhone Safari will zoom in on Inputs with fontSize smaller than 16px
          },
        },
        inputSizeSmall: {
          ...typography.body2.web,
        },
        adornedStart: {
          paddingLeft: theme.spacing(1.5),
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.shape.borderRadius * 1.5,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.border,
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.a11yFocusRing,
              borderWidth: 2,
            },
          },
          '&.Mui-error': {
            backgroundColor: theme.palette.error.light,
            '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.secondary.light,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.border,
            },
          },
        },
        notchedOutline: {
          borderColor: theme.palette.border,
        },
        adornedStart: {
          paddingLeft: theme.spacing(1.5),
        },
        adornedEnd: {
          paddingRight: theme.spacing(1.5),
        },
        input: {
          height: 'auto',
        },
        inputSizeSmall: {
          padding: theme.spacing(1, 1.5),
        },
        inputAdornedStart: {
          paddingLeft: 0,
        },
        inputAdornedEnd: {
          paddingRight: 0,
        },
        multiline: {
          padding: 0,
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius * 5,
        },
        adornedStart: {
          paddingLeft: theme.spacing(1.5),
        },
        adornedEnd: {
          paddingRight: theme.spacing(1.5),
        },
        input: {
          height: 'auto',
          color: theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightMedium,
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.primary,
          },
        },
        inputSizeSmall: {
          padding: theme.spacing(1, 1.5),
        },
        inputAdornedStart: {
          paddingLeft: 0,
        },
        inputAdornedEnd: {
          paddingRight: 0,
        },
        multiline: {
          padding: 0,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '&.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
            marginTop: 0,
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true, // otherwise placeholder gets 0 opacity
      },
      styleOverrides: {
        root: {
          transform: 'none',
          position: 'unset',
          transformOrigin: 'unset',
          pointerEvents: 'auto',
          zIndex: 'auto',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...typography.body2.web,
          marginBottom: theme.spacing(0.5),
          '&, &.Mui-disabled, &.Mui-focused, &.Mui-error': {
            color: theme.palette.text.primary,
          },
        },
        asterisk: {
          marginLeft: theme.spacing(-0.25),
          '&, &.Mui-disabled, &.Mui-focused, &.Mui-error': {
            color: theme.palette.text.primary,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          marginTop: theme.spacing(0.5),
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          marginBottom: theme.spacing(2),
        },
      },
    },
  },
};

const extendedButtons: ThemeOptions = {
  components: {
    MuiToggleButton: {
      styleOverrides: {
        ...(inputs!.components!.MuiButton!.styleOverrides as object),
        ...{
          root: {
            ...(inputs!.components!.MuiButton!.styleOverrides!.root as object),
            ...(inputs!.components!.MuiButton!.styleOverrides!.outlined as object),
            color: theme.palette.text.primary,
          },
        },
      },
    },
  },
};

const dataDisplay: ThemeOptions = {
  components: {
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },
      styleOverrides: {
        fontSizeSmall: {
          fontSize: theme.typography.pxToRem(ICON_SIZES.small),
        },
        fontSizeMedium: {
          fontSize: theme.typography.pxToRem(ICON_SIZES.medium),
        },
        fontSizeLarge: {
          fontSize: theme.typography.pxToRem(ICON_SIZES.large),
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: theme.shape.borderRadius * 1.5,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          ...typography.body2.web,
          fontWeight: FONT_WEIGHT.NORMAL,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <ClearIcon />,
      },
      styleOverrides: {
        root: {
          borderRadius: 24,
          backgroundColor: theme.palette.secondary.dark,
          // color: theme.palette.text.primary,
          height: 'auto',
        },
        colorPrimary: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.main,
        },
        label: {
          ...typography.body2.web,
          // fontWeight: FONT_WEIGHT.BOLD,
          color: 'inherit',
          padding: 0,
          lineHeight: '120%',
          fontWeight: FONT_WEIGHT.NORMAL,
          // fontSize: 11,
        },
        sizeSmall: {
          padding: theme.spacing(0.25, 0.5),
        },
        sizeMedium: {
          padding: theme.spacing(1.5, 2.5),

        },
        colorSuccess: {
          backgroundColor: emerald[550],
          color: theme.palette.common.white,
          fontWeight: FONT_WEIGHT.BOLD,
        },
        avatar: {
          margin: 0,
        },
        avatarMedium: {
          marginRight: theme.spacing(0.5),
        },
        avatarSmall: {
          marginRight: theme.spacing(0.25),
        },
        labelSmall: {
          fontSize: 10,
          // textTransform: 'uppercase',
          fontWeight: FONT_WEIGHT.BOLD,
        },
        icon: {
          fontSize: ICON_SIZES.small,
          color: 'inherit',
          marginRight: theme.spacing(0.5),
          marginLeft: 0,
        },
        iconSmall: {
          marginRight: theme.spacing(0.25),
        },
        deleteIcon: {
          fontSize: ICON_SIZES.small,
          color: theme.palette.text.secondary,
          marginLeft: theme.spacing(0.5),
          marginRight: 0,
          ':hover': {
            color: theme.palette.text.primary,
          },
        },
        deleteIconSmall: {
          fontSize: ICON_SIZES.small,
          marginLeft: theme.spacing(0.25),
          marginRight: 0,
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: theme.spacing(5),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.palette.text.primary,
        },
        secondary: {
          color: theme.palette.text.secondary,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius * 2,
          '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.border,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...typography.body2.web,
          borderbottom: `1px solid ${theme.palette.border}`,
          '.MuiTableRow-root:last-child td&': {
            borderbottom: 'none',
          },
          '.MuiListItemText-root': {
            marginTop: 0,
            marginBottom: 0,
          },
          '.MuiIconButton-sizeMedium': {
            marginTop: `-${theme.spacing(1)}`,
            marginBottom: `-${theme.spacing(1)}`,
          },
          '.MuiIconButton-sizeSmall': {
            marginTop: `-${theme.spacing(0.5)}`,
            marginBottom: `-${theme.spacing(0.5)}`,
          },
        },
        head: {
          ...typography.heading5.web,
        },
        stickyHeader: {
          backgroundColor: theme.palette.common.white,
          '&:first-child': {
            borderTopLeftRadius: 12,
          },
          '&:last-child': {
            borderTopRightRadius: 12,
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          ':hover': {
            color: 'inherit',
          },
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        rowsPerPageOptions: [10, 25, 50],
        rowsPerPage: 10,
      },
      styleOverrides: {
        toolbar: {
          padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
          minHeight: 'auto',
          gap: theme.spacing(1),
          [theme.breakpoints.up('sm')]: {
            minHeight: 'auto',
            gap: theme.spacing(2),
            padding: theme.spacing(2),
          },
          borderTop: `1px solid ${theme.palette.border}`,
          '& > .MuiInputBase-root': {
            margin: '0',
          },
        },
        selectLabel: {
          fontSize: typography.body2.web.fontSize,
          margin: 0,
        },
        select: {
          fontSize: typography.body2.web.fontSize,
          padding: 0,
          paddingRight: `${ICON_SIZES.small + 2}px !important`,
        },
        displayedRows: {
          fontSize: typography.body2.web.fontSize,
          margin: 0,
        },
        actions: {
          margin: `-${theme.spacing(1)} 0`,
        },
      },
    },
  },
};

const feedback: ThemeOptions = {
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 3, 2, 3),
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 3, 2, 3),
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          background: theme.palette.primary.main,
          height: theme.spacing(0.5),
          borderRadius: theme.shape.borderRadius,
        },
        colorPrimary: {
          backgroundColor: theme.palette.border,
        },
        colorSecondary: {
          background: theme.palette.primary.main,
          height: theme.spacing(2),
        },
      },
    },
  },
};

const surfaces: ThemeOptions = {
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: theme.shape.borderRadius * 3,
          overflow: 'hidden',
        },
        elevation1: {
          boxShadow: '0 1px 3px rgba(31, 31, 23, 0.1)',
        },
        elevation2: {
          boxShadow: '0px 8px 20px -4px rgba(0, 0, 0, 0.12), 0px 3px 6px -3px rgba(0, 0, 0, 0.08)',
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        focusHighlight: {
          display: 'none',
        },
      },
    },
  },
};

const navigation: ThemeOptions = {
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          textDecorationColor: 'inherit',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 2,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      },
      styleOverrides: {
        paper: {
          borderRadius: theme.shape.borderRadius * 2,
          border: `1px solid ${theme.palette.border}`,
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 2,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      },
      styleOverrides: {
        list: {
          overflow: 'auto',
          maxHeight: `calc(100vh - ${theme.spacing(20)})`,
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTabs: {
      defaultProps: {
        variant: 'scrollable',
      },
      styleOverrides: {
        indicator: {
          height: theme.spacing(0.5),
          borderRadius: theme.shape.borderRadius * 0.25,
          backgroundColor: theme.palette.common.black,
        },
        flexContainer: {
          gap: theme.spacing(3),
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...typography.body1.web,
          textTransform: 'none',
          padding: 0,
          minWidth: theme.spacing(5),
          [theme.breakpoints.up('xs')]: {
            minWidth: 0,
          },
          color: theme.palette.text.secondary,
        },
        textColorInherit: {
          color: 'inherit',
        },
        textColorPrimary: {
          '&.Mui-selected': {
            color: theme.palette.text.primary,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <ChevronRightIcon fontSize="medium" />,
      },
      styleOverrides: {
        li: {
          '&, & a': {
            color: theme.palette.text.secondary,
          },
          '& a:hover, & a:focus': {
            color: theme.palette.text.primary,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        horizontal: {
          gap: theme.spacing(2),
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        horizontal: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiStepIcon: {
      defaultProps: {
        classes: { root: 'MuiSvgIcon-fontSizeLarge' },
      },
      styleOverrides: {
        root: {
          fontSize: ICON_SIZES.large,
          color: theme.palette.border,
        },
        text: {
          fontWeight: FONT_WEIGHT.BOLD,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active, &.Mui-completed': {
            fontWeight: FONT_WEIGHT.BOLD,
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: theme.palette.border,
          borderTopWidth: 2,
        },
      },
    },
  },
};

const layout: ThemeOptions = {
  components: {
    MuiContainer: {
      defaultProps: {
        sx: { '&:not(.MuiContainer-disableGutters)': { px: { md: 4 } } },
      },
    },
  },
};

const muiXDatePicker = {
  components: {
    MuiPickersArrowSwitcher: {
      defaultProps: {
        slotProps: {
          previousIconButton: {
            edge: false,
          },
          nextIconButton: {
            edge: false,
          },
          leftArrowIcon: {
            fontSize: 'small',
          },
          rightArrowIcon: {
            fontSize: 'small',
          },
        },
      },
      styleOverrides: {
        button: {
          width: theme.spacing(4),
          height: theme.spacing(4),
          ...(inputs!.components!.MuiButton!.styleOverrides!.outlined as object),
          ...(inputs!.components!.MuiButton!.styleOverrides!.outlinedPrimary as object),
        },
        spacer: {
          width: theme.spacing(1),
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius * 2,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '&:focus:not(.Mui-focusVisible):not(.Mui-selected)': {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          },
        },
        today: {
          '&:not(.Mui-selected)': {
            border: `1px solid ${theme.palette.divider}`,
          },
        },
      },
    },
  },
};

export const appTheme = createTheme(
  theme,
  inputs,
  extendedButtons,
  dataDisplay,
  feedback,
  surfaces,
  navigation,
  layout,
  muiXDatePicker,
);
