import { AccessTime } from '@mui/icons-material';
import { styled } from 'styled-components';

import { IArticlePreview } from '../types/IArticle';

export const ReadtimeBadge = ({ readminutes }: { readminutes: IArticlePreview['readminutes'] }) => (
  <Container>
    <AccessTime fontSize="small" />
    <Readtime>{readminutes} min</Readtime>
  </Container>
);

const Container = styled.div`
  color: #69518C;
  font-size: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 0 6px;
`;

const Readtime = styled.p`
  font-weight: 600;
  margin: 0px;
`;
