import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  Navigate,
} from 'react-router-dom';

import { ROUTE_ADMIN } from '../enums/route';
import { AdminApp } from '../AdminApp';
import { OrganizationPage } from '../pages/Organization';
import { AuthGuard } from '../../common/components/AuthGuard';

export const routerAdmin = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTE_ADMIN.ROOT} element={<AdminApp />}>
      <Route path={ROUTE_ADMIN.ADMIN} element={<AuthGuard component={OrganizationPage} />} />
      <Route
        path={ROUTE_ADMIN.ROOT}
        element={<Navigate to={ROUTE_ADMIN.ADMIN} replace />}
      />
    </Route>,
  ),
);
