// import { nightfall } from '../common/styles/colors/nightfall';
// import { limeade } from "../common/styles/colors/limeade";
import { orchid } from '../../common/styles/colors/orchid';
import { blossom } from '../../common/styles/colors/blossom';
// import { violet } from "../common/styles/colors/violet";
// import { emerald } from "../common/styles/colors/emerald";
import { MentalHealthIllustration } from '../atoms/illustrations/MentalHealthIllustration';
import { PhysicalHealthIllustration } from '../atoms/illustrations/PhysicalHealthIllustration';
import { SocialLifeIllustration } from '../atoms/illustrations/SocialLifeIllustration';
import { RealLifeIllustration } from '../atoms/illustrations/RealLifeIllustration';
import { RedFlagIllustration } from '../atoms/illustrations/RedFlagIllustration';
import { ResourceIllustration } from '../atoms/illustrations/ResourceIllustration';
import { blue } from '../../common/styles/colors/blue';

export interface CategoryButtonParams {
  label: string;
  slug: string;
  color: string;
  backgroundColor: string;
  svg: any;
}

export const categories: CategoryButtonParams[] = [
  {
    label: 'Mental Health',
    slug: 'mental-health',
    color: '#E2E84B',
    backgroundColor: '#9C4295',
    svg: <MentalHealthIllustration />,
  },
  {
    label: 'Physical Health',
    slug: 'physical-health',
    color: '#2C1844',
    backgroundColor: '#71B979',
    svg: <PhysicalHealthIllustration />,
  },
  {
    label: 'Social Life',
    slug: 'social-life',
    color: blossom[900],
    backgroundColor: '#6B44A3',
    svg: <SocialLifeIllustration />,
  },
  {
    label: 'Real Life',
    slug: 'real-life',
    color: blue[900],
    backgroundColor: '#EABEF2',
    svg: <RealLifeIllustration />,
  },
];

export const resource = {
  label: 'Resources',
  slug: 'resources',
  color: blue[800],
  backgroundColor: blue[100],
  description: 'Helpful links with trustworthy information',
  svg: <ResourceIllustration />,
};

export const redFlag = {
  label: 'Red Flags',
  slug: 'red-flags',
  color: orchid[1000],
  backgroundColor: blossom[100],
  description: 'Stay Safe—Learn when to seek care',
  svg: <RedFlagIllustration />,
};

export const articleCategories = categories.concat([resource, redFlag]);
