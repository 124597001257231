import { IArticlePreview } from '../types/IArticle';
import { RedFlagCard } from './Card/RedFlagCard';
import { FeatureCard } from './Card/FeatureCard';
import { ResourceBlock } from './ResourceBlock';

interface ArticlePreviewProps {
  article: IArticlePreview,
  slug: string,
  showCategory?: boolean;
  showDescription?: boolean;
}

export const ArticleCard = ({
  article, slug, showCategory, showDescription, ...props
}: ArticlePreviewProps): JSX.Element => {
  const renderCard = () => {
    if (article.category === 'Red Flags') {
      return <RedFlagCard article={article} slug={slug} {...props} />;
    } if (article.type === 'Resource') {
      return <ResourceBlock resources={[article]} loading={false} showAllButton={false} />;
    }
    return <FeatureCard article={article} slug={slug} showCategory={showCategory} showDescription={showDescription} {...props} />;
  };

  return (
    <>
      { renderCard() }
    </>
  );
};
