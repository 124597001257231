import {
  AppBar,
  Box,
  Toolbar,
  Container,
  useScrollTrigger,
  Button,
  Typography,
} from '@mui/material';
import { cloneElement } from 'react';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { BypassLink } from '../../common/atoms/BypassLink';
import { ROUTE_INTERNAL } from '../enums/route';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const ElevationScroll = (props: Props) => {
  const { children, window } = props;
  useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    style: {
      background: 'rgba(255, 255, 255, 0.65)',
    },
    elevation: 0,
  });
};

const MainNavContent = () => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    style={{ width: '100%' }}
  >
    <Typography variant="h2">Joyuus Internal</Typography>
    <Button to={ROUTE_INTERNAL.INTERNAL} component={RouterLink}>
      Home
    </Button>
  </Box>
);

export const AppHeader = (): JSX.Element => (
  <ElevationScroll>
    <AppBar
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <BypassLink />
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <MainNavContent />
        </Toolbar>
      </Container>
    </AppBar>
  </ElevationScroll>
);
