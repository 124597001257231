import { useState } from 'react';
import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import { SEARCH_TXT_PLACEHOLDER } from '../utils/app-language';
import { ROUTE } from '../enums/route';

export const ArticleSearch = ({ searchInput }: { searchInput?: string }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>(searchInput || '');

  const submitSearch = async () => {
    navigate(`${ROUTE.SEARCH}?search=${searchText}`);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      submitSearch();
    }
  };

  return (
    <StyledTextField
      value={searchText}
      variant="filled"
      placeholder={SEARCH_TXT_PLACEHOLDER}
      onChange={(e) => setSearchText(e.target.value)}
      onKeyDown={(e) => handleKeyPress(e)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      autoFocus
    />
  );
};

const StyledTextField = styled(TextField)`
  width: 100%;
`;
