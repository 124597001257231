import { ANALYTICS_EVENT_NAMES } from '../../common/enums/eventNames';
import { Amplitude } from '../../common/services/analytics/amplitude.service';
import { IEventProperties, ITrackValue } from '../../common/types/ITrackEvent';
import { IArticle, IArticlePreview } from '../types/IArticle';
import { stringToSlug } from '../utils/unit-functions';

export class UserTrackingService {
  public trackBookmarkToggle = (article: IArticle | IArticlePreview, bookmarkStatus: boolean) => {
    const value = {
      previous: bookmarkStatus.toString(),
      new: (!bookmarkStatus).toString(),
    };
    const eventInput = bookmarkStatus
      ? ANALYTICS_EVENT_NAMES.CLICK
      : ANALYTICS_EVENT_NAMES.BOOKMARK;
    const eventProperties: IEventProperties = {
      article: article.slug,
      category: stringToSlug(article.category),
      subcategory: stringToSlug(article.subcategory),
      type: stringToSlug(article.type),
      target: 'toggle-bookmark-button',
      value,
    };
    Amplitude.track({
      eventProperties,
      eventInput,
    });
  };

  public trackAccountDeletion = async () => {
    const eventProperties: IEventProperties = {
      page: 'profile',
      target: 'delete-confirm',
    };
    Amplitude.track({
      eventProperties,
      eventInput: ANALYTICS_EVENT_NAMES.ACCOUNT_DELETED,
    });
  };

  public trackSearch = (value: ITrackValue) => {
    const eventProperties: IEventProperties = {
      page: 'search',
      target: 'search-enter',
      value,
    };
    Amplitude.track({
      eventProperties,
      eventInput: ANALYTICS_EVENT_NAMES.SEARCH,
    });
  };
}

export const UserTracking = new UserTrackingService();
