import { ArticleCard } from '../components/ArticleCard';
import { useGetArticleList } from '../hooks/useArticles';
import { IArticlePreview } from '../types/IArticle';
import { Grid } from '../../common/atoms/Grid';
import { theme } from '../../common/theme/theme';
import { RedFlagHeader } from '../components/CategoryHeader';
import { RedFlagCardSkeleton } from '../components/Card/RedFlagCard';
import { ROUTE } from '../enums/route';

const SkeletonCard = (key: number) => (
  <Grid.Cell key={key} $bleed xs={12}>
    <RedFlagCardSkeleton />
  </Grid.Cell>
);

export const RedFlags = () => {
  const category = 'red-flags';
  const { data: articleList, isLoading } = useGetArticleList(category);

  return (
    <div className="content">
      <RedFlagHeader />
      <Grid rowgap={theme.space(4)} colgap={theme.space(4)} sx={{ py: 4 }} justifyItems="space-between">
        { !isLoading && articleList?.length ? articleList.map((preview: IArticlePreview) => (
          <Grid.Cell key={preview?.slug} $bleed xs={12}>
            <ArticleCard key={preview?.id} article={preview} slug={`${ROUTE.ARTICLE}/${preview?.slug}`} />
          </Grid.Cell>
        )) : Array.from({ length: 4 }).map((value: unknown, index: number) => SkeletonCard(index))}
      </Grid>
    </div>
  );
};
