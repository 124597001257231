import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { CACHE_KEY } from '../enums/cacheKey';
import { IArticle, IArticlePreview } from '../types/IArticle';
import { ApiService } from '../../common/services/api-service';
import { UserTracking } from '../services/userTracking.service';
import { ITrackValue } from '../../common/types/ITrackEvent';

const search = async (value: ITrackValue): Promise<AxiosResponse<IArticlePreview[]>> => {
  UserTracking.trackSearch(value);
  const apiConfig = { params: { thumbnails: true } };
  return await ApiService.api.get<IArticlePreview[]>(`/article/search/${value.new}`, apiConfig);
};

export const useGetArticle = (articleSlug?: string) => useQuery<IArticle>({
  queryKey: [CACHE_KEY.ARTICLE, articleSlug],
  queryFn: async () => (await ApiService.api.get(`/article/${articleSlug}`)).data,
  enabled: !!articleSlug,
});

export const useGetArticleList = (categorySlug?: string) => useQuery<IArticlePreview[]>({
  queryKey: [CACHE_KEY.ARTICLE_LIST, categorySlug],
  queryFn: async () => (await ApiService.api.get(`/article/list/${categorySlug}`, { params: { thumbnails: true } })).data,
  enabled: !!categorySlug,
});

export const useMutateSearch = () => useMutation({
  mutationFn: search,
  onSuccess: (data: AxiosResponse<IArticlePreview[]>) => data.data,
});

// Since this is really one call, this can likely be optimized
// to one hook, or to better take advantage of react query.
export const useGetDiscoverResources = () => useQuery<IArticlePreview[]>({
  queryKey: [CACHE_KEY.DISCOVER_RESOURCE_LIST],
  queryFn: async () => (await ApiService.api.get('/article/resources', { params: { count: 4 } })).data,
});

export const useGetResources = () => useQuery<IArticlePreview[]>({
  queryKey: [CACHE_KEY.FULL_RESOURCE_LIST],
  queryFn: async () => (await ApiService.api.get('/article/resources')).data,
});

export const useGetCategoryResources = (categorySlug?: string) => useQuery<IArticlePreview[]>({
  queryKey: [CACHE_KEY.CATEGORY_RESOURCE_LIST, categorySlug],
  queryFn: async () => (await ApiService.api.get('/article/resources', { params: { category: categorySlug, count: 4 } })).data,
});
