import { nightfall } from './colors/nightfall';
import { calcLineHeight, calcPxToRem } from './utils';

export enum WEB_FONT_FAMILY {
  SANS_SERIF = "'Source Sans 3'",
  SERIF = "'Merriweather'",
}

export enum NATIVE_FONT_FAMILY {
  SANS_SERIF_NORMAL = 'inter', // 400
  SANS_SERIF_BOLD = 'interbold', // 600
  SERIF_BOLD = 'sourceserif4', // 600
}

export enum FONT_WEIGHT {
  NORMAL = 400,
  MEDIUM = 500,
  BOLD = 600,
}

export const midnight = '#1E024B';

// see https://www.figma.com/file/5ZWpj5sBMn5yZBrEwWG8ST/%F0%9F%94%A9-Foundations?node-id=513%3A11&t=dDMgwjliOaVUv2JA-1
export const typography = {
  heading1: {
    web: {
      fontFamily: `${WEB_FONT_FAMILY.SERIF}`,
      fontWeight: FONT_WEIGHT.BOLD,
      fontSize: calcPxToRem(32),
      lineHeight: 1.3,
      color: nightfall[900],
    },
    native: {
      fontFamily: NATIVE_FONT_FAMILY.SERIF_BOLD,
      fontSize: 32,
      lineHeight: calcLineHeight(32, 1.2),
    },
  },
  heading2: {
    web: {
      color: midnight,
      fontFamily: `${WEB_FONT_FAMILY.SERIF}`,
      fontWeight: FONT_WEIGHT.BOLD,
      fontSize: calcPxToRem(16),
      lineHeight: 1.4,
    },
    native: {
      fontFamily: NATIVE_FONT_FAMILY.SERIF_BOLD,
      fontSize: 24,
      lineHeight: calcLineHeight(24, 1.2),
    },
  },
  heading3: {
    web: {
      color: midnight,
      fontFamily: `${WEB_FONT_FAMILY.SANS_SERIF}`,
      fontWeight: FONT_WEIGHT.BOLD,
      fontSize: calcPxToRem(16),
      lineHeight: 1.4,
    },
    native: {
      fontFamily: NATIVE_FONT_FAMILY.SANS_SERIF_BOLD,
      fontSize: 16,
      lineHeight: calcLineHeight(16, 1.2),
    },
  },
  heading4: {
    web: {
      fontWeight: FONT_WEIGHT.BOLD,
      fontSize: calcPxToRem(15),
      lineHeight: 1.2,
    },
    native: {
      fontFamily: NATIVE_FONT_FAMILY.SANS_SERIF_BOLD,
      fontSize: 15,
      lineHeight: calcLineHeight(15, 1.2),
    },
  },
  heading5: {
    web: {
      fontWeight: FONT_WEIGHT.BOLD,
      fontSize: calcPxToRem(13),
      lineHeight: 1.2,
    },
    native: {
      fontFamily: NATIVE_FONT_FAMILY.SANS_SERIF_BOLD,
      fontSize: 13,
      lineHeight: calcLineHeight(13, 1.2),
    },
  },
  heading6: () => typography.heading5, // unused ATM but defined for consistency
  body1: {
    web: {
      color: midnight,
      fontFamily: `${WEB_FONT_FAMILY.SANS_SERIF}`,
      fontWeight: FONT_WEIGHT.NORMAL,
      fontSize: calcPxToRem(17),
      lineHeight: 1.5,
    },
    native: {
      fontFamily: NATIVE_FONT_FAMILY.SANS_SERIF_NORMAL,
      fontSize: 15,
      lineHeight: calcLineHeight(15, 1.5),
    },
  },
  body1Bold: () => ({
    // for mobile use only
    ...typography.body1.native,
    fontFamily: NATIVE_FONT_FAMILY.SANS_SERIF_BOLD,
  }),
  body2: {
    web: {
      color: midnight,
      fontFamily: `${WEB_FONT_FAMILY.SANS_SERIF}`,
      fontWeight: FONT_WEIGHT.BOLD,
      fontSize: calcPxToRem(17),
      lineHeight: 1.5,
    },
    native: {
      fontFamily: NATIVE_FONT_FAMILY.SANS_SERIF_NORMAL,
      fontSize: 13,
      lineHeight: calcLineHeight(13, 1.5),
    },
  },
  body2Bold: () => ({
    // for mobile use only
    ...typography.body2.native,
    fontFamily: NATIVE_FONT_FAMILY.SANS_SERIF_BOLD,
  }),
};
