import { createInstance } from '@amplitude/analytics-browser';
import {
  EnrichmentPlugin, BrowserClient, BrowserConfig, Event,
} from '@amplitude/analytics-types';

import { ITrackEventInput } from '../../types/ITrackEvent';
import { AnalyticsUtils } from './analyticsUtils';
import { AMPLITUDE_AUTO_TRACKED_TYPE, ANALYTICS_EVENT_NAMES } from '../../enums/eventNames';
import { ANALYTICS_EVENT_NAMES_ADMIN } from '../../../app-admin/enums/eventNames';
import { USER_ROLE } from '../../enums/userRole';

export class AmplitudeService {
  amplitudeClient: BrowserClient | null = null;

  amplitudeConfig = {
    serverUrl: process.env.REACT_APP_API_BASE_URL?.concat('/api/track'),
    autocapture: {
      attribution: false,
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: false,
      elementInteractions: false,
    },
  };

  // Amplitude Enrichment Plugin to add userId and any other needed data to tracking events
  enrichTrackingEvents = (userAuthId: string): EnrichmentPlugin<BrowserClient, BrowserConfig> => ({
    name: 'joyuus-event-enrichment',
    type: 'enrichment',
    setup: async () => {},
    execute: async (event: Event) => {
      const incomingType = event.event_type as ANALYTICS_EVENT_NAMES | AMPLITUDE_AUTO_TRACKED_TYPE | ANALYTICS_EVENT_NAMES_ADMIN;
      const joyuusType = AnalyticsUtils.getJoyuusEventName(incomingType);
      const enhancedEvent = {
        ...event,
        event_type: joyuusType,
        user_id: userAuthId,
        extra: {
          user_role: USER_ROLE.USER,
        },
      };
      return enhancedEvent;
    },
  });

  public initAmplitude = async (key: string, userAuthId: string) => {
    this.amplitudeClient = createInstance() as BrowserClient;
    this.amplitudeClient.add(this.enrichTrackingEvents(userAuthId));
    this.amplitudeClient.init(key, this.amplitudeConfig);
  };

  public track = ({ eventInput, eventProperties }: ITrackEventInput) => {
    // TODO screen out internal/test users at some point
    // Holding on this for now because we're using internal users
    // for testing that the tracking works in prod
    const trackableEvent = this.amplitudeClient && process.env.REACT_APP_ENVIRONMENT !== 'local';
      if (trackableEvent) {
      this.amplitudeClient!.track(
        eventInput,
        eventProperties,
      );
    }
  };
}

export const Amplitude = new AmplitudeService();
