import { useCallback, useEffect, useState } from 'react';

import { useGetResources } from '../hooks/useArticles';
import { IArticlePreview } from '../types/IArticle';
import { Grid } from '../../common/atoms/Grid';
import { theme } from '../../common/theme/theme';
import { ResourceHeader } from '../components/CategoryHeader';
import { ResourceCard, ResourceCardSkeleton } from '../components/Card/ResourceCard';
import { categories } from '../utils/categories';
import { CategoryFilter } from '../components/CategoryFilter';
import { ANALYTICS_EVENT_NAMES } from '../../common/enums/eventNames';
import { stringToSlug } from '../utils/unit-functions';
import { ROUTE } from '../enums/route';
import { Amplitude } from '../../common/services/analytics/amplitude.service';
import { IEventProperties, ITrackEventInput, ITrackValue } from '../../common/types/ITrackEvent';

const SkeletonCard = (key: number) => (
  <Grid.Cell key={key} $bleed xs={12}>
    { ResourceCardSkeleton(key) }
  </Grid.Cell>
);

export const Resources = () => {
  const { data: resourceList } = useGetResources();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [displayResources, setDisplayResources] = useState<IArticlePreview[]>([]);

  useEffect(() => {
    // Set all categories as selected on page load
    if (resourceList?.length) {
      if (selectedCategories.length > 0) {
        const filteredResources = resourceList.filter((preview) => preview.category === selectedCategories[0]);
        setDisplayResources(filteredResources);
      } else {
        setDisplayResources(resourceList);
      }
    }
  }, [resourceList, selectedCategories]);

  // Called by child components when filter is added
  const addFilter = useCallback((name: string) => {
    const value: ITrackValue = {
      new: stringToSlug(name),
    };
    const eventProperties: IEventProperties = {
      page: 'resources',
      type: 'resource',
      target: 'category-filter',
      value,
    };
    const eventInput: ITrackEventInput = {
      eventProperties,
      eventInput: ANALYTICS_EVENT_NAMES.CLICK,
    };
    Amplitude.track(eventInput);

    setSelectedCategories([name]);
  }, []);

  // Called by child components when filter is removed
  const removeFilter = () => {
    setSelectedCategories([]);
  };

  return (
    <div className="content">
      <ResourceHeader />
      {categories && <CategoryFilter addFilter={addFilter} removeFilter={removeFilter} baseRoute={ROUTE.RESOURCE} /> }
      <Grid rowgap={theme.space(4)} colgap={theme.space(4)} sx={{ py: 4 }} justifyItems="space-between">
        {displayResources?.length ? displayResources.map((preview: IArticlePreview) => (
          <Grid.Cell key={preview.slug} $bleed xs={12}>
            <ResourceCard
              key={preview?.id}
              article={preview}
              slug={`${ROUTE.ARTICLE}/${preview?.slug}`}
              showCategory={false}
            />
          </Grid.Cell>
        )) : Array.from({ length: 4 }).map((value: unknown, index: number) => SkeletonCard(index)) }
      </Grid>
    </div>
  );
};
