/**
 * @description
 * cache keys for react query's cache.
 */
export enum CACHE_KEY {
  DISCOVER_ARTICLES = 'discover-articles',
  ARTICLE_LIST = 'article-list',
  ARTICLE = 'article',
  CATEGORY = 'category',
  USER = 'user',
  USER_BOOKMARKS = 'user-bookmarks',

  // Resource article type
  DISCOVER_RESOURCE_LIST = 'discover-resource-list',
  FULL_RESOURCE_LIST = 'resource-list',
  CATEGORY_RESOURCE_LIST = 'category-resource-list',
  SEARCH = 'search',

  // Ops user functionality
  ORGANIZATIONS = 'organizations',
}
