import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { Add, Remove } from '@mui/icons-material';

import { IUser } from '../types/IUser';
import { Grid } from '../atoms/Grid';
import { InvitationForm } from './InvitationForm';
import { CircularIconButton } from '../atoms/CircularIconButton';
import InvitationsTable from './InvitationsTable';
import UsersTable from './UsersTable';
import { WidthConstrainedContent } from '../atoms/WidthConstrainedContent';
import { IOrganization } from '../types/IOrganization';
import { IInvitation } from '../types/IInvitation';

interface IOrganizationParams {
  organization: IOrganization | undefined;
  users: IUser[];
  invitations: IInvitation[];
  setMessage: (message: string) => void;
}

export const Organization = ({
  organization,
  users,
  invitations,
  setMessage,
}: IOrganizationParams) => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const hideForm = () => {
    setShowForm(false);
  };

  return (
    <WidthConstrainedContent>
      <h2>{organization?.name}</h2>

      <h4>Users</h4>
      {users && users.length > 0 && <UsersTable users={users} setMessage={setMessage} />}

      <h4>
        Invitations
        <Tooltip title="Invite new user" sx={{ ml: 2 }}>
          <CircularIconButton color="primary" edge="start" onClick={() => setShowForm(!showForm)}>
            {showForm ? <Remove fontSize="small" /> : <Add fontSize="small" />}
          </CircularIconButton>
        </Tooltip>
      </h4>

      <Grid>
        { showForm && organization && (
          <Grid.Cell key="form" $bleed>
            <InvitationForm organization={organization} onComplete={hideForm} setMessage={setMessage} />
          </Grid.Cell>
        )}
      </Grid>
      {invitations && invitations.length > 0 && <InvitationsTable invitations={invitations} />}

    </WidthConstrainedContent>
  );
};
