// Represents the user received from auth0

export interface IAuthUser {
  email: string;
  email_verified: boolean;
  family_name?: string;
  given_name?: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string; // = auth0 user id
}

export const NullAuthUser: IAuthUser = {
  email: '',
  email_verified: false,
  family_name: '',
  given_name: '',
  name: '',
  nickname: '',
  picture: '',
  sub: '',
};
