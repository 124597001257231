import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { IUser } from '../types/IUser';
import UsersTableRow from '../atoms/UsersTableRow';

interface IUsersTableProps {
  users: IUser[];
  setMessage: (message: string) => void;
}

export default function UsersTable({ users, setMessage }: IUsersTableProps) {
  return (
    <TableContainer component={Paper} sx={{ my: 2 }}>
      <Table sx={{ width: 1, minWidth: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="center">Role</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row) => (
            <UsersTableRow key={row.id} user={row} setMessage={setMessage} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
