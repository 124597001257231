import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiService } from '../../common/services/api-service';
import { INTERNAL_CACHE_KEY } from '../enums/cacheKey';
import { ICreateInvitationInput, IInvitation } from '../../common/types/IInvitation';

export const useGetOrgInvitations = (orgId?: string) => useQuery<IInvitation[]>({
  queryKey: [INTERNAL_CACHE_KEY.INVITATIONS],
  queryFn: async () => (await ApiService.api.get(`internal/invitation/${orgId}`)).data,
  enabled: !!orgId,
});

export const useCreateInvitation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (invitation: ICreateInvitationInput) => (await ApiService.api.post('/internal/invitation', { invitation })).data,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [INTERNAL_CACHE_KEY.INVITATIONS],
      });
    },
  });
};
