import { ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { appTheme } from '../theme/appTheme';

const queryClient = new QueryClient();

if (!process.env.REACT_APP_AUTH0_DOMAIN) {
  throw new Error('Missing Auth0 Domain');
}

if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
  throw new Error('Missing Auth0 Client ID');
}

export const AppWrapper = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={appTheme}>
    { children }
  </ThemeProvider>
);
