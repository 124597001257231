import { useQuery } from '@tanstack/react-query';

import { CACHE_KEY } from '../enums/cacheKey';
import { ICategory } from '../types/ICategory';
import { ApiService } from '../../common/services/api-service';

export const useGetCategory = (categorySlug?: string) => useQuery<ICategory>({
  queryKey: [CACHE_KEY.CATEGORY, categorySlug],
  queryFn: async () => (await ApiService.api.get(`/category/${categorySlug}`, { params: { thumbnails: true } })).data,
});
