import { Button, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { USER_ROLE } from '../enums/userRole';
import { UserRoleSelect } from '../atoms/UserRoleSelect';
import { IOrganization } from '../types/IOrganization';
import { ICreateInvitationInput } from '../types/IInvitation';
import { useCreateInvitation } from '../../app-admin/hooks/useInvitations';

interface IInvitationFormProps {
  organization: IOrganization;
  onComplete: () => void;
  setMessage: (message: string) => void;
}

interface IInvitationFormValues {
  email: string;
  roleName: USER_ROLE;
}

export const InvitationForm = ({ organization, onComplete, setMessage }: IInvitationFormProps) => {
  const [domain] = useState<string>(organization.domain);
  const { mutate: invite, isSuccess: inviteSuccess, isError: inviteError } = useCreateInvitation();

  const defaultValues = {
    email: '',
    roleName: USER_ROLE.USER,
  };

  useEffect(() => {
    if (inviteSuccess) {
      setMessage('Invitation sent');
    }
    if (inviteError) {
      setMessage('Error sending invitation');
    }
  }, [inviteSuccess, inviteError]);

  const createInvitation = ({ email, roleName, orgId }: ICreateInvitationInput) => {
    invite({ email, roleName, orgId });
    onComplete();
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<IInvitationFormValues>({
    defaultValues,
  });

  const submit = ({ email, roleName }: { email: string, roleName: USER_ROLE }) => {
    const { id: orgId } = organization;
    if (isValid) {
      createInvitation({ email, roleName, orgId });
      reset();
      onComplete();
    }
  };

  return (
    <div>
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5}>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <div>
                  <TextField
                    fullWidth
                    margin="dense"
                    placeholder="Email"
                    onChange={onChange}
                    value={value}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                    FormHelperTextProps={{
                      style: { fontSize: '.7rem', fontWeight: 400 },
                    }}
                    inputProps={{ style: { fontSize: '1rem', fontWeight: 400 } }}
                  />
                </div>
              )}
              rules={{
                required: true,
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Invalid email format',
                },
                validate: {
                  orgDomain: (value: string) => value.split('@')[1] === domain.substring(0, domain.length) || `Please enter an address in the ${domain} domain`,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Controller
              control={control}
              name="roleName"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <UserRoleSelect
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mx: 1, my: 1.25 }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
