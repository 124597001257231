/* eslint-disable no-restricted-imports */
import { createTheme } from '@mui/material/styles';
import { ICON_SIZES, SPACE } from '../styles/constants';
import { space } from '../styles/utils';
import { palette } from '../styles/palette';
import { FONT_WEIGHT, typography, WEB_FONT_FAMILY } from '../styles/typography';

declare module '@mui/material/styles' {
  interface Theme {
    space: (s: number) => number;
    bookCoverRatio?: number;
    iconSizes?: {
      small: number;
      medium: number;
      large: number;
    };
  }

  interface ThemeOptions {
    space?: (s: number) => number;
    bookCoverRatio?: number;
    iconSizes?: {
      small: number;
      medium: number;
      large: number;
    };
  }

  interface Palette {
    inverse: Palette['primary'];
    border: string;
    a11yFocusRing: string;
  }

  interface PaletteOptions {
    inverse: PaletteOptions['primary'];
    border: string;
    a11yFocusRing: string;
  }

  interface TypeText {
    inverse: string;
  }

  interface PaletteColor {
    lighter?: string;
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    inverse?: string;
    lighter?: string;
    darker?: string;
  }
}
declare module '@mui/material' {
  interface ButtonPropsColorOverrides {
    inverse: true;
  }
  interface IconButtonPropsColorOverrides {
    inverse: true;
  }
  interface SvgIconPropsColorOverrides {
    inverse: true;
  }
}

const baseTheme = createTheme({
  spacing: SPACE,
  space,
  iconSizes: ICON_SIZES,
  typography: {
    fontFamily: [WEB_FONT_FAMILY.SANS_SERIF, 'Arial', 'sans-serif'].join(','), // becomes base font on <body>
    fontWeightBold: 600, // default is 700
    h1: typography.heading1.web,
    h2: typography.heading2.web,
    h3: typography.heading3.web,
    h4: typography.heading4.web,
    h5: typography.heading5.web,
    h6: typography.heading5.web,
    body1: typography.body1.web,
    body2: typography.body2.web,
    subtitle1: {
      ...typography.body1.web,
      color: palette.text.secondary,
      fontWeight: FONT_WEIGHT.NORMAL, // MUI uses 500 by default
    },
    subtitle2: {
      ...typography.body2.web,
      color: palette.text.secondary,
      fontWeight: FONT_WEIGHT.NORMAL, // MUI uses 500 by default
    },
    overline: {
      ...typography.body1.web,
      color: palette.text.secondary,
      textTransform: 'none', // MUI uses uppercase by default
    },
    caption: {
      ...typography.body2.web,
      color: palette.text.secondary,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    mode: 'light',
    ...palette,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          WebkitFontSmoothing: 'antialiased',
          mozOsxFontSmoothing: 'grayscale',
        },
        'a:focus-visible': {
          // in case someone hard codes a link without using MUI
          outlineColor: palette.a11yFocusRing,
          outlineStyle: 'auto',
          outlineWidth: 2,
          outlineOffset: 0 /* override browser's default 1px offset */,
        },
        figure: {
          margin: 0,
        },
      },
    },
  },
});

export const theme = createTheme(baseTheme, {
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          backdropFilter: 'blur(10px)',
        },
        colorSecondary: {
          backgroundColor: baseTheme.palette.common.white,
        },
      },
    },
  },
});
