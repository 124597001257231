import { Link } from '@mui/material';
import styled from 'styled-components';

import { MAINCONTENT_ID } from '../../app-user/utils/constants';
import { SPACE } from '../styles/constants';
import { theme } from '../theme/theme';

export const BypassLink = (): JSX.Element => (
  <BypassLinkContainer
    href={`#${MAINCONTENT_ID}`}
    onClick={(e) => {
      e.preventDefault();
      const target = document.getElementById(MAINCONTENT_ID);
      if (target) {
        target.focus();
      }
    }}
  >
    Skip to main content
  </BypassLinkContainer>
);

const BypassLinkContainer = styled(Link).attrs({
  variant: 'body2',
})`
  position: absolute;
  top: -100px;
  opacity: 0;
  left: 0;
  transition: top 0.5s ease-out;
  border-bottom-right-radius: ${SPACE}px;
  padding: ${SPACE * 2}px;
  font-weight: bold;
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.text.inverse};
  z-index: 1;
  &:focus {
    opacity: 1;
    top: 0;
    transition: top 0.2s ease-in;
  }
`;
