import { Skeleton, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { IArticlePreview } from '../../types/IArticle';
import { IEventProperties, ITrackEventInput } from '../../../common/types/ITrackEvent';
import { ANALYTICS_EVENT_NAMES } from '../../../common/enums/eventNames';
import { CategoryTags } from '../../atoms/CategoryTags';
import { CardFooter } from './CardFooter';
import { stringToSlug } from '../../utils/unit-functions';
import { Amplitude } from '../../../common/services/analytics/amplitude.service';

interface ArticlePreviewProps {
  article: IArticlePreview,
  slug: string,
  showCategory?: boolean;
}

// Need to clean this up to only have one click event per element
export const ResourceCard = ({ article, slug, showCategory }: ArticlePreviewProps): JSX.Element => {
  const eventProperties: IEventProperties = {
    article: article.slug,
    category: stringToSlug(article.category),
    subcategory: stringToSlug(article.subcategory),
    type: stringToSlug(article.type),
    target: 'article-card',
  };
  const eventInput: ITrackEventInput = {
    eventProperties,
    eventInput: ANALYTICS_EVENT_NAMES.CLICK,
  };

  return (
    <div>
      <CategoryTags article={article} showSubCategory={showCategory} />
      <RouterLink to={slug} onClick={() => Amplitude.track(eventInput)} style={{ textDecoration: 'none' }}>

        <Typography variant="h2" style={{ padding: '8px 0px', fontSize: 15 }}>
          {article.title}
        </Typography>
      </RouterLink>

      <CardFooter article={article} />
    </div>
  );
};

export const ResourceCardSkeleton = (index: number): JSX.Element => (
  <div key={index} style={{ width: '100%' }}>
    <Skeleton width="15%" variant="text" height={20} />
    <Skeleton width="60%" variant="text" height={35} />
    <Skeleton width="15%" variant="text" height={20} />
  </div>
);
