import { useEffect, useState, ReactNode } from 'react';
import { IUser } from '../types/IUser';
import { Amplitude } from '../services/analytics/amplitude.service';

interface ITrackWrapperProps {
  user: IUser;
  amplitudeKey: string | undefined;
  children: ReactNode;
}

export const TrackWrapper = ({ user, amplitudeKey, children }: ITrackWrapperProps): JSX.Element => {
  const [amplitudeInitialized, setAmplitudeInitialized] = useState(false);

  useEffect(() => {
    if (amplitudeKey && !amplitudeInitialized) {
      Amplitude.initAmplitude(amplitudeKey, user.authId);
      setAmplitudeInitialized(true);
    }
  }, [amplitudeKey, amplitudeInitialized]);

  return (
    <>
      { children }
    </>
  );
};
