export const violet = {
  50: '#F2ECF7',
  100: '#E4D8EF',
  200: '#D7C5E7',
  300: '#CAB2DF',
  400: '#BC9ED7',
  500: '#AF8BD0',
  600: '#A278C8',
  700: '#9565C0',
  800: '#8751B8',
  900: '#7A3EB0',
};
