import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Container } from '@mui/material';

import { AppHeader } from './components/AppHeader';
import { Footer } from './components/Footer';

export const InternalApp = () => (
  <Container
    maxWidth="lg"
    style={{ marginTop: 88 }}
  >
    <AppHeader />
    <Outlet />
    <Footer />
    {/* <ScrollRestoration /> */}
  </Container>
);
