import { Link as RouterLink, useParams } from 'react-router-dom';
import { Options as RichTextRenderOptions, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import ReactPlayer from 'react-player';
import { styled } from 'styled-components';
import { Headphones } from '@mui/icons-material';
import 'react-h5-audio-player/lib/styles.css';
import {
  useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import {
  CircularProgress, IconButton, List, Typography,
} from '@mui/material';

import { useGetArticle } from '../hooks/useArticles';
import { FONT_WEIGHT, WEB_FONT_FAMILY, midnight } from '../../common/styles/typography';
import { CategoryTags } from '../atoms/CategoryTags';
import { calcPxToRem } from '../../common/styles/utils';
import { orchid } from '../../common/styles/colors/orchid';
import { nightfall } from '../../common/styles/colors/nightfall';
import { ANALYTICS_EVENT_NAMES } from '../../common/enums/eventNames';
import { IArticleBody } from '../types/IArticle';
import { ReadtimeBadge } from '../atoms/ReadtimeBadge';
import { stringToSlug } from '../utils/unit-functions';
import { ROUTE } from '../enums/route';
import { Narrator } from '../services/narration-service';
import { ARTICLE_TYPE } from '../enums/articleType';
import { WidthConstrainedContent } from '../../common/atoms/WidthConstrainedContent';
import { Amplitude } from '../../common/services/analytics/amplitude.service';
import { IEventProperties, ITrackEventInput } from '../../common/types/ITrackEvent';

export const Article = () => {
  const params = useParams<{ article: string }>();
  const { article: slug } = params;
  const { data: articleData } = useGetArticle(slug);
  const [heroIsVideo, setHeroIsVideo] = useState(false);
  const [showAudioControls, setShowAudioControls] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState('');
  const [narrationFileKey, setNarrationFileKey] = useState('');

  const eventPropertyBase = {
    article: articleData?.slug,
    category: stringToSlug(articleData?.category || ''),
    subcategory: stringToSlug(articleData?.subcategory || ''),
    type: stringToSlug(articleData?.type || ''),
    target: 'article body',
  };

  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);

  // START ARTICLE FORMATTING -- CONSIDER MOVING

  const formatContentfulRichText = useCallback((body: IArticleBody) => {
    const calloutStyle = {
      color: nightfall[900],
      fontFamily: `${WEB_FONT_FAMILY.SERIF}`,
      fontWeight: FONT_WEIGHT.MEDIUM,
      fontSize: calcPxToRem(32),
      lineHeight: 1.3,
      fontStyle: 'italic',
      borderLeft: 4,
      borderColor: orchid[900],
      paddingLeft: calcPxToRem(24),
    };

    const bodyTextStyle = {
      color: midnight,
      fontFamily: `${WEB_FONT_FAMILY.SANS_SERIF}`,
      fontWeight: FONT_WEIGHT.NORMAL,
      fontSize: calcPxToRem(17),
      lineHeight: 1.5,
      marginY: calcPxToRem(18),
    };

    const removeParagraphWrapper = (nodes: any) => {
      if (nodes.length && nodes[0].props.children) {
        return nodes[0].props.children;
      }
      return nodes;
    };

    const styleArticleLink = (node: any, children: any) => {
      const linkText = children[0];
      const route = `${ROUTE.ARTICLE}/${node.data.target.fields.slug}`;
      const eventProperties: IEventProperties = {
        ...eventPropertyBase,
        page: 'article',
        value: {
          previous: articleData ? articleData.slug : '',
          new: node.data.target.fields.stringToSlug,
        },
      };
      const eventInput: ITrackEventInput = {
        eventProperties,
        eventInput: ANALYTICS_EVENT_NAMES.BODY_INTERNAL_LINK_CLICK,
      };
      return (
        <RouterLink to={route} onClick={() => Amplitude.track(eventInput)}>
          {linkText}
        </RouterLink>
      );
    };

    const styleHyperlink = (node: any, children: any) => {
      const linkText = children[0];
      const url = node.data.uri;
      const eventProperties: IEventProperties = {
        ...eventPropertyBase,
        page: 'article',
        value: {
          previous: articleData ? articleData.slug : '',
          new: url,
        },
      };
      const eventInput: ITrackEventInput = {
        eventProperties,
        eventInput: ANALYTICS_EVENT_NAMES.BODY_EXTERNAL_LINK_CLICK,
      };
      return (
        <a href={url} target="_blank" rel="noreferrer" onClick={() => Amplitude.track(eventInput)}>
          {linkText}
        </a>
      );
    };

    const styleOrderedList = (children: any) => (
      <List sx={{ listStyleType: 'number', marginLeft: calcPxToRem(14), paddingY: 0 }}>
        {children}
      </List>
    );

    const styleUnorderedList = (children: any) => (
      <List sx={{ listStyleType: 'disc', marginLeft: calcPxToRem(18), paddingY: 0 }}>
        {children}
      </List>
    );

    const styleListItem = (children: any) => {
      if (children.length === 1) {
        return (
          <li style={{ color: orchid[900], fontWeight: 'bold' }}>
            <Typography sx={{ marginY: calcPxToRem(12) }} variant="body1">{removeParagraphWrapper(children)}</Typography>
          </li>
        );
      } if (children.length === 2) {
        return (
          <li style={{ color: orchid[900], fontWeight: 'bold' }}>
            <Typography sx={{ marginY: calcPxToRem(12) }} variant="body1">{removeParagraphWrapper(children)}</Typography>
            {children[1]}
          </li>
        );
      }
    };

    const styleHeader = (children: any, variant: 'h2' | 'h3') => (
      <Typography sx={{ marginY: calcPxToRem(8) }} variant={variant}>{children}</Typography>
    );

    const styleQuote = (children: any) => (
      <Typography sx={calloutStyle}>{removeParagraphWrapper(children)}</Typography>
    );

    const styleParagraph = (children: any) => (
      <Typography sx={bodyTextStyle}>{children}</Typography>
    );

    const richTextRenderParams: RichTextRenderOptions = {
      renderNode: {
        [INLINES.ENTRY_HYPERLINK]: (node, children) => styleArticleLink(node, children),
        [INLINES.HYPERLINK]: (node, children) => styleHyperlink(node, children),
        [BLOCKS.HEADING_2]: (node, children) => styleHeader(children, 'h2'),
        [BLOCKS.HEADING_3]: (node, children) => styleHeader(children, 'h3'),
        [BLOCKS.QUOTE]: (node, children) => styleQuote(children),
        [BLOCKS.PARAGRAPH]: (node, children) => styleParagraph(children),
        [BLOCKS.OL_LIST]: (node, children) => styleOrderedList(children),
        [BLOCKS.UL_LIST]: (node, children) => styleUnorderedList(children),
        [BLOCKS.LIST_ITEM]: (node, children) => styleListItem(children),
      },
    };
    return documentToReactComponents(body, richTextRenderParams);
  }, [articleData]);

  const bodyElements = useMemo(
    () => {
      if (articleData?.body) {
        return formatContentfulRichText(articleData?.body);
      }
      return null;
    },
    [articleData, formatContentfulRichText],
  );

  // END ARTICLE FORMATTING

  useEffect(
    () => {
      if (!narrationFileKey && articleData) {
        const key = Narrator.getNarrationFileKey(articleData.slug);
        setNarrationFileKey(key);
      }
      if (articleData?.hero) {
        const { contentType } = articleData.hero.file;
        if (contentType.split('/')[0] === 'video') {
          setHeroIsVideo(true);
        }
      }
    },
    [articleData],
  );

  const trackNarrationEvent = (eventName: ANALYTICS_EVENT_NAMES) => {
    const eventProperties: IEventProperties = {
      ...eventPropertyBase,
      page: 'article',
      value: {
        previous: articleData ? articleData.slug : '',
        new: articleData ? articleData.slug : '',
      },
    };
    const eventInput: ITrackEventInput = {
      eventProperties,
      eventInput: eventName,
    };
    Amplitude.track(eventInput);
  };

  useEffect(() => {
    if (showAudioControls) {
      // Listen for clicks on audio player controls
      if (audioPlayerRef?.current) {
        audioPlayerRef.current.addEventListener('play', () => {
          trackNarrationEvent(ANALYTICS_EVENT_NAMES.NARRATION_PLAY);
        });
        audioPlayerRef.current.addEventListener('ended', () => {
          trackNarrationEvent(ANALYTICS_EVENT_NAMES.NARRATION_END);
        });
      }
    }
  }, [showAudioControls]);

  // Show audio controls for articles with existing narration files
  const startNarration = async () => {
    if (articleData) {
      trackNarrationEvent(ANALYTICS_EVENT_NAMES.NARRATION_EXISTING_FILE);
      const url = await Narrator.getUrl(articleData);
      if (url) {
        setPresignedUrl(url);
        setShowAudioControls(true);
      }
    }
  };

  // Show audio controls for articles with new narration files,
  // when narration file has completed processing
  const startNarrationWhenReady = async () => {
    if (articleData && !narrationFileKey) {
      setTimeout(async () => {
        const url = await Narrator.getUrl(articleData);
        if (url) {
          startNarration();
          setAudioLoading(false);
        } else {
          startNarrationWhenReady();
        }
      }, 5000);
    }
  };

  const initiateNarration = async () => {
    trackNarrationEvent(ANALYTICS_EVENT_NAMES.NARRATION_OPEN);
    if (articleData) {
      if (narrationFileKey) {
        // Narration file already exists
        startNarration();
      } else {
        setAudioLoading(true);
        // Create narration file
        await Narrator.createNarrationFile(articleData);
        trackNarrationEvent(ANALYTICS_EVENT_NAMES.NARRATION_CREATE_FILE);
        startNarrationWhenReady();
      }
    }
  };

  const renderAudioControls = () => {
    if (showAudioControls) {
      return (
        <audio ref={audioPlayerRef} controls src={presignedUrl}>
          <track src={articleData ? articleData.descriptor : ''} kind="captions" />
        </audio>
      );
    }
    return (
      <IconButton
        onClick={initiateNarration}
        color="secondary"
        disabled={showAudioControls}
        sx={{ height: '34px' }}
      >
        { audioLoading ? <CircularProgress size={14} /> : <Headphones /> }
      </IconButton>
    );
  };

  return (
    <ArticleLayout>
      <WidthConstrainedContent>
        {articleData && (
          <div className="content">
            <HeaderContainer>
              <CategoryTags article={articleData} showSubCategory />
              <Headline variant="h1">{articleData.title}</Headline>
              <ArticleActionsContainer>
                <ReadtimeBadge readminutes={articleData?.readminutes} />
                { articleData.type !== ARTICLE_TYPE.RESOURCE && articleData.type !== ARTICLE_TYPE.VIDEO && renderAudioControls()}
              </ArticleActionsContainer>
            </HeaderContainer>
            {articleData?.descriptor && <Description variant="body2">{articleData.descriptor}</Description> }
            { !heroIsVideo
              ? <HeroImage src={articleData.hero?.file.url} alt={articleData.hero?.description} />
              : <ReactPlayer url={articleData.hero.file.url} controls />
            }
            <div>{bodyElements}</div>
          </div>
        )}
      </WidthConstrainedContent>
    </ArticleLayout>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headline = styled(Typography)`
  padding: 8px 0px;
  line-height: 130%; /* 41.6px */
  text-align: center;
`;

const Description = styled(Typography)`
  padding: 24px 0px;
`;

const HeroImage = styled('img')`
  width: 100%;
  border-radius: 16px;
  /* Margin is 24, but accounts for margin of article block */
  margin-bottom: 6px;
`;

const ArticleLayout = styled.div`
  justify-content: center;
  display: flex;
`;

const ArticleActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
