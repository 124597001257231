export const orchid = {
  50: '#EEF2FF',
  100: '#F1D5EB',
  200: '#EAC0E2',
  300: '#E3ABD8',
  400: '#DB96CE',
  500: '#D482C4',
  600: '#CD6DBA',
  700: '#C658B1',
  800: '#BF43A7',
  900: '#B82E9D',
  1000: '#9C4295',
};
