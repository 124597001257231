import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';

import { IAuthUser } from '../../common/types/IAuthUser';
import { ProfileAvatar } from '../atoms/ProfileAvatar';
import { AVATAR_SIZE } from '../enums/avatarSize';

export const ProfileHeader = ({ showLogout, logout }: { showLogout: boolean, logout: () => void }): JSX.Element => {
  // TODO: switch this to reference our postgres db user
  // (at least for the email address -- won't have the name)
  const { user } = useAuth0<IAuthUser>();
  if (user) {
    return (
      <ProfileContainer>
        <ProfileAvatar
          size={AVATAR_SIZE.xlarge}
          user={user}
        />
        <Typography sx={{ textAlign: 'center', margin: '24px 0 16px 0' }} variant="h1">{user.name}</Typography>
        <Typography sx={{ textAlign: 'center' }}>{user.email}</Typography>
        {
          showLogout && (
          <Box textAlign="center" sx={{ mt: 2 }}>
            <Button onClick={logout}>Logout</Button>
          </Box>
          )
        }
      </ProfileContainer>
    );
  }
  return <>No user</>;
};

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;
