import { Typography, Box, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

import { Grid } from '../../common/atoms/Grid';
import { theme } from '../../common/theme/theme';
import { IArticlePreview } from '../types/IArticle';
import { useGetUserBookmarks } from '../hooks/useBookmarks';
import { ROUTE } from '../enums/route';
import { ArticleCard } from '../components/ArticleCard';
import { ProfileHeader } from '../components/ProfileHeader';
import { OrgBanner } from '../components/OrgBanner';
import { useGetUser } from '../../common/hooks/useGetUser';
import { ConfirmationDialog } from '../../common/components/ConfirmationDialog';
import { useDeleteAccount } from '../hooks/useUser';

const EmptyBookmarks = () => (
  <Box
    display="flex"
    justifyContent="center"
    flexDirection="column"
    alignItems="center"
    sx={{ py: 13, mt: 2 }}
    style={{
      backgroundColor: theme.palette.secondary.main,
      borderRadius: theme.spacing(1.5),
      padding: 24,
    }}
  >
    <Typography variant="h2" sx={{ mb: 1 }}>
      No Bookmarks Yet
    </Typography>
    <Typography variant="body1" color="text.secondary">
      Bookmark articles or resources to find them here.
    </Typography>
    <Button
      variant="contained"
      sx={{ mt: 4 }}
      component={RouterLink}
      to={ROUTE.ROOT}
      size="medium"
      endIcon={<ArrowForward />}
    >
      Discover
    </Button>
  </Box>
);

export const Profile = () => {
  const bookmarks: any[] = useGetUserBookmarks();
  const { data: user } = useGetUser({ isAuthenticated: true });
  const { mutate: executeDelete, isSuccess } = useDeleteAccount();
  const [dialogIsOpen, setdialogIsOpen] = useState(false);
  const { logout } = useAuth0();

  useEffect(() => {
    if (isSuccess) {
      handleLogout();
    }
  }, [isSuccess]);

  const handleDeletionRequest = () => {
    setdialogIsOpen(true);
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const getBookmarkDisplay = () => {
    if (!bookmarks?.length) {
      return (
        <Grid.Cell $bleed xs={12}>
          <EmptyBookmarks />
        </Grid.Cell>
      );
    }
    return (
      bookmarks?.length > 0
        && bookmarks?.map((preview: IArticlePreview) => (
          <Grid.Cell key={preview?.slug} $bleed xs={12}>
            <ArticleCard
              key={preview?.id}
              article={preview}
              slug={`${ROUTE.ARTICLE}/${preview.slug}`}
            />
          </Grid.Cell>
        ))
    );
  };

  return (
    <div className="content">
      <Grid
        rowgap={theme.space(4)}
        colgap={theme.space(4)}
        sx={{ py: 4 }}
        justifyItems="space-between"
      >
        <Grid.Cell xs={12}>
          <ProfileHeader showLogout={!user?.organization} logout={handleLogout} />
        </Grid.Cell>
        <Grid.Cell xs={12}>
          {user?.organization?.name && <OrgBanner orgName={user?.organization?.name} logout={handleLogout} />}
        </Grid.Cell>
        <Grid.Cell xs={12}>
          <Typography variant="h3">My Bookmarks</Typography>
          {getBookmarkDisplay()}
        </Grid.Cell>
        <Grid.Cell
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Button onClick={handleDeletionRequest} sx={{ my: '16px', '&:hover': { bgcolor: theme.palette.error.light } }}>
            Delete Account
          </Button>
        </Grid.Cell>
      </Grid>
      <ConfirmationDialog
        open={dialogIsOpen}
        message="Are you sure you want to delete your account?"
        onClose={() => setdialogIsOpen(false)}
        onConfirm={executeDelete}
      >
      </ConfirmationDialog>
    </div>
  );
};
