import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiService } from '../../common/services/api-service';
import { ADMIN_CACHE_KEY } from '../enums/cacheKey';
import { ICreateInvitationInput, IInvitation } from '../../common/types/IInvitation';
import { AdminTracking } from '../services/adminTracking.service';

export const useCreateInvitation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (invitation: ICreateInvitationInput) => {
      AdminTracking.trackUserInvite({ new: { email: invitation.email, role: invitation.roleName.toString() } });
      return (await ApiService.api.post('/admin/invitation', { invitation })).data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [ADMIN_CACHE_KEY.INVITATIONS],
      });
    },
  });
};

export const useGetInvitations = () => useQuery<IInvitation[]>({
  queryKey: [ADMIN_CACHE_KEY.INVITATIONS],
  queryFn: async () => (await ApiService.api.get('admin/invitation')).data,
});
